import {
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import LogoIcon from "src/assets/icons/Logo.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Input } from "reactstrap";
import { Link as Link2 } from "react-scroll";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const handleNavigation = (val) => {
    navigate("/CourseDetails", {
      state: { course: val },
    });
  };
  return (
    <div id="footer" style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%", maxWidth: "950px" }}>
        <Typography style={{ display: "flex", alignItems: "center" }}>
          {" "}
          <img
            //   className={styles.navLogo}
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              marginRight: "16px",
            }}
            alt="logo.png"
            src={LogoIcon}
          />
          TagSkills
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <br />
            {/* <br /> */}
            <Typography variant="body2" className="font12">
              Your gateway to mastering ERP: Explore courses, gain expertise,
              and elevate your career in enterprise solutions
            </Typography>
            <br />
            <Typography variant="body2" className="purpleColor">
              Copyright TagSkills
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              variant="body2"
              style={{ textAlign: "center" }}
              className="font14 purpleColor"
            >
              <b>ERP Courses</b>
            </Typography>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("S/4 HANA MM")}
                    >
                      S/4 HANA MM
                    </Link>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("S/4 HANA SD")}
                    >
                      S/4 HANA SD
                    </Link>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("S/4 HANA FICO")}
                    >
                      S/4 HANA FICO
                    </Link>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("S/4 HANA EWM")}
                    >
                      S/4 HANA EWM
                    </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("S/4 HANA ABAP")}
                    >
                      S/4 HANA ABAP
                    </Link>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("S/4 HANA TM")}
                    >
                      S/4 HANA TM
                    </Link>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("ARIBA")}
                    >
                      ARIBA
                    </Link>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    <Link
                      className="darkColor"
                      onClick={() => handleNavigation("SALESFORCE")}
                    >
                      SALESFORCE
                    </Link>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography variant="body2" className="font14 purpleColor">
              <b>Company</b>
            </Typography>
            <br />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Typography variant="body2" className="font12">
                <Link2
                  to="storySection"
                  className="pointer font12"
                  smooth={true}
                >
                  Success Story
                </Link2>
              </Typography>
              <Typography variant="body2" className="pointer font12">
                <Link2 to="CoursesSection" smooth={true}>
                  Courses
                </Link2>
              </Typography>
              <Typography variant="body2" className="pointer font12">
                <Link2 to="aboutSection" smooth={true}>
                  About
                </Link2>
              </Typography>
              <Typography variant="body2" className="pointer font12">
                <Link2 to="contactSection" smooth={true}>
                  {" "}
                  Contact Us
                </Link2>
              </Typography>
              {/* TODO <Typography variant="body2" className="font12">
                <Link2 to="" smooth={true}>
                  {" "}
                  Blogs
                </Link2>
              </Typography> */}
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body2" className="font14 purpleColor">
              <b>Join a Newsletter</b>
            </Typography>
            <br />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {/* <Typography variant="body2" className="font12">
                Your Email
              </Typography>
              <div style={{ display: "flex", gap: "8px" }}>
                <Input
                  placeholder="Enter Your Email"
                  style={{ borderRadius: "16px" }}
                />
                <Button className="btn" variant="contained">
                  Subscribe
                </Button>
              </div> */}
              <div style={{ display: "flex", gap: "8px" }}>
                <Facebook
                  style={{ cursor: "pointer", fill: "#68a3da" }}
                  onClick={() =>
                    window.open("https://www.facebook.com/TagSkills", "_blank")
                  }
                />
                <Instagram
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/tagskills2020?igsh=YzZmNXpjNXZneHlu",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer", fill: "#fa6488" }}
                />{" "}
                {/* instagram://media?id=YzZmNXpjNXZneHlu */}
                <LinkedIn
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/tag-skills",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer", fill: "#68a3da" }}
                />
                <YouTube
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCnrhyp2JvcNQb3kFeRziDLA",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer", fill: "#ff2525" }}
                />
                <WhatsApp
                  style={{ cursor: "pointer", fill: "#48C9B0" }}
                  onClick={() => window.open("https://wa.me/916360959192")}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <br />
        <Divider
          style={{
            height: "2px",
            background: "linear-gradient(to right, #5249bd, #d3d3d300)",
          }}
        />

        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" className="font11 darkColor">
                <Link
                  style={{ color: "blue" }}
                  onClick={() => navigate("/Disclaimer")}
                >
                  Disclaimer
                </Link>
              </Typography>
              <Typography variant="body2" className="font11 darkColor">
                {" "}
                <Link
                  style={{ color: "blue" }}
                  onClick={() => navigate("/Policy")}
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography variant="body2" className="font11 darkColor">
                {" "}
                <Link
                  style={{ color: "blue" }}
                  onClick={() => navigate("/Conditions")}
                >
                  Terms And Conditions
                </Link>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" className="font11">
                <LocationOnOutlinedIcon />
              </Typography>
              <Typography variant="body2" className="font11">
                No.412, C block, Amity Ramapriya Apartment, Hosakerehalli,BSK
                3rd Stage, Bangalore-560085
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {" "}
              <Typography variant="body2" className="font11">
                <EmailOutlinedIcon />
              </Typography>
              <Typography
                variant="body2"
                className="font11"
                style={{ cursor: "pointer" }}
              >
                <p
                  onClick={() =>
                    window.open("mailto:support@tagskills.com", "_blank")
                  }
                >
                  {" "}
                  support@tagskills.com{" "}
                </p>
              </Typography>
              <Typography variant="body2" className="font11">
                <LocalPhoneOutlinedIcon />
              </Typography>
              <Typography
                variant="body2"
                className="font11"
                style={{ cursor: "pointer" }}
              >
                <p href="/" onClick={() => window.open("tel: +91 6360959192")}>
                  {""}+91 6360959192
                </p>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
