import React, { useEffect, useRef } from "react";
import styles from "./Main.module.css";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import Icon from "src/assets/icons/Icons";
import { BiBell } from "react-icons/bi";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdOutlineDashboard } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";
import { AccountBox } from "@mui/icons-material";

const Main = () => {
  const user = useSelector((state) => state.user.request[0]);
  const { width } = useWindowDimensions();
  const sideNavRef = useRef(null);
  const mainRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (width < 900) {
      sideNavRef.current.style.width = "0";
      mainRef.current.style.marginLeft = "0px";
    }
  }, []);

  useEffect(() => {
    handleNavBar();
  }, [width, location]);

  const handleNavBar = () => {
    if (width > 900 && location.pathname !== "/Videoplay") {
      sideNavRef.current.style.display = "block";
      mainRef.current.style.marginLeft = "300px";
      sideNavRef.current.style.width = "300px";
      mainRef.current.style.paddingTop = "0";
    } else {
      sideNavRef.current.style.width = "100px";
      mainRef.current.style.marginLeft = "0px";
      mainRef.current.style.paddingTop = "52px";
      closeSideNav();
    }
  };

  const openSideNav = () => {
    sideNavRef.current.style.width = "300px";
  };

  const closeSideNav = () => {
    sideNavRef.current.style.width = "0%";
  };

  return (
    <div className={styles.bg}>
      <div ref={sideNavRef} className={styles.sidenav}>
        <div className={styles.logoBox}>
          {(width < 900 || location.pathname === "/Videoplay") && (
            <div
              className={styles.menuDrawer}
              onClick={() => {
                closeSideNav();
              }}
            >
              <Icon
                fill="white"
                style={{ width: "28px", height: "28px", color: "red" }}
                name={"menu"}
              />
            </div>
          )}
          <img src="./images/taglogo.png" className={styles.logo} />
          <h2 className={styles.logoTitle}>TagSkills</h2>
          <div className={styles.bellBox}>
            {/* <NavLink tag={Link} className={styles.bellIcon} to="/Notification"> */}
            <BiBell size={20} color="white" />
            {/* </NavLink> */}
          </div>
        </div>
        {/* <div className={styles.divalign}>
          <Avatar
            alt={user.full_name}
            src={user.photourl}
            sx={{ width: 56, height: 56 }}
          />
        </div>
        <div className={styles.divalign}>
          <h5>{user.full_name}</h5>
        </div> */}
        <br />
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Dashboard"
        >
          <div className="flexNullCenter">
            <MdOutlineDashboard /> Dashboard
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Coursevideo"
        >
          <div className="flexNullCenter">
            <FaVideo />
            Course Videos
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Pdfmaterials"
        >
          <div className="flexNullCenter">
            <FaFilePdf /> PDF Materials
          </div>
        </NavLink>
        {/* <NavLink className={({ isActive }) =>
               isActive ?  styles.active: styles.navItem 
            } onClick={handleNavBar} to="/">Upload Resume</NavLink> */}
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Certificate"
        >
          <div className="flexNullCenter">
            <TbCertificate /> My Certificates
          </div>
        </NavLink>
        {/* <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Blogs"
        >
          Blogs
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Popularvideos"
        >
          Popular Videos
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Profile"
        >
          Profile
        </NavLink> */}
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.active : styles.navItem
          }
          onClick={handleNavBar}
          to="/Profile"
        >
          <div className="flexNullCenter">
            <AccountBox /> My Profile
          </div>
        </NavLink>
      </div>
      <div ref={mainRef} className={styles.main}>
        {(width < 900 || location.pathname === "/Videoplay") && (
          <div className={styles.topNavBar}>
            <div
              className={styles.menuBtn}
              onClick={() => {
                openSideNav();
              }}
            >
              <Icon
                fill="black"
                style={{ width: "28px", height: "28px", color: "red" }}
                name={"menu"}
              />
            </div>
          </div>
        )}
        <div className={styles.container}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;
