import { ADD_PLAYDATA }  from "../action/action"

const initialState = {
  request: [],
};

export const playdataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLAYDATA:
      return {
        ...state,
        request: [...state.request, action.payload],
      };
    default:
      return state;
  }
};