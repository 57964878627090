import React from "react";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Chooseus from "../../assets/images/chooseus.jpg";
import CourseHighlights from "../../assets/images/coursehighligts.png";
import Enroll from "../../assets/images/enroll.png";
import Career from "../../assets/images/career.png";

const Image = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "12px",
});
const ImageContainer = styled(Box)({
  position: "relative",
  width: "100%",
  borderRadius: "12px",
  overflow: "hidden",
});

const TextOverlay = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: "8px",
  textAlign: "center",
});

const Container = styled(Box)({
  padding: "2rem",
  backgroundColor: "#f9f9f9",
  margin: "auto",
  maxWidth: "1200px",
});

const Section = styled(Box)({
  marginBottom: "1.5rem",
});

const ContentWrapper = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  gap: "5rem",
  flexDirection: "column",
  "@media (min-width:600px)": {
    flexDirection: "row",
  },
  "@media (min-width:900px)": {
    flexDirection: "row",
  },
});

const Point = styled(Box)({
  display: "flex",

  gap: "0.5rem",
});

const CheckBoxIcon = styled(CheckBoxOutlinedIcon)({
  color: "orange",
});

const IconWrapper = styled(Box)({
  display: "flex",
  gap: "1rem",
});
const CustomEmailIcon = styled(EmailIcon)(({ theme }) => ({
  color: "#2C3491",
}));

const CustomPhoneIcon = styled(PhoneIcon)(({ theme }) => ({
  color: "#2C3491",
}));

const CareerTransformationAd = () => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Feeling stuck in your career? You’re not alone.
          </Typography>
          <Typography variant="body1" className="font12">
            Over 70% of civil engineers face unemployment. But don’t worry,
            there’s a way to break through and elevate your career prospects.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Even Civil & Mechanical Engineers Can Transition into IT Companies
            with SAP Skills!
          </Typography>
          <Typography variant="body1" className="font12">
            Imagine accessing 25,856 SAP job vacancies, just like those reported
            in July 2024. IT companies are on the lookout for professionals like
            you. With SAP skills, you can seize these opportunities and
            transform your career.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ContentWrapper>
            <Box flex={1}>
              <ImageContainer>
                <Image src={Chooseus} alt="Career Transformation" />
                <TextOverlay>
                  <Typography variant="h5" gutterBottom>
                    Why Choose Tagskills for SAP Training?
                  </Typography>
                </TextOverlay>
              </ImageContainer>
            </Box>
            <Box flex={1} margin="auto" alignContent="baseline">
              <Typography variant="h5" gutterBottom>
                Why Choose Tagskills for SAP Training?
              </Typography>
              <Section>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" className="font12">
                    In-Demand Skills: SAP S/4 EWM skills for supply chain
                    warehouse management can significantly increase your salary.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" className="font12">
                    Lucrative Salaries: With SAP Ariba skills, you can earn
                    between 12 to 15 LPA if you have the required experience.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" className="font12">
                    Versatile Career Opportunities: SAP expertise opens doors to
                    numerous roles in top IT companies.
                  </Typography>
                </Point>
              </Section>
            </Box>
          </ContentWrapper>
        </Grid>

        <Grid item xs={12} md={12}>
          <ContentWrapper>
            <Box flex={1}>
              <Image src={CourseHighlights} alt="SAP Training" />
            </Box>
            <Box flex={2} margin="auto">
              <Typography variant="h5" gutterBottom textAlign="center">
                Course Highlights
              </Typography>
              <Section>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    <strong>Comprehensive SAP Training:</strong> Gain mastery in
                    SAP S/4 EWM and SAP Ariba, critical for supply chain and
                    procurement roles.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    <strong>Expert Instructors:</strong> Learn from industry
                    veterans with years of practical experience.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    <strong>Hands-On Projects:</strong> Apply your learning with
                    real-world projects, ensuring you’re job-ready.
                  </Typography>
                </Point>
              </Section>
            </Box>
          </ContentWrapper>
        </Grid>

        <Grid item xs={12}>
          <ContentWrapper>
            <Box flex={2} margin="auto">
              <Section>
                <Typography variant="h5" textAlign="center">
                  Who Should Enroll?
                </Typography>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    Civil and Mechanical engineers are looking to pivot to the
                    IT sector.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    Professionals aiming to enhance their SAP skills.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    Anyone seeking a high-paying, rewarding career in SAP.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    Commerce and Management students.
                  </Typography>
                </Point>
                <Point>
                  <CheckBoxIcon />
                  <Typography variant="body1" paragraph className="font12">
                    Individuals interested in an advanced course to become an
                    SAP consultant with an average package of up to 15 LPA.
                  </Typography>
                </Point>
              </Section>
            </Box>
            <Box flex={1}>
              <Image src={Enroll} alt="SAP Training" />
            </Box>
          </ContentWrapper>
        </Grid>

        <Grid item xs={12}>
          <ContentWrapper>
            <Box flex={1}>
              <Image src={Career} alt="Career Transformation" />
            </Box>
            <Box flex={2} margin="auto">
              <Typography variant="h5" gutterBottom>
                Take the Leap and Transform <br /> Your Career!
              </Typography>
              <Section>
                <Point>
                  <Typography variant="body1" className="font12" md={6}>
                    Don’t let unemployment or career stagnation hold you back.
                    Enroll in Tagskills’ SAP Online Training today and set
                    yourself on the path to success.
                  </Typography>
                </Point>
                <Point>
                  <CustomEmailIcon />
                  <Typography variant="body1" className="font12">
                    support@tagskills.com
                  </Typography>
                </Point>
                <Point>
                  <CustomPhoneIcon />
                  <Typography variant="body1" className="font12">
                    +91 6360959192
                  </Typography>
                </Point>
                <Point>
                  <Typography variant="body1">Follow Us</Typography>
                </Point>
                <IconWrapper>
                  <IconButton
                    href="https://www.facebook.com/TagSkills"
                    target="_blank"
                    color="primary"
                  >
                    <FacebookIcon />
                  </IconButton>
                  <IconButton
                    href="https://www.instagram.com/tagskills2020?igsh=YzZmNXpjNXZneHlu"
                    target="_blank"
                    color="primary"
                  >
                    <InstagramIcon />
                  </IconButton>
                  <IconButton
                    href="https://www.linkedin.com/company/tag-skills"
                    target="_blank"
                    color="primary"
                  >
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton
                    href="https://api.whatsapp.com/send/?phone=916360959192&text&type=phone_number&app_absent=0"
                    target="_blank"
                    color="primary"
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </IconWrapper>
              </Section>
            </Box>
          </ContentWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CareerTransformationAd;
