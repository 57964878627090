import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "./SideBar";
import Backdrop from "src/components/Elements/Backdrop";
// Assets
import LogoIcon from "src/assets/icons/Logo.png";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  MenuItem,
  Menu,
  Box,
  IconButton,
} from "@mui/material";
import LoginPage from "../LandingPage/components/LoginPage";
// import BurgerIcon from "../../assets/svg/BurgerIcon";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export default function TopNavbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log("click");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const handleNavigation = (val) => {
    navigate("/JobDetails", {
      state: { course: val },
    });
  };

  return (
    <>
      {/* <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} /> */}
      {/* {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />} */}
      <Wrapper
        className="flexCenter animate purpleBg whiteColor"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link
            className="pointer flexNullCenter"
            style={{ gap: "0px" }}
            to="home"
            smooth={true}
          >
            <img
              //   className={styles.navLogo}
              style={{
                width: "36px",
                height: "36px",
                alignSelf: "flex-start",
                // marginRight: "16px",
              }}
              alt="logo.png"
              src={LogoIcon}
            />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              TagSkills
            </h1>
          </Link>
          {/* <Button>Login</Button> */}
          {/* <BurderWrapper
            className="pointer"
            // onClick={() => toggleSidebar(!sidebarOpen)}
          > */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                {" "}
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="CoursesSection"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Courses
                </Link>
              </MenuItem>
              <MenuItem>
                {" "}
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="storySection"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Success Story
                </Link>
              </MenuItem>
              <MenuItem>
                {" "}
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="aboutSection"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  About Us
                </Link>
              </MenuItem>
            <MenuItem>
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  onClick={() => handleNavigation()}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Jobs
                </Link>
              </MenuItem>

              <MenuItem>
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="contactSection"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Contact
                </Link>
              </MenuItem>
              {/* <MenuItem>Logout</MenuItem> */}
            </Menu>
          </Box>

          {/* </BurderWrapper> */}

          <UlWrapperRight className="flexNullCenter">
            <li className=" font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="Courses"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Courses
              </Link>
            </li>
            <li className=" font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="stories"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Success Story
              </Link>
            </li>
            <li className=" font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="about"
                spy={true}
                smooth={true}
                offset={-80}
              >
                About Us
              </Link>
            </li>
            <li className=" font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="footer"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Contact
              </Link>
            </li>
            <li className=" font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="footer"
                spy={true}
                smooth={true}
                offset={-80}
                onClick={() => handleNavigation()}
              >
                Jobs
              </Link>
            </li>
            <li className=" font15 pointer flexCenter">
              <Button
                // href="/"
                size="small"
                variant="contained"
                className="radius8 lightBg purpleColor"
                style={{ padding: "10px 15px" }}
                onClick={() => setOpenLogin(true)}
              >
                Log in
              </Button>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
      {openLogin && (
        <Dialog fullWidth maxWidth={"md"} open={openLogin}>
          <DialogContent>
            <LoginPage onClose={() => setOpenLogin(false)} />
          </DialogContent>
        </Dialog>
      )}
      {console.log(open, "open")}
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
