import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./styles.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import BookNow from "./BookNow";
import { useNavigate } from "react-router-dom";
import { CiSquareCheck } from "react-icons/ci";
import { FaRegCheckSquare } from "react-icons/fa";

const styles = {
  title: {
    padding: "16px",
    transition: "color 0.3s, transform 0.3s",
    cursor: "pointer",
  },
  titleHover: {
    color: "#5249bd",
    transform: "scale(1.1)",
  },
  divider: {
    backgroundColor: "#d3d3d3",
    marginLeft: "18px",
  },
};

export default function Courses() {
  const [isHovered, setIsHovered] = React.useState(false);
  const data = [
    {
      name: "ERP",
      list: [
        {
          header: "ABAP WITH HANA",
          content: [
            {
              label: "ABAP and HANA Integration",
              text: "Learn how to seamlessly integrate ABAP programming language with ERP HANA's in-memory database technology.",
            },
            {
              label: "Optimized Data Processing",
              text: "Understand techniques for optimizing data processing using ABAP with HANA, leveraging the speed and efficiency of in-memory computing.",
            },
            {
              label: "Core Data Services (CDS) Views ",
              text: "Master the creation and consumption of CDS views to define data models and enable efficient communication between ABAP and HANA.",
            },
            {
              label: "Performance Tuning",
              text: "Gain insights into performance tuning strategies for ABAP development in the HANA environment, ensuring optimal application speed.",
            },
            {
              label: "Real-time Analytics",
              text: "Explore the capabilities of real-time analytics, allowing for instantaneous insights into business data through the combined power of ABAP and HANA.",
            },
            {
              label: "Advanced Features and Fiori Integration",
              text: ": Delve into advanced functionalities like geospatial data processing and predictive analytics, and learn how to integrate ERP Fiori for modern and responsive user interfaces in ABAP with HANA applications.",
            },
          ],
        },
        {
          header: "FICO WITH HANA",
          content: [
            {
              label: "ERP FICO Fundamentals",
              text: "Understand the foundational concepts of ERP Financial Accounting (FICO) and its integration with ERP HANA's in-memory database technology.",
            },
            {
              label: "In-memory Financial Data Processing",
              text: "Learn how ERP HANA accelerates financial data processing in real-time, providing faster insights and analytics for finance-related operations.",
            },
            {
              label: "ERP HANA Database Integration",
              text: "Explore the seamless integration of ERP FICO with HANA, unlocking enhanced performance and efficiency in managing financial data.",
            },
            {
              label: "Advanced Financial Reporting",
              text: "Master advanced financial reporting and analytics capabilities made possible by the synergy of ERP FICO and HANA, enabling real-time decision-making.",
            },
            {
              label: "ERP Fiori User Interface Integration",
              text: "Understand how to integrate ERP Fiori for a modern and user-friendly interface in financial applications developed with ERP FICO and HANA.",
            },
            {
              label: "Optimization and Compliance",
              text: "Gain insights into optimizing financial processes, ensuring compliance, and leveraging the advanced features of ERP FICO with HANA for improved financial management.",
            },
          ],
        },
        {
          header: "MM WITH HANA",
          content: [
            {
              label: "ERP MM Fundamentals",
              text: "Acquire a solid understanding of ERP Material Management (MM), covering procurement, inventory management, and logistics processes.",
            },
            {
              label: "Integration with ERP HANA",
              text: "Learn how ERP MM seamlessly integrates with ERP HANA's in-memory database technology, enhancing speed and efficiency in managing material-related data.",
            },
            {
              label: "Real-time Material Data Processing",
              text: "Explore how ERP HANA enables real-time processing of material-related information, providing immediate insights for better decision-making in supply chain and procurement",
            },
            {
              label: "Inventory Optimization",
              text: "Master techniques to optimize inventory management using ERP MM with HANA, ensuring efficient stock levels and minimizing operational costs.",
            },
            {
              label: "ERP Fiori Interface Integration",
              text: "Understand the integration of ERP Fiori for a modern and intuitive user interface, enhancing user experience in material management applications.",
            },
            {
              label: "Advanced Analytics and Reporting",
              text: "Delve into the advanced analytics and reporting capabilities facilitated by the combination of ERP MM and HANA, allowing for comprehensive analysis of material-related data for strategic planning.",
            },
          ],
        },
        {
          header: "MM WITH HANA",
          content: [
            {
              label: "ERP PP/QM Basics",
              text: "Gain a comprehensive understanding of ERP Production Planning (PP) and Quality Management (QM), covering key processes in manufacturing and quality control",
            },
            {
              label: "Integration with ERP HANA",
              text: "Learn how ERP PP/QM seamlessly integrates with ERP HANA's in-memory database technology, enhancing data processing speed and efficiency.",
            },
            {
              label: "Real-time Manufacturing Data Processing",
              text: "Explore how ERP HANA enables real-time processing of production and quality data, allowing for immediate insights and agile decision-making.",
            },
            {
              label: "Quality Control Optimization",
              text: "Master techniques to optimize quality management processes using ERP PP/QM with HANA, ensuring rigorous quality standards are met throughout the production lifecycle.",
            },
            {
              label: "ERP Fiori Interface Integration",
              text: "Understand the integration of ERP Fiori for a modern and user-friendly interface in PP/QM applications, enhancing usability for end-users.",
            },
            {
              label: "Advanced Analytics and Reporting",
              text: "Delve into the advanced analytics and reporting capabilities facilitated by the combination of ERP PP/QM and HANA, allowing for in-depth analysis of manufacturing and quality data for continuous process improvement.",
            },
          ],
        },
        {
          header: "SD WITH HANA",
          content: [
            {
              label: "SD WITH HANA",
              text: "Understand the fundamentals of Sales and Distribution (SD) in ERP, covering key processes such as order management, pricing, and delivery",
            },
            {
              label: "Integration with ERP HANA",
              text: "Learn how ERP SD seamlessly integrates with ERP HANA's in-memory database technology, enhancing data processing speed and efficiency in sales-related operations",
            },
            {
              label: "Real-time Sales Data Processing",
              text: "Explore how ERP HANA enables real-time processing of sales data, providing immediate insights for agile decision-making and responsive customer service.",
            },
            {
              label: "Pricing and Revenue Optimization",
              text: "Master techniques to optimize pricing strategies and revenue management using ERP SD with HANA, ensuring competitiveness in the market.",
            },
            {
              label: "ERP Fiori Interface Integration",
              text: "Understand the integration of ERP Fiori for a modern and intuitive user interface in SD applications, enhancing user experience for sales and distribution processes.",
            },
            {
              label: "Advanced Analytics and Reporting",
              text: "Delve into the advanced analytics and reporting capabilities facilitated by the combination of ERP SD and HANA, allowing for comprehensive analysis of sales data and customer behaviour for strategic planning.",
            },
          ],
        },
      ],
    },
    {
      name: "Interior Design & Architecture",
      list: [{ header: "", content: [{ label: "", text: "" }] }],
    },
    {
      name: "Data Science",
      list: [{ header: "", content: [{ label: "", text: "" }] }],
    },
    {
      name: "IT & Software Engineering",
      list: [{ header: "", content: [{ label: "", text: "" }] }],
    },
  ];
  const [selectedDiv, setSelectedDiv] = useState("ERP");
  const handleDivSelection = (val) => {
    setSelectedDiv(val);
  };
  const navigate = useNavigate();
  const [openJoinFor, setOpenJoinFor] = useState(false);

  const getContent = (val) => {
    // let record = data.find((item) => item.name === val);
    let record = data.find((item) => item?.name);
    return record?.list;
  };
  const handleDialogClose = () => {
    setOpenJoinFor(false);
  };
  const handleNavigation = (val) => {
    navigate("/CourseDetails", {
      state: { course: val },
    });
  };
  return (
    <div id="Courses">
      <Box
      // sx={{
      //   flexGrow: 1,
      //   display: { xs: "none", md: "block" },
      //   justifyContent: "end",
      // }}
      >
        {" "}
        {/* <div className="animation" id="Courses">
          <div className="anm_mod full fast"> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Card className="curCard">
              <b> SAP COURSES</b>

              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> DURATION :45-75HOURS
              </Typography>
              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> ERP APPLICATION
              </Typography>

              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> BUSINESS PROCESS KNOWLEDGE
              </Typography>
              <b>DATA ANALYTICS/DATA SCIENCE</b>
              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> DURATION : 75-130 HOURS
              </Typography>

              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> REAL-WORLD APPLICATIONS
              </Typography>

              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> PROGRAMMING SKILLS
              </Typography>
              <Divider />
              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> REAL TIME PROJECT
              </Typography>
              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> CONSULTATIVE APPROACH
              </Typography>
              <Typography variant="body1" className="flexNullCenter font14">
                {" "}
                <FaRegCheckSquare /> PLACEMENT SUPPORT
              </Typography>
              <br />
              <Button
                style={{
                  background: "#9966CC",
                  color: "white",
                  borderRadius: "8px",
                  // width: "200px",
                  textTransform: "none",
                }}
                onClick={() => setOpenJoinFor(true)}
              >
                Book Your Free Demo
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={3} style={{ padding: "16px" }}>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("S/4 HANA MM")}
                >
                  <CardContent
                    style={
                      selectedDiv === "S/4 HANA MM"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                    // className="pb0"
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "S/4 HANA MM"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        01
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>

                    <Typography variant="body1" className="font18">
                      <b> S/4 HANA MM</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() => handleNavigation("S/4 HANA MM")}
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("S/4 HANA SD")}
                >
                  <CardContent
                    style={
                      selectedDiv === "S/4 HANA SD"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "S/4 HANA SD"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        02
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>S/4 HANA SD</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("S/4 HANA SD")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("S/4 HANA FICO")}
                >
                  <CardContent
                    style={
                      selectedDiv === "S/4 HANA FICO"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "S/4 HANA FICO"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        03
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>S/4 HANA FICO</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("S/4 HANA FICO")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("S/4 HANA EWM")}
                >
                  <CardContent
                    style={
                      selectedDiv === "S/4 HANA EWM"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "S/4 HANA EWM"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        04
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>S/4 HANA EWM</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("S/4 HANA EWM")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("S/4 HANA ABAP")}
                >
                  <CardContent
                    style={
                      selectedDiv === "S/4 HANA ABAP"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "S/4 HANA ABAP"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        05
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>S/4 HANA ABAP</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("S/4 HANA ABAP")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("S/4 HANA TM")}
                >
                  <CardContent
                    style={
                      selectedDiv === "S/4 HANA TM"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "S/4 HANA TM"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        06
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>S/4 HANA TM</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("S/4 HANA TM")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("ARIBA")}
                >
                  <CardContent
                    style={
                      selectedDiv === "ARIBA"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "ARIBA"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        07
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>ARIBA</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("ARIBA")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("SALESFORCE")}
                >
                  <CardContent
                    style={
                      selectedDiv === "SALESFORCE"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "SALESFORCE"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        08
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>SALESFORCE</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("SALESFORCE")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("SAP PM")}
                >
                  <CardContent
                    style={
                      selectedDiv === "SAP PM"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "SAP PM"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        09
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>SAP PM</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("SAP PM")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("SAP BTP")}
                >
                  <CardContent
                    style={
                      selectedDiv === "SAP BTP"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "SAP BTP"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        10
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>SAP BTP</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("SAP BTP")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("SAP EAM")}
                >
                  <CardContent
                    style={
                      selectedDiv === "SAP EAM"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "SAP EAM"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        11
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>SAP EAM</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("SAP EAM")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("SAP PS")}
                >
                  <CardContent
                    style={
                      selectedDiv === "SAP PS"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "SAP PS"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        12
                      </div>{" "}
                      <b>ERP</b>
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>SAP PS</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("SAP PS")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <div>
              <b
                style={{
                  ...styles.title,
                  ...(isHovered ? styles.titleHover : {}),
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Other Courses
              </b>
              <Divider sx={{ ...styles.divider }} />
            </div>

            <Grid
              container
              display="flex"
              rowSpacing={{ xs: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
              style={{ padding: "16px" }}
            >
              <Grid item md={4} xs={12}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("DATA ANALYTICS")}
                >
                  <CardContent
                    style={
                      selectedDiv === "DATA ANALYTICS"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "DATA ANALYTICS"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        01
                      </div>{" "}
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>DATA ANALYTICS</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("DATA ANALYTICS")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item md={4} xs={12}>
                <Card
                  className={
                    selectedDiv === 1 ? "cardDiv" : "cardDiv topleftBorder"
                  }
                  elevation={1}
                  onClick={() => handleDivSelection("DATA SCIENCE")}
                >
                  <CardContent
                    style={
                      selectedDiv === "DATA SCIENCE"
                        ? { background: "#5249BD", color: "white" }
                        : {}
                    }
                  >
                    <Typography variant="h6" className="flexNullCenter pb15">
                      <div
                        className="countDiv"
                        style={
                          selectedDiv === "DATA SCIENCE"
                            ? {
                                background: "white",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                            : {
                                background: "#5249bd33",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "18px",
                              }
                        }
                      >
                        02
                      </div>{" "}
                    </Typography>
                    <Typography variant="body1" className="font18">
                      <b>DATA SCIENCE</b>
                    </Typography>
                  </CardContent>
                  <CardActions className="cardFooter p0">
                    <Button
                      className="btn"
                      onClick={() =>
                        // window.open("https://tagskills.com/CourseDetails")
                        handleNavigation("DATA SCIENCE")
                      }
                    >
                      Package Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </div>
        </div> */}
      </Box>

      {openJoinFor && (
        <BookNow openJoinFor={openJoinFor} onClose={handleDialogClose} />
      )}
    </div>
  );
}
