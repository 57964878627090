export const ADD_PAYDATA = 'ADD_PAYDATA';
export const addPaydata = (request) => ({
  type: ADD_PAYDATA,
  payload: request,
});

export const ADD_USER = 'ADD_USER';
export const addUser = (request) => ({
  type: ADD_USER,
  payload: request,
});

export const ADD_PLAYDATA = 'ADD_PLAYDATA';
export const addPlaydata = (request) => ({
  type: ADD_PLAYDATA,
  payload: request,
});