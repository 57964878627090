import { Link } from "react-router-dom";
import NavMenu from "src/components/NavMenu/NavMenu";
import styles from "./Validatecertificate.module.css";
import { MdEmail } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { CertificateDisplayApi } from "src/api/ApiConnect";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "src/components/NavMenu/LeftMenu";
import { useNavigate } from "react-router-dom";
import { PostWitoutToken } from "src/api/apiConfig";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

const Field = React.forwardRef(
  ({ label, error, placeholder, icon, ...props }, ref) => {
    return (
      <>
        <div className={styles.input}>
          {icon}
          <input
            {...props}
            ref={ref}
            placeholder={placeholder}
            className={styles.inputStyle}
          />
        </div>
        <p className={styles.validerror}>{error}</p>
      </>
    );
  }
);

const Validatec = () => {
  const [dashtest, setTest] = useState([]);
  const [loading, setLoading] = useState();
  const [buttonPopup, setButtonPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const emailRef = React.useRef();
  const [emailError, setEmailError] = useState(null);
  const [certificateresponse, setCertificateresponse] = useState([]);

  const [txtemail, setTxtemail] = useState("");

  const handleSubmit = async () => {
    if (!txtemail) {
      setEmailError("Please enter your email!");
      return;
    }

    setEmailError(null);
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(txtemail)) {
      setEmailError("Enter valid Email!");
      return;
    }

    const RequestBody = { USER_EMAIL: txtemail };
    const data = await PostWitoutToken(CertificateDisplayApi, RequestBody);
    setCertificateresponse(data);
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  return (
    <Box>
      <NavMenu />

      <Box
        sx={{
          minWidth: "100%",
          m: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography level="body-sm">
            Validate Your Tagskills Course Certification Details
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Divider variant="middle" sx={{ width: "70%" }} />
      </Box>

      <Box
        sx={{
          minWidth: "100%",
          m: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ minWidth: "40%" }}>
          <TextField
            fullWidth
            label="Enter Your Email"
            id="fullWidth"
            value={txtemail}
            onChange={(e) => {
              setTxtemail(e.target.value);
            }}
          />
          <Typography variant="caption" color={"#A52A2A"}>
            {emailError}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          minWidth: "100%",
          m: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ minWidth: "30%" }}>
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
          >
            Validate Your Course Details
          </Button>
        </Box>
      </Box>

      {certificateresponse?.FULLNAME != null ? (
        <>
          <Box
            sx={{
              minWidth: "100%",
              m: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography level="body-sm">
                Certification Details of Candidate
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100%",
              m: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ minWidth: "40%" }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Name Of Candidate"
                id="fullWidth"
                value={certificateresponse?.FULLNAME}
              />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100%",
              m: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ minWidth: "40%" }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Register Number Of Candidate"
                id="fullWidth"
                value={certificateresponse?.ID}
              />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100%",
              m: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ minWidth: "40%" }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Course Of Candidate"
                id="fullWidth"
                value={certificateresponse?.COMPLETEDCOURSE}
              />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100%",
              m: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ minWidth: "40%" }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                label="Course Status Of Candidate"
                id="fullWidth"
                value={
                  certificateresponse?.COURSE_STATUS === "COMPLETED"
                    ? "Completed"
                    : "Not Completed"
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: "100%",
              m: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ minWidth: "40%" }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Course Completed Date Of Candidate"
                id="fullWidth"
                value={
                  certificateresponse?.COURSE_STATUS === "COMPLETED"
                    ? dayjs(certificateresponse?.COMPLETEDDATE).format(
                        "DD-MMM-YYYY"
                      )
                    : "NA"
                }
              />
            </Box>
          </Box>{" "}
        </>
      ) : (
        <>
          <Box
            sx={{
              minWidth: "100%",
              m: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography color={"#A52A2A"} level="body-sm">
                Data not available for this candidate!
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Validatec;
