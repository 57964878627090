import { Divider, Typography } from "@mui/material";
import React from "react";
import googleImg from "src/assets/images/google.png";
import YoutubeIcon from "src/assets/images/youtube.png";
import microsoft from "src/assets/images/microsoft.png";
import spotify from "src/assets/images/spotify.png";
import slack from "src/assets/images/slack.png";
import uber from "src/assets/images/uber.png";
import zoom from "src/assets/images/zoom.png";
import stripe from "src/assets/images/stripe.png";
import medium from "src/assets/images/medium.png";
import comp1 from "../../../assets/images/compines/Accenture_logo.png";
import comp2 from "../../../assets/images/compines/Capgemini-Logo.png";
import comp3 from "../../../assets/images/compines/Cognizant_logo.png";
import comp4 from "../../../assets/images/compines/Ecolab.png";
import comp5 from "../../../assets/images/compines/Genpact_logo.png";
import comp6 from "../../../assets/images/compines/HCLTech-logo.png";
import comp7 from "../../../assets/images/compines/Infosys_logo.png";
import comp8 from "../../../assets/images/compines/Intel_logo.png";
import comp9 from "../../../assets/images/compines/Mercedes-Benz_Logo.png";
import comp10 from "../../../assets/images/compines/Mindtree_logo.png";
import comp11 from "../../../assets/images/compines/Reliance-Industries-Limited-Logo.png";
import comp12 from "../../../assets/images/compines/Tata_logo.png";
import comp13 from "../../../assets/images/compines/Tech_Mahindra_logo.png";
import comp14 from "../../../assets/images/compines/Wipro_logo.svg";
import comp15 from "../../../assets/images/compines/YES Securities.png";
import comp16 from "../../../assets/images/compines/himalaya.png";
import comp17 from "../../../assets/images/compines/lseg.png";
import comp18 from "../../../assets/images/compines/toyata.png";

import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Slider from "react-slick";
export default function TrustedTeams() {
  const images = [
    comp1,
    comp2,
    comp3,
    comp4,
    comp5,
    comp6,
    comp7,
    comp8,
    comp9,
    comp10,
    comp11,
    comp12,
    comp13,
    comp14,
    comp15,
    comp16,
    comp17,
    comp18,
  ];
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Wrapper id="trustedTeams">
      <InnerWrapper>
        <Typography variant="body1" style={{ textAlign: "center" }}>
          Our alumni's are working in&nbsp;
          <span className="purpleColor">
            <b>100+</b>
          </span>{" "}
          best organisations
        </Typography>
        <Typography
          variant="body1"
          style={{ textAlign: "center", marginBottom: "15px" }}
        >
          <b>Top Organisations</b>
        </Typography>

        <Slider {...settings}>
          {images.map((item, i) => {
            return (
              <ImgDiv key={"img" + i}>
                <LazyLoadImage
                  style={{
                    width: "120px",
                    height: "100%",
                    maxHeight: "60px",
                    objectFit: "contain",
                  }}
                  src={item}
                  alt="img"
                />
              </ImgDiv>
            );
          })}
          {/* </ImgDiv> */}
        </Slider>
      </InnerWrapper>
    </Wrapper>
  );
}
const InnerWrapper = styled.div`
  // padding: 30px 0px;
  @media (max-width: 760px) {
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  textalign: center;
  padding: 30px 0px;
  @media (max-width: 760px) {
    padding: 0px;
  }
`;
const ImgDiv = styled.div`
  // display: flex;
  width: 120px;
  // gap: 15px;
  // flex-wrap: wrap;
  // padding: 20px 280px;
  // justify-content: center;
  @media (max-width: 760px) {
    padding: 0px;
  }
`;
