import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useLocation } from "react-router-dom";
  import styled from "styled-components";
  import { Link } from "react-scroll";
  import LogoIcon from "src/assets/icons/Logo.png";
  import { ArrowBack } from "@mui/icons-material";
  import courseImg from "../../../assets/images/courseImage.png";
  import BookNow from "./BookNow";
  import { CiYoutube } from "react-icons/ci";
  import { IoDownloadOutline } from "react-icons/io5";
  import { CgWebsite } from "react-icons/cg";
  import { TfiMedall } from "react-icons/tfi";
  import img1 from "../../../assets/images/img1.png";
  import img2 from "../../../assets/images/img2.png";
  import img3 from "../../../assets/images/img3.png";
  import img4 from "../../../assets/images/img4.png";
  import img5 from "../../../assets/images/img5.png";
  
  export default function JobDetails() {
    const location = useLocation();
    const [openJoinFor, setOpenJoinFor] = useState(false);
    const handleDialogClose = () => {
      setOpenJoinFor(false);
    };
    return (
      <div>
        <TopDiv className="purpleBg whiteColor flexSpaceCenter">
          <Link
            className="pointer flexNullCenter"
            style={{ gap: "0px" }}
            to="home"
            smooth={true}
          >
            <img
              //   className={styles.navLogo}
              style={{
                width: "36px",
                height: "36px",
                alignSelf: "flex-start",
                // marginRight: "16px",
              }}
              alt="logo.png"
              src={LogoIcon}
            />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              TagSkills
            </h1>
          </Link>
          <Button startIcon={<ArrowBack />} href="/" className="whiteColor">
            Back
          </Button>
        </TopDiv>
        <iframe src="https://optimhire.com/d/Search-job-iframe?ref_code=sachin-kocharekar&skill=&positions=&dark_color=3f73ee&light_color=3b74e8" width="100%" height="800"></iframe>
      </div>
    );
  }
  const Wrapper = styled.section`
    padding-top: 30px;
    width: 100%;
    margin: 0;
    height: 100%;
    min-height: 30vh;
    max-height: 40vh;
    //   @media (max-width: 960px) {
    //     flex-direction: column;
    //   }
    @media (max-width: 760px) {
      max-height: 100vh;
      min-height: 20vh;
      padding-top: 15px;
    }
  `;
  const TopDiv = styled.section`
    width: 100%;
    margin: 0;
    height: 100%;
    padding: 20px;
    //   @media (max-width: 960px) {
    //     flex-direction: column;
    //   }
  `;
  const SideDiv = styled.section`
    position: absolute;
    right: 50px;
    top: 30px;
    //   @media (max-width: 960px) {
    //     flex-direction: column;
    //   }
    @media (max-width: 760px) {
      display: none;
    }
  `;
  
  const BroderContainer = styled.section`
    border: 1px solid lightgray;
    width: 100%;
    margin: 0;
    padding: 20px;
    height: 100%;
    max-width: 75%;
    min-height: 40vh;
    max-height: 60vh;
    //   @media (max-width: 960px) {
    //     flex-direction: column;
    //   }
    @media (max-width: 760px) {
      max-height: 100vh;
      max-width: 100%;
    }
  `;
  const FooterDiv = styled.section`
    width: 100%;
    //   margin: 10px;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //   max-width: 75%;
    //   min-height: 20vh;
    //   max-height: 20vh;
    //   @media (max-width: 960px) {
    //     flex-direction: column;
    //   }
    @media (max-width: 760px) {
      max-height: 100vh;
      max-width: 100%;
    }
  `;
  
  const BroderLessContainer = styled.section`
    //   border: 1px solid lightgray;
    width: 100%;
    margin: 0;
    padding: 20px;
    height: 100%;
    max-width: 75%;
    min-height: 15vh;
    max-height: 20vh;
    //   @media (max-width: 960px) {
    //     flex-direction: column;
    //   }
    @media (max-width: 760px) {
      max-height: 100vh;
      max-width: 100%;
    }
  `;
  const InnerWrapper = styled.section`
    //   padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 100%;
    max-width: 75%;
    margin: 0;
    height: 100%;
    //   min-height: 40vh;
    //   max-height: 60vh;
    //   @media (max-width: 960px) {
    //     flex-direction: column;
    //   }
    @media (max-width: 760px) {
      max-height: 100vh;
      padding: 20px;
      max-width: 100%;
    }
  `;
  