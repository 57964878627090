import "bootstrap/dist/css/bootstrap.css"

import * as React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { Provider } from 'react-redux'
import store from 'src/redux/store'
import registerServiceWorker from "./registerServiceWorker"


const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(<Provider store={store}><App /></Provider>)

registerServiceWorker()
