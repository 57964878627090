import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./Profile.module.css";
import { FaRegEdit } from "react-icons/fa";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ProfileUpdate,ChangePasswordApi,PaymentDetailsApi } from "src/api/ApiConnect";
import { Post } from "src/api/apiConfig";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { countryList } from "src/util/countryList";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { addPaydata } from "src/redux/action/action"
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import ProfileSidebar from "../DashboardRevamp/ProfileSidebar";


export default function ProfileRevamp() {

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
  
    setState({ ...state, [anchor]: open });
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.request[0]);
  const [enableEditing, setEditing] = useState(false);
  const [fullname, setFullname] = React.useState(user?.full_name);
  const [gender, setGender] = React.useState(user?.user_gender);
  const [location, setLocation] = React.useState(user?.user_location);
  const [linkedinurl, setLinkedinurl] = React.useState(user?.linkedinurl);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [currenterror, setCurrenterror] = React.useState(false);
  const [newerror, setNewerror] = React.useState(false);
  const [confirmerror, setConfirmerror] = React.useState(false);

  const [currenterrormsg, setCurrenterrormsg] = React.useState('');
  const [newerrormsg, setNewerrormsg] = React.useState('');
  const [confirmerrormsg, setConfirmerrormsg] = React.useState('');
  
  const timer = React.useRef();

  const [currentpassword , setCurrentpassword] = useState('');
  const [newpassword , setNewpassword] = useState('');
  const [confirmpassword , setConfirmpassword] = useState('');

  const [paydetails, setPaydetails] = React.useState([]);
  const [coursefees, setCoursefees] = React.useState(0);
  const [totalpay, setTotalpay] = React.useState(0);


  useEffect(() => {
    
    onloadpaymentcall()
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const convertToDecimal = (inputValue) => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      return(value.toFixed(2));
    } else {
      return(0);
    }
  };

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);


  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const onloadpaymentcall = async () => {
    const RequestBody = {
      USER_EMAIL: user.email
    };
      const data = await Post(PaymentDetailsApi, RequestBody);
      if(data?.IsSuccess)
      {
        setPaydetails(data)
        setCoursefees(convertToDecimal(data?.COURSE_FEES))
        setTotalpay(data?.P_HISTORY.reduce((accumulator, item) => accumulator + Number(item.P_AMOUNT), 0 ))
      }

    };
  
  
  const onSavecall = async () => {

    const RequestBody = {
      USER_FULLNAME: fullname,
      USER_MOBILE: user?.mobile,
      USER_EMAIL: user.email,
      USER_GENDER: gender,
      USER_LOCATION: location,
      USER_COUNTRY: "",
    };

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
      const data = await Post(ProfileUpdate, RequestBody);
      setEditing(false);
    }
  };


  const changepasswordHandler = async () => {

    if(currentpassword ==='')
    {
      setCurrenterror(true);
      setCurrenterrormsg('Enter Current Password! ')
      return;
    }

    if(newpassword ==='')
    {
      setNewerror(true);
      setNewerrormsg('Enter New Password! ')
      return;
    }

    if(confirmpassword ==='')
    {
      setConfirmerror(true);
      setConfirmerrormsg('Enter Confirm Password! ')
      return;
    }

    if(newpassword!==confirmpassword)
    {
      setConfirmerror(true);
      setConfirmerrormsg('New password & Confirm Password not same!')
      return;
    }

    const RequestBody = {
      USER_EMAIL: user.email,
      USER_OLDPASSWORD: currentpassword,
      USER_NEWPASSWORD:newpassword,
    };

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
      const data = await Post(ChangePasswordApi, RequestBody);
      alert(data?.Message);
      setEditing(false);
    }
  };


  const payHandler = async () => {
    if (convertToDecimal(coursefees - totalpay) > 0) {
      dispatch(
        addPaydata({
          amount: `${convertToDecimal(coursefees - totalpay)}`,
          firstname: user?.full_name?.trimEnd(),
          email: user?.email,
          phone: user?.mobile,
          productinfo: user?.selected_course,
          lastname: user?.full_name?.trimEnd(),
        })
      );

      const abc= {
        amount: `${convertToDecimal(coursefees - totalpay)}`,
        firstname: user?.full_name?.trimEnd(),
        email: user?.email,
        phone: user?.mobile,
        productinfo: user?.selected_course,
        lastname: user?.full_name?.trimEnd(),
      }
      navigate("/Pay");
    }
  };
  

  return (
    
    <div className="p30">
         <AppBar
        color="transparent"
        position="static"
        elevation={1}
        style={{ border: "1px solid #544bbd" }}
        //   className="purpleBg"
      >
        <Toolbar className={styles.toolbar}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>My Profile</b>
          </Typography>
          <div className="flexSpaceCenter pb10">
           
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("right", true)}
            >
              <Menu />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <br/>

      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Card className={styles.cardDiv}>
            {/* <CardActionArea> */}
            <div className="flexCenter p15">
              <Avatar
                style={{ height: "200px", width: "200px" }}
                src={user?.photourl}
              />
            </div>
            <CardContent>
              <div className="flexSpaceCenter">
                <Typography>My Profile</Typography>
                {!enableEditing && (
                  <FaRegEdit onClick={() => setEditing(true)} />
                )}
              </div>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Full name"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    style={{ width: "100%" }}
                    disabled={!enableEditing}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={gender}
                      size="small"
                      onChange={(e) => setGender(e.target.value)}
                      fullWidth
                      label="Gender"
                      disabled={!enableEditing}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    size="small"
                    value={user?.email}
                    variant="outlined"
                    label="Email"
                    disabled={!enableEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    size="small"
                    variant="outlined"
                    value={user?.mobile}
                    label="Ph No."
                    disabled={!enableEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%" }}
                    size="small"
                    variant="outlined"
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    disabled={!enableEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={!enableEditing}
                    size="small"
                    fullWidth
                    id="country-select-demo"
                    options={countryList}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label} ({option.code}) +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    style={{ width: "100%" }}
                    variant="outlined"
                    label="LinkedIn url"
                    value={linkedinurl}
                    onChange={(e) => setLinkedinurl(e.target.value)}
                    disabled={!enableEditing}
                  />
                </Grid>
              </Grid>
            </CardContent>
            {/* </CardActionArea> */}
            {enableEditing && (
              <CardActions className="flexCenter">
                <Button
                  variant="contained"
                  className="lightPurpleBg whiteColor"
                  disabled={loading}
                  onClick={() => onSavecall()}
                >
                  Save
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </CardActions>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Card className={styles.cardDiv}>
                <CardHeader
                  className="purpleBg whiteColor"
                  title={
                    <Typography variant="body2" className="font18">
                      <b>My account summary</b>
                    </Typography>
                  }
                />
                <CardContent className="pb10">
                  <div className="flexSpaceCenter pb10">
                    <Typography variant="body2" className="font14">
                      Course fee (₹)
                    </Typography>
                    <Typography variant="body2" className="font14">
                      {convertToDecimal(coursefees || '00')}
                    </Typography>
                  </div>
                  <div className="flexSpaceCenter pb10">
                    <Typography variant="body2" className="font14">
                      Paid (₹)
                    </Typography>
                    <Typography variant="body2" className="font14">
                      {convertToDecimal(totalpay)}
                    </Typography>
                  </div>
                  <div className="flexSpaceCenter pb10">
                    <Typography variant="body2" className="font14">
                      Remaining (₹)
                    </Typography>
                    <Typography variant="body2" className="font14">
                      {convertToDecimal(coursefees-totalpay)}
                    </Typography>
                  </div>
                  <Divider />
                </CardContent>
                <CardActions className="flexCenter">
                  <Button
                    className="lightPurpleBg whiteColor"
                    variant="contained"
                    onClick={() => payHandler()}
                  >
                    Pay
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card className={styles.cardDiv}>
                <CardHeader
                  className="purpleBg whiteColor"
                  title={
                    <Typography variant="body2" className="font18">
                      <b>Change password</b>
                    </Typography>
                  }
                />
                <CardContent className="pb10">
                  <TextField
                    size="small"
                    className="pb10"
                    style={{ width: "100%" }}
                    variant="outlined"
                    label="Current Password"
                    value={currentpassword}
                    onChange={(e) => setCurrentpassword(e.target.value)}
                    helperText={currenterrormsg}
                    error={currenterror}
                    type={showCurrentPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )}}
                  />
                  <TextField
                    size="small"
                    className="pb10"
                    style={{ width: "100%" }}
                    variant="outlined"
                    label="New Password"
                    value={newpassword}
                    onChange={(e) => setNewpassword(e.target.value)}
                    helperText={newerrormsg}
                    error={newerror}
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )}}
                  />
                  <TextField
                    size="small"
                    className="pb10"
                    style={{ width: "100%" }}
                    variant="outlined"
                    label="Confirm Password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                    helperText={confirmerrormsg}
                    error={confirmerror}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )}}
                  />
                </CardContent>
                <CardActions className="flexCenter">
                  <Button
                    className="lightPurpleBg whiteColor"
                    variant="contained"
                    onClick={() => changepasswordHandler()}
                  >
                    Submit
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <ProfileSidebar toggleDrawer={() => toggleDrawer("right", false)} />
      </SwipeableDrawer>
    </div>
  );
}
