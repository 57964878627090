import { ADD_PAYDATA }  from "../action/action"

const initialState = {
  request: [],
};

export const paydataReducer = (state = initialState, action) => {
  switch (action?.type) {
    case ADD_PAYDATA:
      return {
        ...state,
        request: [...state?.request, action?.payload],
      };
    default:
      return state;
  }
};