import React, { useEffect, useState } from "react";
import styles from "./Videoplay.module.css";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { VideoDisplayApi } from "src/api/ApiConnect";
import { Post } from "src/api/apiConfig";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@mui/material";
import { TimeAgo } from "src/hooks/TimeAgo";
import {
  AppBar,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import ProfileSidebar from "../DashboardRevamp/ProfileSidebar";

const Videoplay = () => {

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.request[0]); 
  const [coursefile,setCoursefile] = useState(useSelector((state) => state.videoplay.request[0].index));
  const [Searchvalue, setSearchvalue] = React.useState("");
  const [dispVideos, setDispvideos] = useState([]);
  const [videosData, setVideosData] = useState([]);

  function handleEnter(event) {
    if (event.keyCode === 13) {
      getallvideos();
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    loabsearchbox();
    getallvideos();
  }, []);

  const loabsearchbox = async () => {
    const RequestBody = {
      USER_EMAIL: user.email,
      USER_BATCH: user.batch,
      USER_MEMBERSHIP_TYPE: user.membership_type,
      USER_COURSE: user.selected_course,
      USER_SEARCH: "",
    };

    const data = await Post(VideoDisplayApi, RequestBody);
    setDispvideos(data?.VIDEO);
  };

  const getallvideos = async () => {
    const RequestBody = {
      USER_EMAIL: user.email,
      USER_BATCH: user.batch,
      USER_MEMBERSHIP_TYPE: user.membership_type,
      USER_COURSE: user.selected_course,
      USER_SEARCH: Searchvalue,
    };
    const data = await Post(VideoDisplayApi, RequestBody);
    console.log(data, "data");
    setVideosData(data.VIDEO);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleClickVideo = async (index) => {
    setCoursefile(index);
    console.log(videosData, "videosData", index);
    document.getElementById("myVideo").load();
    document.getElementById("myVideo").play();
  };

  const searchclick = async () => {
    getallvideos();
  };

  return (
 
    <div className={styles.HomeLandingimgBox}>     
        <AppBar
        color="transparent"
        position="static"
        elevation={1}
        style={{ border: "1px solid #544bbd" }}
        //   className="purpleBg"
      >
        <Toolbar className={styles.toolbar}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>Course Videos</b>
          </Typography>
          <div className="flexSpaceCenter pb10">
            <div className={styles.searchBox}>
            <Autocomplete
                disablePortal
                size="small"
                id="searchcombobox"
                value={Searchvalue}
                onInputChange={(event, newValue) => {
                  setSearchvalue(newValue);
                }}
                className={styles.searchField}
                options={dispVideos?.map((item) => item.FILE_HEAD)}
                // sx={{ width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} label="Search For Session" />
                )}
              />
              <button
                className={styles.searchBtn}
                type="button"
                onClick={() => searchclick()}
              >
                <BiSearch color="white" />
              </button>
            </div>
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("right", true)}
            >
              <Menu />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <video
            className={styles.videoPlayer}
            onContextMenu={(e) => e.preventDefault()}
            controls
            autoPlay
            id="myVideo"
            width={
              width < 1250 ? `${width < 900 ? width : width - 332}px` : "100%"
            }
            controlsList="nodownload"
          >
            <source src={coursefile.FILE_URL} type="video/mp4" />
          </video>
          <b>
            <p style={{ coursefile: "left", fontSize: "18px" }}>
              {coursefile.FILE_HEAD}
            </p>
          </b>
          <p style={{ textAlign: "left", fontSize: "14px" }}>
            {TimeAgo(new Date(coursefile.FILE_DATE))} &nbsp;
            {coursefile.FILE_DATE}
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <div style={{ height: "100vh", overflow: "scroll" }}>
            {videosData?.length
              ? videosData.map((item) => (
                  <div
                    onClick={() => handleClickVideo(item)}
                    key={item.ID}
                    className={styles.card}
                  >
                    <div className={styles.categoryContainer}>
                      <img
                        src="images/playbutton.png"
                        className={styles.img}
                        alt="Computer"
                      ></img>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        padding: "8px",
                      }}
                    >
                      <p style={{ fontSize: "14px" }}>
                        <b>{item.FILE_HEAD}</b>
                      </p>
                      <p style={{ fontSize: "12px" }}>
                        {TimeAgo(new Date(coursefile.FILE_DATE))} &nbsp;
                      </p>
                      <p style={{ fontSize: "12px" }}>{coursefile.FILE_DATE}</p>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </Grid>
      </Grid>
  
       <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <ProfileSidebar toggleDrawer={() => toggleDrawer("right", false)} />
      </SwipeableDrawer>
      
    </div>
  );
};

export default Videoplay;
