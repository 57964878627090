import { createStore, combineReducers } from 'redux';
import { paydataReducer } from './reducer/paydataReducer';
import { loginReducer } from './reducer/loginReducer';
import { playdataReducer } from './reducer/playdataReducer';

const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {}


  const rootReducer = combineReducers({
    request: paydataReducer,
    user: loginReducer,
    videoplay: playdataReducer,
  });


const store = createStore(rootReducer, persistedState)
store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})
export default store 