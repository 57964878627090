import * as React from "react"
import {BiBell} from "react-icons/bi"
import { useState } from "react"
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap"
import { Link } from "react-router-dom"
import { BiMenu } from "react-icons/bi"
import "./Profilenavbar.css"
import { useDispatch, useSelector } from "react-redux"

const NavMenu = () => {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(state=>state.user)
  const userProfile = useSelector(state=>state.profile)

  const toggle = () => {
    setOpen(!isOpen)
  }

 
  
  return (
    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow sticky-top" >
      <Container>
        <NavbarBrand tag={Link} to="/">
          <img
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              marginRight: "16px",
            }}
            alt="logo.png"
            src={"./images/taglogo.png"}
          />
          TagSkills
        
          </NavbarBrand>
<div className="hai">{user.full_name}</div>
        <NavbarToggler onClick={toggle} className="mr-2 menuBtn">
          <BiMenu size={22} />
        </NavbarToggler>
        <Collapse
          className="d-sm-inline-flex flex-sm-row-reverse"
          isOpen={isOpen}
          navbar
        >
          <ul className="navbar-nav flex-grow">
        
              
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/Profile">
                Profile
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/Notification">
                <BiBell size={20}/>
              </NavLink>
            </NavItem>
          </ul>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default NavMenu
