import axios from "axios"
//UAT
//const BASE_URL= "https://uatapitagskills.tagskills.com/"


//const BASE_URL= "https://localhost:7273/"


//LIVE
const BASE_URL= "https://apilive.tagskills.com"
//const BASE_URL ="https://www.tagapi.in"
axios.defaults.baseURL = BASE_URL


export const Post = async (url, body) => {
  const headers = {
    'Content-Type': 'application/json',
  }
  if (localStorage.getItem("token")) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  }
 
  try {
    const { data } = await axios.post(url,body ,{ headers})
    return data
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
    }else{
    console.log('apierror',error);
    }
    return null
  }
}



export const PostWitoutToken = async (url, body) => {
  const headers = {
    'Content-Type': 'application/json',
  } 
  try {

    const { data } = await axios.post(url, body,{ headers})
    return data
  } catch (error) {
    console.log(error);
    return null
  }
}




export const Get = async ( url, token ) => {
  const headers = {
    'Content-Type': 'application/json',
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  try {
    const { data } = await axios.get(url, { headers })
    return data
  } catch (error) {
    return null
  }
} 

export const Getwithurl = async ( url ) => {
  try {
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    return null
  }
} 


