import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PriyankaImg from "../../../assets/images/team/PRIYANKA.jpeg";
import AmeerImg from "../../../assets/images/team/AMEER.jpeg";
import GopikaImg from "../../../assets/images/team/GOPIKA.jpeg";
import MayurImg from "../../../assets/images/team/MAYUR.png";
import RakshithImg from "../../../assets/images/team/RAKSHITH.jpeg";
import SanketImg from "../../../assets/images/team/SANKET.jpeg";
import SmithaImg from "../../../assets/images/team/SMITHA T.jpeg";

export default function Teams() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <ArrowCircleRightIcon className="arrowBtn" />,
    prevArrow: <ArrowCircleLeftIcon className="arrowBtn" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,

        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <ArrowCircleRightIcon className="arrowBtn" />,
    prevArrow: <ArrowCircleLeftIcon className="arrowBtn" />,
  };
  const teams = [
    {
      name: "PRIYANKA BAJORIA",
      jd: "BUSINESS DEVELOPMENT EXECUTIVE",
      img: PriyankaImg,
    },
    { name: "SONAR MAYUR MANOHAR", jd: "SENIOR ASSOCIATE", img: MayurImg },
    { name: "RAKSHITH N", jd: "SENIOR ASSOCIATE", img: RakshithImg },
    { name: "GOPIKA RAVEENDRAN", jd: "ASSOCIATE", img: GopikaImg },

    { name: "SANKET NIKAM", jd: "ASSOCIATE", img: SanketImg },

    { name: "AMEERSUHIL DAFEDAR", jd: "TRAINEE", img: AmeerImg },
    { name: "SMITHA T K", jd: "TECHNOLOGY LEAD", img: SmithaImg },
  ];
  return (
    <div id="teams">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Typography variant="h5">
          <b>Meet the Team</b>
        </Typography>
        <Typography variant="body2">
          Meet our team of professionals to serve you{" "}
        </Typography>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "block", lg: "block" },
        }}
      >
        <div style={{ padding: "30px" }}>
          <Slider {...settings}>
            {teams.map((item, i) => {
              return (
                <div key={"ury" + i}>
                  <Card className="cardDiv">
                    <CardMedia
                      sx={{ height: 140 }}
                      image={item?.img}
                      title={item.name}
                    />
                    <CardContent
                      style={{ textAlign: "center", padding: "5px" }}
                    >
                      <Typography
                        gutterBottom
                        variant="body2"
                        className="font8"
                        color="textSecondary"
                      >
                        {item.jd}
                      </Typography>
                      <Typography
                        className="font11"
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {item.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </Slider>
        </div>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "block", md: "none", lg: "none" },
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "25px",
            overflow: "scroll",
            padding: "10px",
          }}
        >
          {teams.map((item, i) => {
            return (
              <div key={"po" + i}>
                <Card className="cardDiv">
                  <CardMedia
                    sx={{ height: 140 }}
                    image={item?.img}
                    title={item.name}
                  />
                  <CardContent style={{ textAlign: "center", padding: "5px" }}>
                    <Typography
                      gutterBottom
                      variant="body2"
                      className="font8"
                      color="textSecondary"
                    >
                      {item.jd}
                    </Typography>
                    <Typography
                      className="font11"
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      {item.name}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            );
          })}
        </div>
      </Box>
    </div>
  );
}
