import React from "react";
import {
  Container,
  Divider,
  Typography,
  Box,
  Card,
  CardMedia,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import styled from "styled-components";
import Sponsorships from "../../assets/images/sponsorship/tagskill-min.jpg";
import Sponsorships2 from "../../assets/images/sponsorship/sponsorship2.jpg";

import SponsorshipImageList from "./SponsorshipImageList";
import styles from "./Sponsorship.module.css";
import { Link } from "react-scroll";
import LogoIcon from "src/assets/icons/Logo.png";

export default function Sponsorship() {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>  
    <TopDiv className="purpleBg whiteColor flexSpaceCenter">
    <Link
      className="pointer flexNullCenter"
      style={{ gap: "0px" }}
      to="home"
      smooth={true}
    >
      <img
        //   className={styles.navLogo}
        style={{
          width: "36px",
          height: "36px",
          alignSelf: "flex-start",
          // marginRight: "16px",
        }}
        alt="logo.png"
        src={LogoIcon}
      />
      <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
        TagSkills
      </h1>
    </Link>
    <Button startIcon={<ArrowBack />} href="/" className="whiteColor">
      Back
    </Button>
  </TopDiv>
    <Container maxWidth="xl">
      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={isMobile ? 2 : 4} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            
            <Typography
              className={styles.fontSize20}
              variant="body1"
              sx={{
                color: "#333",
                mb: "10px",
              }}
            >
              Boundless Boundaries: Trivarna Sagar's Journey with TagSkills
              Sponsorship
            </Typography>
            <Divider
              sx={{ backgroundColor: "#5249bd", height: "3px", mb: "20px" }}
            />
            <Typography
              variant="h3"
              className={`${styles.fontSize30} ${styles.bold}`}
              sx={{ color: "#5249bd", mb: "20px" }}
            >
              <b>Transforming Dreams into Reality with TagSkills</b>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Card
                sx={{
                  mr: isMobile ? "20px" : "40px",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <CardMedia
                  component="img"
                  src={Sponsorships}
                  alt="Sponsorship Image 1"
                  sx={{
                    height: isMobile ? "200px" : "400px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Card>
              <Card sx={{ borderRadius: "10px", overflow: "hidden" }}>
                <CardMedia
                  component="img"
                  src={Sponsorships2}
                  alt="Sponsorship Image 2"
                  sx={{
                    height: isMobile ? "200px" : "400px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                mt: "20px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "20px",
                mb: isMobile ? "20px" : "40px",
                height: isMobile ? "auto" : "fit-content", // Ensuring height matches combined height of images
              }}
            >
              <Typography
                variant="body1"
                className={styles.font12}
                sx={{ color: "#333", mb: "20px" }}
              >
                In sports, sponsors nurture talent. Trivarna Sagar's cricket
                dreams thrive under TagSkills, introduced by friend Prashun
                Shetty. TagSkills identifies and nurtures Trivarna's talent,
                boosting his cricket success.
              </Typography>
              <Typography
                variant="body1"
                className={styles.font12}
                sx={{ color: "#333", mb: "20px" }}
              >
                Balancing full-time cricket and part-time gym training, Trivarna
                shows dedication. With TagSkills, he excels in cricket, focusing
                on his passion while exploring other interests.
              </Typography>
              <Typography
                variant="body1"
                className={styles.font12}
                sx={{ color: "#333", mb: "20px" }}
              >
                Armed with bat and gloves, TagSkills transforms Trivarna's
                journey. It equips him for sporting excellence and opens doors
                to new opportunities.
              </Typography>
              <Typography
                variant="body1"
                className={styles.font12}
                sx={{ color: "#333", mb: "20px" }}
              >
                Trivarna's family provides unwavering support from scenic Sagar
                Taluk. Rooted in familial love, his aspirations flourish.
              </Typography>
              <Typography
                variant="body1"
                className={styles.font12}
                sx={{ color: "#333", mb: "20px" }}
              >
                In bustling Bangalore, Trivarna's cricket ambitions thrive with
                family and friends' backing.
              </Typography>
              <Typography
                variant="body1"
                className={styles.font12}
                sx={{ color: "#333" }}
              >
                TagSkills fuels Trivarna's dreams. With Prashun Shetty’s
                support, he's set for greater cricketing heights.
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px",
                height: "fit-content", // Ensuring height fits content
              }}
            >
              <Typography
                variant="body1"
                className={styles.font12}
                sx={{ color: "#333", p: "10px" }}
              >
                With his unwavering determination and the steadfast support of
                TagSkills, family, and friends, Trivarna Sagar stands poised on
                the brink of greatness. As he continues to excel on the cricket
                field, may his journey be paved with success and may his dreams
                of representing the Indian cricket team become a reality. Best
                wishes to Trivarna as he embarks on this thrilling adventure,
                with the hopes and aspirations of a nation behind him.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mt: isMobile ? "20px" : "10px" }}>
              <Grid
                container
                spacing={isMobile ? 2 : 4}
                justifyContent="flex-end"
              >
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      padding: "0px 10px ",
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={styles.font12}
                      sx={{ color: "#333", mb: "10px" }}
                    >
                     - Aishwarya Fernandes / Editor-in-Chief
                    </Typography>
                    <Divider
                      sx={{
                        backgroundColor: "#5249bd",
                        height: "3px",
                        width: "100%",
                        mb: "10px",
                      }}
                    />

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: "20px" }}>
          <SponsorshipImageList />
        </Box>
      </Box>
    </Container>
    </div>
  );
}

const Wrapper = styled.section`
padding-top: 30px;
width: 100%;
margin: 0;
height: 100%;
min-height: 30vh;
max-height: 40vh;
//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
@media (max-width: 760px) {
  max-height: 100vh;
  min-height: 20vh;
  padding-top: 15px;
}
`;
const TopDiv = styled.section`
width: 100%;
margin: 0;
height: 100%;
padding: 20px;
//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
`;
const SideDiv = styled.section`
position: absolute;
right: 50px;
top: 30px;
//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
@media (max-width: 760px) {
  display: none;
}
`;

const BroderContainer = styled.section`
border: 1px solid lightgray;
width: 100%;
margin: 0;
padding: 20px;
height: 100%;
max-width: 75%;
min-height: 40vh;
max-height: 60vh;
//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
@media (max-width: 760px) {
  max-height: 100vh;
  max-width: 100%;
}
`;
const FooterDiv = styled.section`
width: 100%;
//   margin: 10px;
padding: 20px;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
//   max-width: 75%;
//   min-height: 20vh;
//   max-height: 20vh;
//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
@media (max-width: 760px) {
  max-height: 100vh;
  max-width: 100%;
}
`;

const BroderLessContainer = styled.section`
//   border: 1px solid lightgray;
width: 100%;
margin: 0;
padding: 20px;
height: 100%;
max-width: 75%;
min-height: 15vh;
max-height: 20vh;
//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
@media (max-width: 760px) {
  max-height: 100vh;
  max-width: 100%;
}
`;
const InnerWrapper = styled.section`
//   padding-top: 80px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: left;
width: 100%;
max-width: 75%;
margin: 0;
height: 100%;
//   min-height: 40vh;
//   max-height: 60vh;
//   @media (max-width: 960px) {
//     flex-direction: column;
//   }
@media (max-width: 760px) {
  max-height: 100vh;
  padding: 20px;
  max-width: 100%;
}
`;
