import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import {
  FaBook,
  FaFilePdf,
  FaVideo,
} from "react-icons/fa";

import {
  AppBar,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Menu,
} from "@mui/icons-material";
import styled from "styled-components";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import styles from "src/screens/Dashboard/Dashboard.module.css";
import { useNavigate } from "react-router-dom";
import { TimeAgo } from "src/hooks/TimeAgo";
import ProfileSidebar from "./ProfileSidebar";
import { useState } from "react";

export default function DashboardRevamp() {
  const dashboardDisp = [];

  const user = useSelector((state) => state.user.request[0]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClickVideo = async (index) => {
    //dispatch(getcoursefile(index));
    navigate("/Videoplay");
  };
  const handleNavigation = (val) => {
    navigate(val);
  };

  return (
    <div style={{ padding: "15px" }}>
      <React.Fragment key={"right"}>
        <AppBar
          color="transparent"
          position="static"
          elevation={1}
          style={{ border: "1px solid #544bbd" }}
          //   className="purpleBg"
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <b>Dashboard</b>
            </Typography>
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("right", true)}
            >
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <div className="container">
          <Grid container gap={5}>
            <Grid
              item
              xs={12}
              md={5}
              style={{
                backgroundColor: "#1C45A6",
                cursor: 'pointer',
                gap: "8px",
              }}
              className="card flexCenter flexColumn"
            >
              <Avatar className="whiteBg">
                <FaBook style={{ color: "#1C45A6" }} />
              </Avatar>
              <Typography variant="body2" className="font18 whiteColor">
                <b>Latest Interview questions</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className="card flexCenter flexColumn"
              style={{
                backgroundColor: "#FFC42B",
                cursor: 'pointer',
                gap: "8px",
              }}
              onClick={() => handleNavigation("/Coursevideo")}
            >
              <Avatar className="whiteBg">
                <FaVideo style={{ color: "red" }} />
              </Avatar>
              <Typography variant="body2" className="font18 whiteColor">
                <b>Video</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className="card flexCenter flexColumn"
              style={{
                backgroundColor: "#EB6F64",
                cursor: 'pointer',
                gap: "8px",
              }}
              onClick={() => handleNavigation("/Pdfmaterials")}
            >
              <Avatar className="whiteBg">
                <FaFilePdf style={{ color: "red" }} />
              </Avatar>
              <Typography variant="body2" className="font18 whiteColor">
                <b>Pdf</b>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <br />
        <div className="container">
          <Grid container gap={3}>
            <Grid item xs={12} md={5}>
              <Typography variant="h5">
                <b> Course Details</b>
              </Typography>
              <br />
              <Card
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <CardHeader
                  style={{ height: "150px", backgroundColor: "#F0ECF9" }}
                  title={
                    <div className="flexCenter flexColumn ">
                      <FaBook className="font60" style={{ color: "#6F42C1" }} />
                    </div>
                  }
                />
                <CardContent
                  style={{
                    height: "150px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className="purpleColor"
                  >
                   {user?.selected_course}
                  </Typography>
                </CardContent>
                <Divider />
                <CardActions>
                  <Typography variant="body2" className="font14 purpleColor">
                    COURSE STATUS : {user?.course_status}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} className="flexCenter flexColumn ">
              <Typography variant="body2" className="font16">
                Recently added videos
              </Typography>
              <div
                style={{
                  height: "400px",
                  overflow: "scroll",
                  paddingTop: "20px",
                }}
              >
                <div
                  className="flexCenter flexColumn "
                  style={{
                    gap: "15px",
                  }}
                >
                  {dashboardDisp.map((item) => {
                    return (
                      <div
                        onClick={() => handleClickVideo(item)}
                        key={item.ID}
                        className={styles.card}
                      >
                        <div className={styles.categoryContainer}>
                          <div style={{ width: "100%", transition: "0.3s" }}>
                            <img
                              src="images/playbutton.png"
                              style={{
                                width: "100%",
                                height: "200px",
                                padding: 0,
                                borderRadius: "8px 8px 0px 0px",
                                objectFit: "fill",
                                transition: "0.3s",
                              }}
                              alt="Computer"
                            ></img>
                          </div>
                          <p className={styles.videoName}>
                            <b>{item.FILE_HEAD}</b>
                          </p>
                          <p
                            className={styles.videoName}
                            style={{ textAlign: "left", fontSize: "14px" }}
                          >
                            {TimeAgo(new Date(item.FILE_DATE))} &nbsp;
                            {item.FILE_DATE}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          <ProfileSidebar toggleDrawer={() => toggleDrawer("right", false)} />
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
const TopSection = styled.div`
  padding: 20px 30px;
  //   padding: 10px;
  @media (max-width: 760px) {
    padding: 10px;
  }
`;
const CardSection = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 760px) {
    padding: 10px;
  }
`;
