import React, { useEffect, useState } from "react";
import NavMenu from "src/components/NavMenu/NavMenu";
import styles from "./Reviewall.module.css";
import { BsStarFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { FiLinkedin } from "react-icons/fi";
import { ReviewDisplay } from "src/api/ApiConnect";
import { Get } from "src/api/apiConfig";

const Reviewall = () => {
  const dispatch = useDispatch();
  const [allttest, setTtest] = useState([]);
  useEffect(() => {
    getreviewall();
  }, []);

  const getreviewall = async () => {
    const RequestBody = {};
    const data = await Get(ReviewDisplay, RequestBody);
    setTtest(data.getInformation);
    //dispatch(getuserreviewall(data.getInformation));
  };

  return (
    <div>
      <NavMenu />
      <div className={styles.HomeLandingBox}>
        <div className={styles.reviewGrid}>
          {allttest.map((item) => (
            <div key={item.ID} className={styles.reviewCard}>
              <div className={styles.buttonbox}>
                <div className={styles.buttontop}>
                  <img
                    src={item.IMGURL}
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: 0,
                    }}
                    alt="Computer"
                  />
                </div>
                <div className={styles.buttonbottom}>
                  <div className={styles.name}>{item.NAME}</div>
                  <div className={styles.year}>{item.EXP} YEAR</div>
                  <div className={styles.company}>{item.COMPANY}</div>
                  <div className={styles.star}>
                    <BsStarFill
                      color="rgb(230, 155, 17)"
                      size={20}
                      className={styles.icon}
                    />
                    <BsStarFill
                      color="rgb(230, 155, 17)"
                      size={20}
                      className={styles.icon}
                    />
                    <BsStarFill
                      color="rgb(230, 155, 17)"
                      size={20}
                      className={styles.icon}
                    />
                    <BsStarFill
                      color="rgb(230, 155, 17)"
                      size={20}
                      className={styles.icon}
                    />
                    <BsStarFill
                      color="rgb(230, 155, 17)"
                      size={20}
                      className={styles.icon}
                    />
                  </div>
                  <div className={styles.lin}>
                    <FiLinkedin size={20} color=" rgb(71, 71, 138)" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviewall;
