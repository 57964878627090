import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Rating,
  Tooltip,
  Typography,Link
} from "@mui/material";
import React, { useEffect, useState } from "react";
import gIcon from "src/assets/images/gIcon.png";
import image1 from "src/assets/images/image1.png";
import image2 from "src/assets/images/image2.png";
import image3 from "src/assets/images/image3.png";
import image4 from "src/assets/images/image4.png";
import image5 from "src/assets/images/image5.png";
import GridViewIcon from "@mui/icons-material/GridView";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import person1 from "src/assets/images/person1.png";
import person2 from "src/assets/images/person2.png";
import person3 from "src/assets/images/person3.png";
import person4 from "src/assets/images/person4.png";

import person5 from "src/assets/images/person5.png";
import { LinkedIn } from "@mui/icons-material";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { reviewall } from "src/api/api";
import { AllReviewDisplay } from "src/api/ApiConnect";
import { Post } from "src/api/apiConfig";
import { useDispatch } from "react-redux";
import EngineeringIcon from '@mui/icons-material/Engineering';

export default function Stories() {
  const [viewMode, setViewMode] = useState("less");
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getreviewall();
  }, []);

  const getreviewall = async () => {
    const RequestBody = {
      FILTER: 'ALL'
    };
    const data = await Post(AllReviewDisplay, RequestBody);
    // setUsers(data?.STORY);
    setUsers(data?.STORY.sort((a, b) => {
      return new Date(b.DATETIME) - new Date(a.DATETIME); // Sorts in descending order
  }));
    //dispatch(getuserreviewall(data?.getInformation));
  };

  return (
    <div id="stories">
      <Grid container spacing={2} className="animation">
        <Grid
          className="anm_mod left delay"
          item
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">
            <b>Real Success Stories</b>
          </Typography>
          <Typography variant="body2">
          TagSkills isn't just about acquiring new skills; it's about transforming lives. Our alumni network is a testament to the impact of our training, with countless graduates going on to lead successful careers in their chosen fields. Your success story starts here.
          </Typography>
          <div className="ratingDiv">
            <List>
              <ListItem>
                <ListItemAvatar>
                  <img
                    src={gIcon}
                    style={{ width: "50px", display: "flex" }}
                    alt="icon"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<Rating name="read-only" value={4.9} readOnly />}
                  secondary={
                    <div>
                      <b>4.9</b>
                      &nbsp;
                      <span
                        onClick={() =>
                          window.open(
                            "https://www.google.com/search?q=tagskills&rlz=1C1CHBF_enIN994IN994&oq=tagskills&aqs=chrome..69i57j46i175i199i512j0i10l2j46i10i175i199j69i60l2j69i61.5342j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3bae3f50cd3efe11:0x45ac4731b6278edb,1,,,",
                            "_blank"
                          )
                        }
                        style={{ color: "#00C9FF", cursor: "pointer" }}
                      >
                        <u>192 Google Reviews</u>
                      </span>
                    </div>
                  }
                />
              </ListItem>
            </List>
          </div>
        </Grid>{" "}
        <Grid item xs={12} md={6} className="anm_mod right delay">
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "block" },
              justifyContent: "end",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="imgDiv">
                  <Img1 src={image1} alt="img" />
                  <Img2 src={image2} alt="img" />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="imgDiv">
                  <Img3 src={image3} alt="img" />
                  <Img4 src={image4} alt="img" />
                  <Img5 src={image5} alt="img" />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        spacing={3}
        // style={{ margin: "15px 0px" }}
        className="animation"
      >
        {viewMode === "less" ? (
          <>
            {users.slice(0, 6).map((item, i) => {
              return (
                <Grid
                  key={"a" + i}
                  item
                  xs={12}
                  md={4}
                  className="cardDiv anm_mod full"
                >
                  <Card className="cardDiv" style={{ padding: "10px" }}>
                    <CardContent
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <LazyLoadImage
                        src={item?.IMG_URL}
                        alt="img"
                        style={{ width: "100px", height: "100px" }}
                      />
                      <div>
                        <Typography variant="h6">
                          <b>{item?.NAME}</b>
                        </Typography>
                        <div>
                          <Tooltip title={item?.COMMENT}>
                            <Typography variant="body2" className="font12">
                              {item?.COMMENT.substr(0, 100)}
                              <b>...</b>
                            </Typography>
                          </Tooltip>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <Typography className="font11" variant="body2">
                              <GridViewIcon className="font14" />{" "}
                              {item?.COMPANY}
                            </Typography>
                            <Typography className="font11" variant="body2">
                              <EngineeringIcon className="font14" />
                              {item?.EXPIRENCE ==='0'? ' Fresher' : ' '+item?.EXPIRENCE+' Year Exp'}  
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <CardActions>
                    <Link href={item?.LINKEDINURL}  target="_blank" >
                      <LinkedIn className="linedIn" />
                      </Link>
                      <Rating name="read-only" value={5} readOnly />
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </>
        ) : (
          <>
            {users.map((item, i) => {
              console.log('999',users)
              return (
                <Grid
                  key={"a" + i}
                  item
                  xs={12}
                  md={4}
                  className="cardDiv anm_mod full"
                >
                  <Card className="cardDiv" style={{ padding: "10px" }}>
                    <CardContent
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <LazyLoadImage
                        src={item?.IMG_URL}
                        alt="img"
                        style={{ width: "100px", height: "100px" }}
                      />
                      <div>
                        <Typography variant="h6">
                          <b>{item?.NAME}</b>
                        </Typography>
                        <div>
                          <Tooltip title={item?.COMMENT}>
                            <Typography variant="body2" className="font12">
                              {item?.COMMENT.substr(0, 100)}
                              <b>...</b>
                            </Typography>
                          </Tooltip>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <Typography className="font11" variant="body2">
                              <GridViewIcon className="font14" />{" "}
                              {item?.COMPANY}
                            </Typography>
                            <Typography className="font11" variant="body2">
                              <EngineeringIcon className="font14" />
                              {item?.EXPIRENCE ==='0'? ' Fresher' : ' '+item?.EXPIRENCE+' Year Exp'}  
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <CardActions>
                    <Link href={item?.LINKEDINURL}  target="_blank" >
                      <LinkedIn className="linedIn" />
                      </Link>
                      <Rating name="read-only" value={5} readOnly />
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </>
        )}
        <br />
      </Grid>
      {viewMode === "less" ? (
        <div style={{ marginTop: "15px", textAlign: "center" }}>
          <Button className="btn" onClick={() => setViewMode("more")}>
            Load More
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: "15px", textAlign: "center" }}>
          <Button className="btn" onClick={() => setViewMode("less")}>
            Load Less
          </Button>
        </div>
      )}
    </div>
  );
}
const Img1 = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  @media (max-width: 760px) {
    width: 120px;
    height: 120px;
  }
`;
const Img2 = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  @media (max-width: 760px) {
    width: 80px;
    height: 80px;
  }
`;
const Img3 = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  @media (max-width: 760px) {
    width: 140px;
    height: 140px;
  }
`;
const Img4 = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  @media (max-width: 760px) {
    width: 120px;
    height: 120px;
  }
`;
const Img5 = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  @media (max-width: 760px) {
    width: 90px;
    height: 90px;
  }
`;
