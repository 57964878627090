import React, { useEffect, useRef, useState } from "react"
import { apicertificate } from "src/api/api"
import styles from "./Certificate.module.css"
import Profilenavbar from "src/components/Profilenavbar/Profilenavbar"
import { useNavigate } from "react-router-dom"
import { renderToString } from "react-dom/server";
import { CertificateDisplayApi } from "src/api/ApiConnect"
import { Post } from "src/api/apiConfig";
import { useDispatch, useSelector } from "react-redux"
import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'
import {
  AppBar,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import ProfileSidebar from "../DashboardRevamp/ProfileSidebar";
import dayjs from "dayjs";




const Certificate = () => {
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
  
    setState({ ...state, [anchor]: open });
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  
  const user = useSelector((state) => state.user.request[0]);

    const [certificateresponse, setCertificateresponse] = useState([])

    const pdfRef = useRef()

    const COURSEINCOMPLETE= 
    { body: (
      <p className={styles.complete}>NOT COMPLETED</p>
      )
    }

    const COURSECOMPLETE= 
    { body: (
      <p className={styles.complete}>SUCCESSFULLY COMPLETED THE COURSE</p>
      )
    }

    const printDocument = () =>{
      // const input = document.getElementById('divToPrint');
      // html2canvas(input)
      //   .then((canvas) => {
      //     const imgData = canvas.toDataURL('image/png');
      //     const pdf = new jsPDF({
      //       orientation: "landscape",
      //       unit: "cm",
      //       format:[21.0 , 29.7]
      //     });
         
      //     pdf.addImage(imgData, 'JPEG', 0, 0);
      //     // pdf.output('dataurlnewwindow');
      //     pdf.save("tagskills_certificate.pdf");
      //   })

        // let htmlElement = document.getElementById('divToPrint');
        // let elementAsString = renderToString(htmlElement);
        // var doc = new jsPDF();
        // doc.html(elementAsString, {
        //   callback: function (doc) {
        //     doc.save("test.pdf");
        //   },
        //   x: 10,
        //   y: 10,
        // });

      const content = pdfRef.current;

    const doc = new jsPDF(
      {
        orientation:"landscape",
        unit:"mm",
        format:[156, 278]
    }
    );
    doc.html(content, {
        callback: function (doc) {
            doc.save(certificateresponse?.FULLNAME+'certificate.pdf');
        },
        width: 100, // <- here
        windowWidth: 340 // <- here
    });
    }
    
    useEffect(() => {
      getcertificate();
     
    },[])
    
      const getcertificate = async () => {

        const RequestBody = { USER_EMAIL:user.email}
        const data = await Post(CertificateDisplayApi,RequestBody)
        setCertificateresponse(data)

      }
  
  return (
  
           
      <div className={styles.HomeLandingimgBox} >
      <AppBar
        color="transparent"
        position="static"
        elevation={1}
        style={{ border: "1px solid #544bbd" }}
        //   className="purpleBg"
      >
        <Toolbar className={styles.toolbar}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>My Certificates</b>
          </Typography>
          <div className="flexSpaceCenter pb10">
           
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("right", true)}
            >
              <Menu />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
        <div  className={styles.reviewGrid}>
        
            <div  ref={pdfRef}   className={styles.reviewCard}>
                <div className={styles.box}>
                <h6 className={styles.cid}>Certification ID:{certificateresponse?.ID}</h6>
                <h2 className={styles.tag}>
                <img
                  style={{
                    width: "36px",
                    height: "36px",
                    alignSelf: "flex-start",
                    marginRight: "16px",
                  }}
                  alt="logo.png"
                  src={"./images/taglogo.png"}
                />
                  TagSkills

           </h2>
                <p className={styles.complete}>Certification of Completion</p>
                <h2 className={styles.myname}>{certificateresponse?.FULLNAME}</h2>
                <p className={styles.complete}>Course Status : {certificateresponse?.COURSE_STATUS ==='COMPLETED' ?'Completed':'Not Completed'}</p>  
                <h5 className={styles.course}>{certificateresponse?.COMPLETEDCOURSE }</h5>
                <div className={styles.linebox}></div>
                
                <p className={styles.complete}>Completed Date : {certificateresponse?.COURSE_STATUS ==='COMPLETED' ? dayjs(certificateresponse?.COMPLETEDDATE).format("DD-MMM-YYYY") : 'NA'}</p>   
                 <div className={styles.sbox}>
                   <div className={styles.instructor}>
                   <h5 className={styles.course}></h5>
                <div className={styles.linebox}></div>
                <p className={styles.complete}>CEO of TagSkills</p> 
                    </div>
                   </div>
                   <div className={styles.validation}>
                   <p className={styles.link} onClick={() => window.open("https://www.tagskills.com/Validatecertificate","_blank")}>{" "}
                 To verify this certificate visit  https://www.tagskills.com/Validatecertificate</p>
                        </div>
                   <div className={styles.footer}>
                       <div className={styles.link} onClick={() => window.open("https://www.tagskills.com/","_blank")}>{" "}
                        Website:www.tagskills.com  </div>
                       <div className={styles.link} onClick={() => window.open("mailto:support@tagskills.com", "_blank")}>{" "}
                        Email: Support@tagskills.com </div>
                       <div className={styles.link} onClick={() => window.open("tel: +91 6360959192", "_blank")}>{" "}
                        Phone: +91 6360959192 </div>    
                        
                        </div>
                </div>
            </div>
            <button onClick={printDocument} className={styles.Readmorebtn}>
                <p className={styles.linkItem}>Download</p>
              </button>   
        </div>
       
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <ProfileSidebar toggleDrawer={() => toggleDrawer("right", false)} />
      </SwipeableDrawer>

    </div>
  )
}

export default Certificate
