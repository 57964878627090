import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import LogoIcon from "src/assets/icons/Logo.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ActivityIndicator from "src/components/ActivityIndicator/ActivityIndicator";
import CareerTransformationAd from "./CareerTransformationAd";
import { PostWitoutToken } from "src/api/apiConfig";
import { BookDemoApi } from "src/api/ApiConnect";
import Bookdemo from '../../assets/images/bookdemo.jpeg';
import { useNavigate } from "react-router-dom";


export default function BookDemo() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const getcouponcode = queryParams.get("coupon");

  const [localfullname, setLocalFullname] = useState("");
  const [localmobile, setLocalMobile] = useState("");
  const [localemail, setLocalEmail] = useState("");
  const [couponcode, setCouponcode] = useState(getcouponcode || "");

  const [localfullnameError, setLocalFullnameError] = useState(null);
  const [localmobileError, setLocalMobileError] = useState(null);
  const [localemailError, setLocalEmailError] = useState(null);

  const onSubmit = async () => {
    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setLocalFullnameError(null);
    setLocalMobileError(null);
    setLocalEmailError(null);

    if (localfullname.length === 0) {
      setLocalFullnameError("Please enter your full name");
      return;
    }

    if (localmobile.length === 0) {
      setLocalMobileError("Please enter your mobile number");
      return;
    }

    if (localemail.length === 0) {
      setLocalEmailError("Please enter your email");
      return;
    }

    if (!emailPattern.test(localemail)) {
      setLocalEmailError("Enter a valid Email Id");
      return;
    }

    setLoading(true);

    const RequestBody = {
      USER_FULLNAME: localfullname,
      USER_MOBILE: localmobile,
      USER_EMAIL: localemail,
      COURSE_CATEGORY: "ERP",
      SOURCE: "CURIOSTUFF",
      COUPON: couponcode?.toUpperCase(),
    };

    const data = await PostWitoutToken(BookDemoApi, RequestBody);
    setLoading(false);
    alert(data.Message);
  };

  return (
    <>
      <div>
        <Wrapper className="purpleBg">
          <Header>
            <Link
              className="pointer flexNullCenter"
              style={{ gap: "0px" }}
              to="home"
              smooth={true}
            >
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  alignSelf: "flex-start",
                }}
                alt="logo.png"
                src={LogoIcon}
              />
              <h1
                style={{ marginLeft: "15px" }}
                className="font20 extraBold whiteColor"
              >
                TagSkills
              </h1>
            </Link>
          </Header>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ContentWrapper>
                <Typography variant="h3" className="font30 whiteColor">
                Unlock Your IT Career with SAP Online Training 
                From Tagskills
                </Typography>
                <br />
              </ContentWrapper>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", justifyContent: "center" }}
            ></Grid>
          </Grid>
        </Wrapper>
        <Wrapper style={{ paddingBottom: "30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <img
              src={Bookdemo}
                alt=""
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
            </Grid>
            <Grid item xs={12} md={6} className="flexCenter">
              <Box
                sx={{
                  width: "400px",
                  backgroundColor: "white",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  position: { xs: "relative", md: "absolute" },
                  top: { md: "100px", lg: "150px" },
                }}
              >
                <CardContent className="flexNullCenter flexColumn p30">
                  <TextField
                    variant="outlined"
                    size="small"
                    className="pb10"
                    label="Full Name"
                    style={{ width: "100%" }}
                    value={localfullname}
                    onChange={(e) => setLocalFullname(e.target.value)}
                    helperText={localfullnameError}
                    autoComplete="off"
                    inputProps={{ maxLength: 25 }}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Email id"
                    className="pb10"
                    style={{ width: "100%" }}
                    value={localemail}
                    onChange={(e) => setLocalEmail(e.target.value)}
                    helperText={localemailError}
                    autoComplete="off"
                    inputProps={{ maxLength: 25 }}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    className="pb10"
                    label="Mobile number"
                    style={{ width: "100%" }}
                    id="mobile"
                    value={localmobile}
                    onChange={(e) => setLocalMobile(e.target.value)}
                    autoComplete="off"
                    helperText={localmobileError}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Enter Coupon Code (Optional)"
                    className="pb10"
                    style={{ width: "100%" }}
                    value={couponcode}
                    onChange={(e) => setCouponcode(e.target.value)}
                  />
                </CardContent>
                <CardActions className="flexNullCenter flexColumn p30">
                  <div className="flexNullCenter pb10">
                    <Typography variant="body2" className="font11">
                      By clicking Submit, you agree to receive communications
                      from TagSkills in accordance with our{" "}
                      <a href="" className="lightPurpleColor" onClick={() => navigate("/Policy")}>
                        Privacy Policy
                      </a>
                    </Typography>
                  </div>
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    className="lightPurpleBg"
                    disabled={loading}
                    onClick={onSubmit}
                    type="button"
                  >
                    {!loading ? <p>Submit</p> : <ActivityIndicator />}
                  </Button>
                </CardActions>
                <br />
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </div>

      <div>
        <CareerTransformationAd />
      </div>
    </>
  );
}

const Wrapper = styled.section`
  padding: 30px;
  width: 100%;
  margin: 0;
  height: 100%;
  background-color: ${(props) =>
    props.className === "purpleBg" ? "#6a1b9a" : "#fff"};
  @media (max-width: 560px) {
    max-height: 100vh;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
  height: 100%;
`;
