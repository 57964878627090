import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Paper,
  Avatar,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-scroll";
import { ArrowBack } from "@mui/icons-material";
import LogoIcon from "src/assets/icons/Logo.png";
import styled from "styled-components";
import "../../../src/styles.css";
import styles1 from "./VerifyCertificate.module.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5249bd",
    },
  },
});

const VerifyCertificate = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [details, setDetails] = useState(null);

  const handleInputChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const fetchDetails = () => {
    //  API call
    setDetails({
      name: "Sachin K",
      course: "SAP MM",
      status: "In Progress",
      date: "21-03-2023",
    });
  };

  return (
    <Box>
      <TopDiv className="purpleBg whiteColor flexSpaceCenter">
        <Link
          className="pointer flexNullCenter"
          style={{ gap: "0px" }}
          to="home"
          smooth={true}
        >
          <img
            //   className={styles.navLogo}
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              // marginRight: "16px",
            }}
            alt="logo.png"
            src={LogoIcon}
          />
          <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
            TagSkills
          </h1>
        </Link>
        <Button startIcon={<ArrowBack />} href="/" className="whiteColor">
          Back
        </Button>
      </TopDiv>
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm" sx={{ mt: 4 }}>
          <Paper
            elevation={3}
            sx={{ p: 4, textAlign: "center", borderRadius: 3 }}
          >
            <Avatar
              sx={{
                mb: 2,
                mx: "auto",
                bgcolor: "primary.main",
                width: 56,
                height: 56,
              }}
            >
              <CheckCircleOutlineIcon fontSize="large" />
            </Avatar>
            <Typography
              variant="h5"
              className="font14"
              gutterBottom
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              Certificate Verification
            </Typography>
            <TextField
              fullWidth
              label="Enter your mobile number"
              variant="outlined"
              className={`${styles1.font12} ${styles1.textfield}`}
              value={mobileNumber}
              onChange={handleInputChange}
              sx={{ mb: 3 }}
            />
            <Button
              size="small"
              variant="contained"
              className={`${styles1.btn} ${styles1.font12}`}
              onClick={fetchDetails}
              sx={{
                mb: 4,
              }}
            >
              Get Details
            </Button>
            {details && (
              <Box sx={{ textAlign: "left", mt: 3 }}>
                <Typography className={styles1.font12} sx={{ mb: 1 }}>
                  <strong>Name:</strong> {details.name}
                </Typography>
                <Typography
                  className={styles1.font12}
                  sx={{ fontSize: "16px", mb: 1 }}
                >
                  <strong>Course:</strong> {details.course}
                </Typography>
                <Typography
                  className={styles1.font12}
                  sx={{ fontSize: "16px", mb: 1 }}
                >
                  <strong>Course Status:</strong> {details.status}
                </Typography>
                <Typography
                  className={styles1.font12}
                  sx={{ fontSize: "16px" }}
                >
                  <strong>Date:</strong> {details.date}
                </Typography>
              </Box>
            )}
          </Paper>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default VerifyCertificate;
const TopDiv = styled.section`
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 20px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
`;
