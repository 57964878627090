import React, { useEffect, useState } from "react"
import { PaymentApi } from "src/api/ApiConnect"
import { PostWitoutToken } from "src/api/apiConfig";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"


const Pay = () => {
 const request = useSelector((state) => state.request.request);
  const [loading, setLoading] = useState()
  const user = useSelector(state=>state.user)
  const [paymenthtmlbody,setHtmlbody]=useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    getpayment()
  },[])

  const getpayment = async () => {
const data = await PostWitoutToken(PaymentApi,request[0])
      setHtmlbody(data.HTMLBODY)
      setTimeout(() => {
        document.getElementById('PostForm').submit();
    }, 1000); 
    }

  return(
    <div>
      {paymenthtmlbody ? (
                <div dangerouslySetInnerHTML={{ __html: paymenthtmlbody }} />
            ) : (
                <p>Loading payment form...</p>
            )}
    </div>
  )
}
export default Pay
