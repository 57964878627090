import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

export default function GateWayText() {
  return (
    <Wrapper id="success">
      <Typography variant="h4">
        <b>Your Gateway to success, We provide Experience!</b>
      </Typography>
      <br />
      <Typography variant="body2">
        TagSkills has been committed to providing quality education and
        knowledge to many aspirants, focusing on effective employability
        training and rewarding career. We recognize that different candidates
        have different training requirements, so we provide a variety of courses
        to a different set of candidates. Our training ensures IT professionals,
        business users, and decision-makers have the knowledge they need to
        drive an enterprise effectively. Established in 2020, TagSkills is
        recognized as the best Virtual Training center.
      </Typography>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  text-align: center;
  padding: 60px 150px;
  @media (max-width: 560px) {
    padding: 15px;
  }
`;
