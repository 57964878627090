import React, { useEffect, useState } from "react"
import styles from "./Notification.module.css"
import Popup from "./Popup.js"
import { Link } from "react-router-dom";
import {GoDashboard} from "react-icons/go"
import {RiVidiconLine} from "react-icons/ri"
import {AiOutlineFilePdf} from "react-icons/ai"
import {AiOutlineUpload} from "react-icons/ai"
import {GrCertificate} from "react-icons/gr"
import {FaBloggerB} from "react-icons/fa"
import {GrLogout} from "react-icons/gr"
import Profilenavbar from "src/components/Profilenavbar/Profilenavbar"
import { useNavigate } from "react-router-dom"
import { NotificationDisplayApi } from "src/api/ApiConnect"
import { Post } from "src/api/apiConfig";
import { useDispatch, useSelector } from "react-redux"


const Notification = () => {
  const[buttonPopup, setButtonPopup]=useState(false)
  const [notitest, setTest] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const notificationDisp = useSelector((state) => state.notificationload)

  useEffect(() => {
    getnotification()
  })
  const getnotification = async () => {
    if (user.course_type === "0") {
      navigate("/")
    } else if (user.selected_course === "0") {
      navigate("/")
    } else {

      
const RequestBody = {
  USER_EMAIL: user.email,
  USER_BATCH: user.batch,
  USER_MEMBERSHIP_TYPE: user.membership_type,
  USER_MAIN_COURSE:  user.course_type,
  USER_SELECTED_COURSE: user.selected_course, 
}
const data = await Post(NotificationDisplayApi,RequestBody)
      setTest(data.notification)
      //dispatch(getusernotification(data.notification))
    }
  }
  
  return (
    
 <div>
         <Profilenavbar />
    <div className={styles.HomeLandingBox}>
        <div className={styles.HomeLandingMainBox}>
            <button className={styles.appstoree}><GoDashboard size={20} /><Link to="/Dashboard"><p className={styles.appstoreTextt}>Dashboard</p></Link></button>
            <button className={styles.appstore}><RiVidiconLine size={20} /><Link to="/Coursevideo"><p className={styles.appstoreText}>Course Videos</p></Link></button>
            <button className={styles.appstore}><AiOutlineFilePdf size={20} /><Link to="/"><p className={styles.appstoreText}>Pdf Materials</p></Link></button>
            <button className={styles.appstore}><AiOutlineUpload size={20} /><Link to="/"><p className={styles.appstoreText}>Upload Resume</p></Link></button>
            <button className={styles.appstore}><GrCertificate size={20} /><Link to="/"><p className={styles.appstoreText}>My Certificate</p></Link></button>
           
            <button className={styles.appstore}><FaBloggerB size={20}/><Link to="/Blogs"><p className={styles.appstoreText}>Blogs</p></Link></button>
            <button className={styles.appstore}><Link to="/"><p className={styles.appstoreText}></p></Link></button>
            <button className={styles.appstore}><Link to="/"><p className={styles.appstoreText}></p></Link></button>
            <button className={styles.appstore}><GrLogout size={20} /><Link to="/"><p className={styles.appstoreText}>Logout</p></Link></button>
          
        </div>
    
            <div className={styles.HomeLandingimgBox}>

                <div className={styles.Homesectionone}> 
                <div className={styles.submitStyle}> Notification</div>
                </div>

                
                <div className={styles.Homesectiontwo}> 
                <div className={styles.gridContainer}>
                
        {notitest.map((item) => (
          <div   key={item.ID} className={styles.card}  >
            <div  className={styles.name}>
              <h6 onClick={()=>setButtonPopup(true)}>
                {item.NOTIFICATION_DATETIME} {item.NOTIFICATION_HEADING}
                </h6  >
                <Popup trigger={buttonPopup} setTrigger= {setButtonPopup}>
               <p> {item.NOTIFICATION_DATETIME}</p>  
              <b> <p>{item.NOTIFICATION_HEADING}</p></b> 
          <p> {item.NOTIFICATION_DESCRIPTION}</p>
           </Popup>
            </div>
          </div>
           
        ))}
      </div>
      </div>
                








                </div>
                </div>
                </div>

  )
}

export default Notification