import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./styles.css";
import styled from "styled-components";
import GroupsIcon from "@mui/icons-material/Groups";
import DescriptionIcon from "@mui/icons-material/Description";
export default function WhyUs() {
  return (
    <div id="whyus">
      <Wrapper id="success">
      <Typography variant="h4" style={{ textAlign: "center" }}>
        <b>Why Choose TagSkills for Your Professional Training?</b>
      </Typography>
      <br />
      <Typography variant="body2">
      At TagSkills, you're not just learning the theory; you're immersing yourself in practical,
       hands-on experience that translates directly to real-world applications. 
       Our courses are taught by industry veterans with years of experience in their respective fields, 
       ensuring you receive top-tier education grounded in the latest industry standards and practices.
       </Typography>
       </Wrapper>
       <br />
      <br />
      <br />
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "flex", md: "flex", lg: "flex" },
          flexDirection: { xs: "column", md: "row", lg: "row" },
          gap: { xs: "60px", md: "20px", lg: "20px" },
        }}
      >
        <div className="animation">
          <div className="anm_mod full fast">
            <div>
              {" "}
              <Wrapper>
                <IconWrapper>
                  <Avatar
                    className="icon"
                    style={{
                      background: "#5B72EE",
                      boxShadow:
                        "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                    }}
                  >
                    <DescriptionIcon style={{ fontSize: "40px" }} />
                  </Avatar>
                </IconWrapper>
                <Card className="cardDiv">
                  <CardContent style={{ paddingTop: "35px" }}>
                    <Typography variant="body1" style={{ color: "#5B72EE" }}>
                      <b>ERP APPLICATION + BUSINESS PROCESS KNOWLEDGE</b>
                    </Typography>
                    <Typography variant="body2" className="font12">
                      Cost effective guidance on ERP Application knowledge
                      sharing with detailed business process demonstration by
                      highly skilled and recommended ERP consultants
                    </Typography>
                  </CardContent>
                </Card>
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="animation">
          <div className="anm_mod full fast">
            <div>
              <Wrapper>
                <IconWrapper>
                  <Avatar
                    className="icon"
                    style={{
                      background: "#00CBB8",
                      boxShadow:
                        "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                    }}
                  >
                    <CalendarMonthIcon style={{ fontSize: "40px" }} />
                  </Avatar>
                </IconWrapper>
                <Card className="cardDiv">
                  <CardContent style={{ paddingTop: "35px" }}>
                    <Typography variant="body1" style={{ color: "#00CBB8" }}>
                      <b>REAL TIME PROJECT</b>
                    </Typography>
                    <Typography variant="body2" className="font12">
                      We provide real-time project work to gain practical
                      hands-on experience using ERP
                    </Typography>
                  </CardContent>
                </Card>
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="animation">
          <div className="anm_mod full fast">
            <div>
              <Wrapper>
                <IconWrapper>
                  <Avatar
                    className="icon"
                    style={{
                      background: "#EE995B",
                      boxShadow:
                        "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                    }}
                  >
                    <DescriptionIcon style={{ fontSize: "40px" }} />
                  </Avatar>
                </IconWrapper>
                <Card className="cardDiv">
                  <CardContent style={{ paddingTop: "35px" }}>
                    <Typography variant="body1" style={{ color: "#EE995B" }}>
                      <b>CONSULTATIVE APPROACH</b>
                    </Typography>
                    <Typography variant="body2" className="font12">
                      Our Consultants are highly experienced and excellent in
                      knowledge who work in close coordination with aspirants
                      throughout the process
                    </Typography>
                  </CardContent>
                </Card>
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="animation">
          <div className="anm_mod full fast">
            <div>
              <Wrapper>
                <IconWrapper>
                  <Avatar
                    className="icon"
                    style={{
                      background: "#29B9E7",
                      boxShadow:
                        "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                    }}
                  >
                    <GroupsIcon style={{ fontSize: "40px" }} />
                  </Avatar>
                </IconWrapper>
                <Card className="cardDiv">
                  <CardContent style={{ paddingTop: "35px" }}>
                    <Typography variant="body1" style={{ color: "#29B9E7" }}>
                      <b>PLACEMENT SUPPORT</b>
                    </Typography>
                    <Typography variant="body2" className="font12">
                      We provide post training placement support, career
                      advisory services and knowledge upgradation through
                      regular industry interaction.
                    </Typography>
                  </CardContent>
                </Card>
              </Wrapper>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
const Wrapper = styled.div`
  position: relative;
`;
const IconWrapper = styled.div`
  position: absolute;
  left: 38%;
  top: -50px;
`;
