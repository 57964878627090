import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import circle from "../../../assets/images/coilCricle.svg";
import CEO from "../../../assets/images/CEO.png";
import msme from "../../../assets/images/msme.png";
import { Instagram, LinkedIn } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function About() {
  return (
    <Wrapper id="about">
      <ContentWrapper>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "block" },
            justifyContent: "end",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={8}>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h4">
                  <b>About Us</b>
                </Typography>
                <Typography
                  style={{ textAlign: "left" }}
                  variant="body2"
                  className="font12"
                >
                  <b>About TagSkills</b>
                </Typography>
                <Typography
                  style={{ textAlign: "left", marginBottom: 10 }}
                  variant="body2"
                  className="font12"
                >
                  At TagSkills, we are more than just an educational
                  institution; we are your gateway to a future defined by
                  success in the digital economy. Founded with the vision to
                  empower professionals across the globe, TagSkills has quickly
                  established itself as a beacon of excellence in online ERP
                  training. Our mission is to unlock the full potential of our
                  students, equipping them with the cutting-edge skills required
                  to excel in today’s fast-paced business environment. TagSkills
                  has been committed to providing quality education and
                  knowledge to many aspirants, focusing on effective
                  employability training and rewarding career. We recognize that
                  different candidates have different training requirements, so
                  we provide a variety of courses to a different set of
                  candidates. Our training ensures IT professionals, business
                  users, and decision-makers have the knowledge they need to
                  drive an enterprise effectively. Established in 2020,
                  TagSkills is recognized as the best Virtual Training center.
                </Typography>
                <Typography
                  style={{ textAlign: "left" }}
                  variant="body2"
                  className="font12"
                >
                  <b>Commitment to Excellence</b>
                </Typography>
                <Typography
                  style={{ textAlign: "left", marginBottom: 10 }}
                  variant="body2"
                  className="font12"
                >
                  TagSkills is committed to fostering a learning environment
                  that encourages growth, innovation, and excellence. We
                  continuously update our course offerings to reflect the latest
                  industry trends and ensure that our students are prepared to
                  meet the challenges of tomorrow. By choosing TagSkills, you’re
                  not just enrolling in a course; you’re investing in a future
                  where you are an in-demand professional, equipped with the
                  knowledge and skills to lead in the digital age.
                </Typography>
                <Typography
                  style={{ textAlign: "left" }}
                  variant="body2"
                  className="font12"
                >
                  <b>Join Our Community</b>
                </Typography>
                <Typography
                  style={{ textAlign: "left" }}
                  variant="body2"
                  className="font12"
                >
                  Become part of a thriving community of professionals who have
                  transformed their careers with TagSkills. Whether you’re
                  looking to advance in your current role or pivot to a new
                  field entirely, our ERP courses provide the foundation you
                  need to achieve your goals. Let TagSkills be the catalyst for
                  your professional transformation. Start your journey with us
                  today.
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" className="font12 purpleColor">
                    Accreditations
                  </Typography>
                  <LazyLoadImage
                    src={msme}
                    alt="img"
                    style={{ width: "120px", height: "80px" }}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                style={{
                  position: "relative",
                  width: "500px",
                  height: "500px",
                  zIndex: 9,
                  // background: "red",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "200px",
                    height: "300px",
                    background: "#5249BD",
                    left: "120px",
                    top: "80px",
                    borderRadius: "24px",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    width: "120px",
                    height: "140px",
                    background: "#92FE9D",
                    left: "250px",
                    top: "280px",
                    borderRadius: "24px",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    width: "200px",
                    height: "300px",
                    background: "transparent",
                    left: "150px",
                    top: "100px",
                    //   borderRadius: "38px",
                  }}
                >
                  <LazyLoadImage
                    src={CEO}
                    alt="img"
                    style={{
                      width: "200px",
                      height: "300px",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "250px",
                  }}
                >
                  <Typography variant="body2">
                    <b>Prashun Shetty</b>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    Founder & CEO
                  </Typography>
                  <Typography variant="body2">
                    <Instagram
                      style={{ cursor: "pointer", fill: "#fa6488" }}
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/tagskills2020?igsh=YzZmNXpjNXZneHlu",
                          "_blank"
                        )
                      }
                    />
                    <LinkedIn
                      style={{ cursor: "pointer", fill: "#68a3da" }}
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/prashun-shetty-41903a39?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
                          "_blank"
                        )
                      }
                    />
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "block", md: "none", lg: "none" },
            justifyContent: "end",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h4">
                  <b>About Us</b>
                </Typography>
                <Typography variant="body2" className="font12">
                  TagSkills has been committed to providing quality education
                  and knowledge to many aspirants, focusing on effective
                  employability training and rewarding career. We recognize that
                  different candidates have different training requirements, so
                  we provide a variety of courses to a different set of
                  candidates. Our training ensures IT professionals, business
                  users, and decision-makers have the knowledge they need to
                  drive an enterprise effectively. Established in 2020,
                  TagSkills is recognized as the best Virtual Training center.
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" className="font12 purpleColor">
                    Accreditations
                  </Typography>
                  <img
                    src={msme}
                    alt="img"
                    style={{ width: "120px", height: "80px" }}
                  />
                </div>
              </div>

              <CEOWapper>
                <PurpleDIv></PurpleDIv>
                <GreenDiv></GreenDiv>
                <ImgDiv>
                  <img
                    src={CEO}
                    alt="img"
                    style={{
                      width: "140px",
                      height: "200px",
                    }}
                  />
                </ImgDiv>
                <LabelDiv>
                  <Typography variant="body2">
                    <b>Prashun Shetty</b>
                  </Typography>
                  <Typography variant="body2" className="font12">
                    Founder & CEO
                  </Typography>
                  <Typography variant="body2">
                    <Instagram
                      style={{ cursor: "pointer", fill: "#fa6488" }}
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/tagskills2020?igsh=YzZmNXpjNXZneHlu",
                          "_blank"
                        )
                      }
                    />
                    <LinkedIn
                      style={{ cursor: "pointer", fill: "#68a3da" }}
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/prashun-shetty-41903a39?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
                          "_blank"
                        )
                      }
                    />
                  </Typography>
                </LabelDiv>
              </CEOWapper>
            </Grid>
          </Grid>
        </Box>
      </ContentWrapper>
      <LeftWrapper>
        <LazyLoadImage
          src={circle}
          alt="img"
          style={{ width: "100%", maxWidth: "200px" }}
        />
      </LeftWrapper>
      <RightWrapper>
        <LazyLoadImage
          src={circle}
          alt="img"
          style={{ width: "100%", maxWidth: "200px" }}
        />
      </RightWrapper>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
  height: 480px;
  @media (max-width: 560px) {
    // display: none;
    height: 580px;
  }
`;
const ContentWrapper = styled.div`
  position: absolute;
`;
const CEOWapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 410px;
  height: 300px;
  z-index: 9;
`;
const PurpleDIv = styled.div`
  position: absolute;
  width: 120px;
  height: 180px;
  background: #5249bd;
  left: 42%;
  top: 2%;
  border-radius: 24px;
  @media (max-width: 895px) {
    // display: none;
    // height: 580px;
    left: 77%;
  }
  @media (max-width: 735px) {
    left: 70%;
  }
  @media (max-width: 635px) {
    left: 65%;
  }
  @media (max-width: 600px) {
    left: 52%;
  }
  @media (max-width: 500px) {
    left: 42%;
  }
  @media (max-width: 450px) {
    left: 35%;
  }
`;
const GreenDiv = styled.div`
  position: absolute;
  width: 120px;
  height: 140px;
  background: #92fe9d;
  left: 62%;
  top: 35%;
  border-radius: 24px;
  @media (max-width: 895px) {
    // display: none;
    // height: 580px;
    left: 95%;
  }
  @media (max-width: 735px) {
    left: 85%;
  }
  @media (max-width: 635px) {
    left: 80%;
  }
  @media (max-width: 600px) {
    left: 70%;
  }
  @media (max-width: 500px) {
    left: 60%;
  }
  @media (max-width: 450px) {
    left: 52%;
  }
`;
const ImgDiv = styled.div`
  position: absolute;
  width: 200px;
  height: 300px;
  background: transparent;
  left: 50%;
  top: 8%;
  @media (max-width: 895px) {
    // display: none;
    // height: 580px;
    left: 85%;
  }
  @media (max-width: 735px) {
    left: 75%;
  }
  @media (max-width: 635px) {
    left: 70%;
  }
  @media (max-width: 600px) {
    left: 60%;
  }
  @media (max-width: 500px) {
    left: 50%;
  }
  @media (max-width: 450px) {
    left: 40%;
  }
`;
const LabelDiv = styled.div`
  position: absolute;
  bottom: 0;
  top: 85%;
  left: 75%;
  text-align: center;
  @media (max-width: 600px) {
    left: 60%;
  }
  @media (max-width: 500px) {
    left: 50%;
  }
  @media (max-width: 450px) {
    left: 40%;
  }
`;
const LeftWrapper = styled.div`
  position: absolute;
  left: -60px;
  top: 30%;
`;
const RightWrapper = styled.div`
  position: absolute;
  right: -60px;
  top: 0;
`;
