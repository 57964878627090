import React from "react"
import { Link } from "react-router-dom"
import NavMenu from "src/components/NavMenu/NavMenu"
import styles from "./Taglogin.module.css"

const Field = React.forwardRef(({ label, type, ...props }, ref) => {
  return (
    <div>
      <label className={styles.labelStyle}>{label}</label>
      <input {...props} ref={ref} type={type} className={styles.inputStyle} />
    </div>
  )
})

const Login = () => {
  
  const emailRef = React.useRef()
  const passwordRef = React.useRef()


  const handleSubmit = (e) => {
    e.preventDefault()
    // const data = {
    //   username: usernameRef.current.value,
    //   password: passwordRef.current.value,
    // }
   // const json = JSON.stringify(data, null, 4)
   // alert("hii")
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault()
    }
  }

  return (
    <div className={styles.appStyle}>
      <NavMenu />
      
      <div className={styles.innerDiv}>
        <form className={styles.formStyle} onSubmit={handleSubmit}>
       <b>   <h1 className={styles.head}>Login Into TagSkills</h1></b>

    <div style={{width:"50%"}}>
        <hr className={styles.lineBreak} /></div>
  <div style={{width:"50%"}}>
   <hr className={styles.lineeBreak} /></div>

          <div>
          
            <Field  onKeyDown={handleEnter} ref={emailRef} label="Email" type="password" />
            <Field  onKeyDown={handleEnter} ref={passwordRef} label="Password" type="text" />
           
           <div style={{display:"flex",flexDirection:"row",margin:"1px",}}>


               <div style={{marginLeft:"10px",margin:"5px"}}>
              <Link to="/">Forgot Password ?</Link> 
              </div>
               
</div>
            <button className={styles.submitStyle} type="Signup">
            <Link to="/login"><p className={styles.linkItem}>Login</p></Link>
              
            </button>


            <div style={{marginLeft:"10px",margin:"5px"}}>
              <Link to="/">Don't have account  ? Signup</Link> 
              </div>

          </div>
          
        </form>
        

        
      </div>
    </div>
  )
}

export default Login