import React, { useEffect, useState } from "react";
import styles from "./Pdfmaterials.module.css";

import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { PdfDisplayApi } from "src/api/ApiConnect";
import { Post } from "src/api/apiConfig";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { TimeAgo } from "src/hooks/TimeAgo";
import {
  AppBar,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import ProfileSidebar from "../DashboardRevamp/ProfileSidebar";

const Ppdfmaterials = () => {
  const [test, setTest] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.request[0]);
  
  const [Searchvalue, setSearchvalue] = React.useState("");
  const [disppdf, setDisppdf] = useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect(() => {
    loabsearchbox();
    getallpdf();
  }, []);

  const loabsearchbox = async () => {
    const RequestBody = {
      USER_EMAIL: user.email,
      USER_BATCH: user.batch,
      USER_MEMBERSHIP_TYPE: user.membership_type,
      USER_COURSE: user.selected_course,
      USER_SEARCH: "",
    };

    const data = await Post(PdfDisplayApi, RequestBody);
    setDisppdf(data?.PDF);
  };

  const getallpdf = async () => {
    const RequestBody = {
      USER_EMAIL: user.email,
      USER_BATCH: user.batch,
      USER_MEMBERSHIP_TYPE: user.membership_type,
      USER_COURSE: user.selected_course,
      USER_SEARCH: Searchvalue,
    };
    const data = await Post(PdfDisplayApi, RequestBody);

    setTest(data.PDF);
  };

  const searchclick = async () => {
    getallpdf();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      getallpdf();
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  const handleClickpdf = async (urlpath) => {
    window.location.href = urlpath;
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <div className={styles.container}>
      <AppBar
        color="transparent"
        position="static"
        elevation={1}
        style={{ border: "1px solid #544bbd" }}
        //   className="purpleBg"
      >
        <Toolbar className={styles.toolbar}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>PDF Materials</b>
          </Typography>
          <div className="flexSpaceCenter pb10">
            <div className={styles.searchBox}>
              <Autocomplete
                disablePortal
                size="small"
                id="searchcombobox"
                value={Searchvalue}
                onInputChange={(event, newValue) => {
                  setSearchvalue(newValue);
                }}
                className={styles.searchField}
                options={disppdf.map((item) => item.FILE_HEAD)}
                renderInput={(params) => (
                  <TextField {...params} label="Search For Material" />
                )}
              />
              <button
                className={styles.searchBtn}
                type="button"
                onClick={() => searchclick()}
              >
                <BiSearch color="white" />
              </button>
            </div>
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("right", true)}
            >
              <Menu />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <div className={styles.gridContainer}>
        {test.map((item) => (
          <div
            onClick={() => window.open(item.FILE_URL)}
            key={item.ID}
            className={styles.card}
          >
            <div className={styles.categoryContainer}>
              <div className={styles.buttontop}>
                <img
                  src="images/pdffile.png"
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "0%",
                    borderRadius: "2px 2px 0 0",
                  }}
                  alt="Computer"
                ></img>
              </div>
              <div className={styles.txteleps}>
                <b>{item.FILE_HEAD}</b>
              </div>
              <p className={styles.txteleps} style={{ fontSize: "12px" }}>
                {TimeAgo(new Date(item.FILE_DATE))} &nbsp;
                {item.FILE_DATE}
              </p>
            </div>
          </div>
        ))}
      </div>
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <ProfileSidebar toggleDrawer={() => toggleDrawer("right", false)} />
      </SwipeableDrawer>
    </div>
  );
};

export default Ppdfmaterials;
