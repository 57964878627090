import React, { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import sponsorshipBatch1 from "../../assets/images/sponsorship/sponsorshipBatch1.jpg";
import sponsorshipBatch2 from "../../assets/images/sponsorship/sponsorshipBatch2.jpg";
import sponsorshipBatch3 from "../../assets/images/sponsorship/sponsorshipBatch3.jpg";
import sponsorshipBatch4 from "../../assets/images/sponsorship/sponsorshipBatch4.jpg";

const itemData = [
  { img: sponsorshipBatch1, title: "sponsorshipBatchPhotos" },
  { img: sponsorshipBatch2, title: "sponsorshipBatchPhotos" },
  { img: sponsorshipBatch3, title: "sponsorshipBatchPhotos" },
  { img: sponsorshipBatch4, title: "sponsorshipBatchPhotos" },
];

const SponsorshipImageList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (img) => {
    setSelectedImage(img);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <ImageList
        sx={{
          width: "100%",
          height: "100%",
          mt: "10px",
          overflow: "hidden", // Prevent scrollbar
          gap: "30px",
        }}
        cols={isMobile ? 1 : 3}
        rowHeight={400}
      >
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            sx={{
              overflow: "hidden",
              padding: "20px",
              cursor: "pointer",
              "&:hover img": {
                transform: "scale(1.1)",
              },
            }}
            onClick={() => handleClickOpen(item.img)}
          >
            <img
              src={item.img}
              alt={item.title}
              style={{
                width: "100%",
                height: "100%",
                display: "block",
                objectFit: "cover",
                transition: "transform 0.3s ease-in-out",
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backdropFilter: "blur(8px)", // Blur background
          },
        }}
      >
        <DialogTitle>
          {selectedImage ? "Tagskills Sponsorship" : ""}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            overflow: "hidden", // Remove scrollbar
          }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SponsorshipImageList;
