import React, { useEffect, useState } from "react";
import styles from "./Coursevideo.module.css";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { VideoDisplayApi } from "src/api/ApiConnect";
import { Post } from "src/api/apiConfig";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import { formatDistanceToNow } from 'date-fns';
import {
  AppBar,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import ProfileSidebar from "../DashboardRevamp/ProfileSidebar";
import { addPlaydata } from "src/redux/action/action"
import playbutton from "../../assets/images/playbutton.png";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DialogActions from '@mui/material/DialogActions';

const Coursevideo = () => {

  function EllipsisTooltip({ text, width = "40vh" }) {
    return (
      <Tooltip title={text} placement="top">
        <Box
          sx={{
            width: width,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </Box>
      </Tooltip>
    );
  }

  function EllipsisTooltipmodel({ text, width = "auto" }) {
    return (
      <Tooltip title={text} placement="top">
        <Box
          sx={{
            width: width,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </Box>
      </Tooltip>
    );
  }
 

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.request[0]);
  
  const [dispVideos,setDispVideos] = useState([]);
  const [Searchvalue, setSearchvalue] = React.useState("");
  const [searchvideos, setSearchvideos] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  
  useEffect(() => {
    //searchvideosload();
    getallvideos();
  }, []);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const searchvideosload = async () => {
    const RequestBody = {
      USER_EMAIL:user?.email,
      USER_BATCH: user.batch,
      USER_MEMBERSHIP_TYPE: user.membership_type,
      USER_COURSE: user.selected_course,
      USER_SEARCH: Searchvalue,
    };

    const data = await Post(VideoDisplayApi, RequestBody);
    if(data?.IsSuccess)
    {
      setSearchvideos(data?.VIDEO)
     
    }
  };

  const getallvideos = async () => {

    const RequestBody = {
      BATCH: user.batch,
      TYPE: 'Video',
    };

    const data = await Post(VideoDisplayApi, RequestBody);
    if (data?.IsSuccess) {
      setDispVideos(data?.VIDEO);
    }
  };

  const searchclick = async () => {
    await getallvideos();
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      getallvideos();
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  const handleClickVideo = async (index) => {
    dispatch(addPlaydata({index}));
    navigate("/Videoplay");
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <div className={styles.HomeLandingimgBox}>
      <AppBar
        color="transparent"
        position="static"
        elevation={1}
        style={{ border: "1px solid #544bbd" }}
        //   className="purpleBg"
      >
        <Toolbar className={styles.toolbar}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>Course Videos</b>
          </Typography>
          <div className="flexSpaceCenter pb10">
            <div className={styles.searchBox}>
              <Autocomplete
                disablePortal
                size="small"
                id="searchcombobox"
                value={Searchvalue}
                onInputChange={(event, newValue) => {
                  setSearchvalue(newValue);
                }}
                className={styles.searchField}
                options={searchvideos?.map((item) => item.FILE_HEAD)}
                // sx={{ width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} label="Search For Session" />
                )}
              />
              <button
                className={styles.searchBtn}
                type="button"
                onClick={() => searchclick()}
              >
                <BiSearch color="white" />
              </button>
            </div>
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer("right", true)}
            >
              <Menu />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              bgcolor: "background.paper",
              borderRadius: 1,
              minWidth:"100vh",
            }}
          >
      {dispVideos?.map((el) => (
          <Card
          sx={{ width:"40vh", m: 1,marginLeft:3, cursor: "pointer" }}
        >
          <CardMedia
            component="img"
            height="200"
            sx={{objectFit: 'cover'}}
            image={playbutton}
            title={el.FILE_HEAD}
            onClick={() => {
              handleClickOpen();
              setSelectedData(el);
            }}
          />
          <CardContent>
            <Typography gutterBottom subtitle1= 'h2'  component="div">
              <EllipsisTooltip
                text={el.FILE_HEAD}
              ></EllipsisTooltip>
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Last updated: {formatDistanceToNow(new Date(el.FILE_DATE))} ago
            </Typography>
          </CardContent>
        </Card>
        ))}
      </Box>

      <Dialog
        maxWidth="md"
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "center", // Centers the dialog vertically in the viewport
          },
        }}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>
          <EllipsisTooltipmodel
            text={selectedData.FILE_HEAD}
            width="auto"
          ></EllipsisTooltipmodel>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <video
            width="100%"
            className={styles.videoPlayer}
            onContextMenu={(e) => e.preventDefault()}
            controls
            autoPlay
            id="myVideo"
            controlsList="nodownload"
          >
            <source src={selectedData?.FILE_URL} type="video/mp4" />
          </video>
        </DialogContent>
      </Dialog>

      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <ProfileSidebar toggleDrawer={() => toggleDrawer("right", false)} />
      </SwipeableDrawer>
    </div>
  );
};

export default Coursevideo;
