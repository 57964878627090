import React, { useEffect, useState } from "react"
import { PaymentApi } from "src/api/ApiConnect"
import { Post } from "src/api/apiConfig";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"

const Payment = () => {

  const user = useSelector(state=>state.user)
  const [paymenthtmlbody,setHtmlbody]=useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    getpayment()
  })

  const getpayment = async () => {

   // alert('Please call us +91 6360959192')
   
const RequestBody = { 
  amount:  user.user_pendingamount,
  firstname: user.full_name,
  email: user.email,
  phone: user.mobile,
  productinfo: user.selected_course,
  lastname: ""
}
const data = await Post(PaymentApi,RequestBody)
      setHtmlbody(data.HTMLBODY)
      //dispatch(gettransactionid(data.transactionid))
      document.getElementById("PostForm").submit()

    }

    
    function createMarkup() {
      return {__html: paymenthtmlbody};
    }
    
    function MyComponent() {
      return <div dangerouslySetInnerHTML={createMarkup()} />;
    }

  return(
    <div>
      <MyComponent/>
    </div>
  )
}
export default Payment
