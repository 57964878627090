import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import LogoIcon from "src/assets/icons/Logo.png";
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  TextField,
  Typography,
  CardHeader,
  Divider,
} from "@mui/material";
import { Check, Payment } from "@mui/icons-material";
import { countryList } from "src/util/countryList";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { PostWitoutToken } from "src/api/apiConfig";
import { BatchDetailsApi } from "src/api/ApiConnect";
import { GState } from "jspdf";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { AdmissionApi } from "src/api/ApiConnect"
import  Pay  from "src/screens/LandingPage/components/Pay"
import { useDispatch, useSelector } from 'react-redux';
import { addPaydata } from "src/redux/action/action"


const ValidationTextField = styled(TextField)({
  '& input:valid + fieldset': {
    borderColor: '#E0E3E7',
    borderWidth: 1,
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 1,
  },
  '& input:valid:focus + fieldset': {
    borderLeftWidth: 4,
    padding: '4px !important', // override inline-style
  },
});

export default function Admission() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const Batchid = queryParameters.get("batchid");
  const [datadisplay, setDatadisplay] = useState(false);
  const [discountedprice, setDiscountedprice] = useState(0);
  const [discountedpriceonly, setDiscountedpriceonly] = useState(0);
  const [gst, setGst] = useState(0);




  //User Inputs
  const [firstname , setFirstname] = useState('');
  const [lastname , setLastname] = useState('');
  const [emaiid , setEmailid] = useState('');
  const [mobilenumber , setMobilenumber] = useState('');
  const [gender, setGender] = React.useState("");
  const [city , setCity] = useState('');
  const [country , setCountry] = useState("India");
  const [linkedinprofile , setLinkedinprofile] = useState('');
  const [createpassword , setCreatepassword] = useState('');
  const [confirmpassword , setConfirmpassword] = useState('');

//EROR MSG
  const [msgfirstname , setFirstnamemsg] = useState('');
  const [msglastname , setLastnamemsg] = useState('');
  const [msgemaiid , setEmailidmsg] = useState('');
  const [msgmobilenumber , setMobilenumbermsg] = useState('');
  const [msggender, setGendermsg] = React.useState("");
  const [msgcity , setCitymsg] = useState('');
  const [msgcountry , setCountrymsg] = useState('');
  const [msglinkedinprofile , setLinkedinprofilemsg] = useState('');
  const [msgcreatepassword , setCreatepasswordmsg] = useState('');
  const [msgconfirmpassword , setConfirmpasswordmsg] = useState('');

  //BOOL MSG
  const [msgfirstnamebool , setFirstnamemsgbool] = useState('');
  const [msglastnamebbool , setLastnamemsgbool] = useState('');
  const [msgemaiidbool , setEmailidmsgbool] = useState('');
  const [msgmobilenumberbool , setMobilenumbermsgbool] = useState('');
  const [msggenderbool, setGendermsgbool] = React.useState("");
  const [msgcitybool , setCitymsgbool] = useState('');
  const [msgcountrybool , setCountrymsgbool] = useState('');
  const [msglinkedinprofilebool , setLinkedinprofilemsgbool] = useState('');
  const [msgcreatepasswordbool , setCreatepasswordmsgbool] = useState('');
  const [msgconfirmpasswordbool , setConfirmpasswordmsgbool] = useState('');


  useEffect(() => {
    loadBatch();
  }, [discountedprice]);

  const loadBatch = async () => {
    const data = await PostWitoutToken(BatchDetailsApi + Batchid, "");
    if (data?.IsSuccess) {
      setDatadisplay(data);
      setDiscountedpriceonly((datadisplay?.COURSE_PRICE / 100) * datadisplay?.OFFER_PRICE)
      setDiscountedprice(
        datadisplay?.COURSE_PRICE -
          (datadisplay?.COURSE_PRICE / 100) * datadisplay?.OFFER_PRICE
      );
      setGst((discountedprice / 100) * 18);
    }else{
      alert(data?.Message)
    }
  };

  const handleChange = (event) => {
    setGender(event.target.value);
    if(event.target.value!='')
    {setGendermsgbool(false);
      setGendermsg('')
    }
  };

  const payHandler = async () => {
    
    if(firstname ==='')
    {
      setFirstnamemsgbool(true);
      setFirstnamemsg('Enter First Name!')
      return;
    }

    if(lastname ==='')
    {
      setLastnamemsgbool(true);
      setLastnamemsg('Enter Last Name!')
      return;
    }

    if(emaiid ==='')
    {
      setEmailidmsgbool(true);
      setEmailidmsg('Enter EmailId!')
      return;
    }

      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!re.test(String(emaiid).toLowerCase()))
      {
        setEmailidmsgbool(true);
        setEmailidmsg('Enter Valid EmailId!')
        return;
      }

      if(mobilenumber ==='')
      {
        setMobilenumbermsgbool(true);
        setMobilenumbermsg('Enter Mobile Number!')
        return;
      }

      if(city ==='')
    {
      setCitymsgbool(true);
      setCitymsg('Enter Your City!')
      return;
    }

    if(createpassword ==='')
    {
      setCreatepasswordmsgbool(true);
      setCreatepasswordmsg('Enter Your Password!')
      return;
    }

    if(confirmpassword ==='')
    {
      setConfirmpasswordmsgbool(true);
      setConfirmpasswordmsg('Confirm Your Password!')
      return;
    }

    if(confirmpassword != createpassword)
    {
      setConfirmpasswordmsgbool(true);
      setConfirmpasswordmsg('Confirm Password & Password Are Not Matching!')
      return;
    }

     //Api call
     const RequestBody = {
      USER_FULLNAME: firstname+ ' '+ lastname,
      USER_MOBILE: mobilenumber,
      USER_EMAIL: emaiid,
      USER_COURSE: datadisplay?.COURSE,
      USER_PASSWORD: confirmpassword,
      USER_LOCATION: city,
      USER_COUNTRY: country,
      USER_BATCH: datadisplay?.BATCH,
      COURSE_PRICE: datadisplay?.COURSE_PRICE,
      COURSE_DISCOUNT: datadisplay?.OFFER_PRICE,
      USER_GENDER: gender,
      USER_LINKEDIN: linkedinprofile
    }
   
    const data = await PostWitoutToken(AdmissionApi, RequestBody);
    if (data?.IsSuccess) {
      dispatch(addPaydata( { 
        amount: `${discountedprice + gst}`,
        firstname: firstname,
        email: emaiid,
        phone: mobilenumber,
        productinfo: datadisplay?.COURSE,
        lastname: lastname
      }));
      navigate('/Pay');
    }
  }

  return (
    <div>
      <Wrapper className="purpleBg">
        <div>
          <Link
            className="pointer flexNullCenter"
            style={{ gap: "0px" }}
            to="home"
            smooth={true}
          >
            <img
              //   className={styles.navLogo}
              style={{
                width: "36px",
                height: "36px",
                alignSelf: "flex-start",
                // marginRight: "16px",
              }}
              alt="logo.png"
              src={LogoIcon}
            />
            <h1
              style={{ marginLeft: "15px" }}
              className="font20 extraBold whiteColor"
            >
              TagSkills
            </h1>
          </Link>
        </div>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} className="grid1">
            <ContentWrapper>
              <Typography variant="h3" className="font30 whiteColor">
                {datadisplay?.COURSE}
              </Typography>
              <Card>
                <CardHeader className="purpleBg whiteColor" />
                <CardContent className="pb10">
                  <div className="flexSpaceCenter pb10">
                    <Typography variant="body2" className="font14">
                      Course Fee
                    </Typography>
                    <Typography variant="body2" className="font14">
                      {datadisplay?.COURSE_PRICE}
                    </Typography>
                  </div>
                  <div className="flexSpaceCenter pb10">
                    <Typography variant="body2" className="font14">
                      Discount({datadisplay?.OFFER_PRICE}%)
                    </Typography>
                    <Typography variant="body2" className="font14">
                      {discountedpriceonly}
                    </Typography>
                  </div>
                  <div className="flexSpaceCenter pb10">
                    <Typography variant="body2" className="font14">
                      GST 18%
                    </Typography>
                    <Typography variant="body2" className="font14">
                      {gst}
                    </Typography>
                  </div>
                  <Divider />
                  <div className="flexSpaceCenter pb10">
                    <Typography variant="body2" className="font14">
                      Amout to be paid(₹)
                    </Typography>
                    <Typography variant="body2" className="font14">
                      {discountedprice + gst}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              <br />
            </ContentWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="grid2"
            style={{ display: "flex", justifyContent: "center" }}
          ></Grid>
        </Grid>
      </Wrapper>
      <Wrapper style={{ paddingBottom: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="flexColumn flexCenter">
            <div>
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">
                  Batch Start Date :{moment(datadisplay?.START_DATE).format("DD-MMM-YYYY")}
                </Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">
                  Batch : {datadisplay?.BATCH}
                </Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Duration 75 hour</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">ERP Application</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">
                  Business process knowledge
                </Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Real time project</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Consultative approach</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Placement support</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="flexCenter">
            <Box
              sx={{
                width: "400px",
                position: "absolute",
                backgroundColor: "white",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                flexGrow: 1,
                top: { md: "100px", lg: "100px" },
                position: { xs: "relative", md: "absolute", lg: "absolute" },
                // justifyContent: "end",
              }}
              style={{}}
            >
              <CardContent className="flexNullCenter flexColumn p30">
                <Autocomplete
                  defaultValue={{ code: "IN", label: "India", phone: "91" }}
                  size="small"
                  fullWidth
                  id="country-select-demo"
                  options={countryList}
                  helperText={msgcountry}
                  error={msgcountrybool}
                  autoHighlight
                  getOptionLabel={(option) => (option.label)}
                onInputChange={(event, newInputValue) => {
                  setCountry(newInputValue);
                }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <div className="flexNullCenter pb10">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="First name"
                    style={{ width: "100%" }}
                    value={firstname}
                    onChange={(e) => 
                      {setFirstname(e.target.value);
                       if(e.target.value!=''){setFirstnamemsgbool(false);setFirstnamemsg('')}}}
                    helperText={msgfirstname}
                    error={msgfirstnamebool}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Last name"
                    style={{ width: "100%" }}
                    value={lastname}
                    onChange={(e) => {setLastname(e.target.value);
                      if(e.target.value!=''){setLastnamemsgbool(false);setLastnamemsg('')}}}
                    helperText={msglastname}
                    error={msglastnamebbool}
                  />
                </div>

                <TextField
                  variant="outlined"
                  size="small"
                  label="Email id"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={emaiid}
                    onChange={(e) => {setEmailid(e.target.value);
                      if(e.target.value!=''){setEmailidmsgbool(false);setEmailidmsg('')}}}
                    helperText={msgemaiid}
                    error={msgemaiidbool}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="Mobile number"
                  type="number"
                  style={{ width: "100%" }}
                  value={mobilenumber}
                    onChange={(e) => {setMobilenumber(e.target.value);
                      if(e.target.value!=''){setMobilenumbermsgbool(false);setMobilenumbermsg('')}}}
                      onKeyDown={e => {["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
                      if (mobilenumber.length >= 10 && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(e.key)) {
                        e.preventDefault();
                      }}}
                    helperText={msgmobilenumber}
                    error={msgmobilenumberbool}
                />

                <Box style={{ width: "100%" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={gender}
                      label="Gender"
                      onChange={handleChange}
                      helperText={msggender}
                      error={msggenderbool}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="City"
                  style={{ width: "100%" }}
                  value={city}
                  onChange={(e) => {setCity(e.target.value);
                    if(e.target.value!=''){setCitymsgbool(false);setCitymsg('')}}}
                  helperText={msgcity}
                  error={msgcitybool}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="LinkedIn profile url"
                  style={{ width: "100%" }}
                  value={linkedinprofile}
                  onChange={(e) => setLinkedinprofile(e.target.value)}
                  helperText={msglinkedinprofile}
                  error={msglinkedinprofilebool}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="Create password"
                  type="password"
                  style={{ width: "100%" }}
                  value={createpassword}
                  onChange={(e) => {setCreatepassword(e.target.value);
                    if(e.target.value!=''){setCreatepasswordmsgbool(false);setCreatepasswordmsg('')}}}
                  helperText={msgcreatepassword}
                  error={msgcreatepasswordbool}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  type="password"
                  label="Confirm password"
                  style={{ width: "100%" }}
                  value={confirmpassword}
                  onChange={(e) => {setConfirmpassword(e.target.value);
                    if(e.target.value!=''){setConfirmpasswordmsgbool(false);setConfirmpasswordmsg('')}}}
                  helperText={msgconfirmpassword}
                  error={msgconfirmpasswordbool}
                />
              </CardContent>
              <CardActions className="flexNullCenter flexColumn p30">
                <div className="flexNullCenter pb10">
                  <Typography variant="body2" className="font11">
                    By clicking Pay Now, you afree to receive communication from
                    Tagskills in accordance with our{" "}
                    <a style={{color :"blue",cursor:"pointer"}} onClick={() => navigate("/Policy")} className="lightPurpleColor">
                      Privacy Policy
                    </a>
                  </Typography>
                </div>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  className="lightPurpleBg"
                  onClick={() => payHandler()}
                >
                  Pay now
                </Button>
              </CardActions>
              <br />
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.section`
  padding: 30px;
  width: 100%;
  margin: 0;
  height: 100%;
  //   min-height: 140px;
  //   max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 560px) {
    max-height: 100vh;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
  height: 100%;
`;
