import { Get, Post } from "./apiConfig"
import { Delete } from "./apiConfig"

export const register = async ({ otp, fname, mobile, email, password }) => {
  //TODO correct the api
  const body = {
    USER_OTP: otp,
    USER_FULLNAME: fname,
    USER_MOBILE: mobile,
    USER_EMAIL: email,
    USER_PASSWORD: password,
    USER_GENDER: "",
  }
  const url = "/api/register"
  const data = await Post({ url, body })
  return data
}

export const login = async ( email, password ) => {
  const body = {
    EMAIL: email,
    PASSWORD: password,
  }
  const url = "/api/login"
  const data = await Post( url, body )
  return data
}

export const newregister = async ({ email,course,searchtext, token }) => {
  const url = "/api/newregister"
  const body = {
    USER_EMAIL: email,
    COURSE:course,
    SEARCH:searchtext
  }
  const data = await Post({ url, body, token })
  return data
}
export const batchList = async ({ token }) => {
  const url = "/api/bathdetails"
  const body = {}
  const data = await Post({ url, body, token })
  return data?.BATCHDEATILS
}

export const loadcomboapi = async ({ token }) => {
  const url = "/api/loadcombo"
  const data = await Post({ url, token })
  return data
}

export const watchlistapi = async ({
  email,
  searchtext,
  course,
  todate,
  fromdate,
  coursestatus,
  revnufilter,
  token,
}) => {
  const url = "/api/mywatchlist"
  const body = {
    OWNER_EMAIL: email,
    SEARCH: searchtext,
    COURSE: course,
    TODATE: todate,
    FROMDATE: fromdate,
    COURSESTATUS: coursestatus,
    REVENUEFILTER: revnufilter,
  }
  const data = await Post({ url, body, token })
  return data
}

export const reportapi = async ({
  email,
  todate,
  fromdate,filter,
  token,
}) => {
  const url = "/api/report"
  const body = {
    OWNER_EMAIL: email,
    TODATE: todate,
    FROMDATE: fromdate,
    FILTER: filter,
  }
  const data = await Post({ url, body, token })
  return data
}

export const addtowatchlist = async ({ email, semail, token }) => {
  const url = "/api/addtowatchlist"
  const body = {
    OWNER_EMAIL: email,
    STUDENT_EMAIL: semail,
  }
  const data = await Post({ url, body, token })
  return data
}

export const viewpaymentdetails = async ({ semail, token }) => {
  const url = "/api/viewpaymentdetails"
  const body = {
    STUDENT_EMAIL: semail,
  }
  const data = await Post({ url, body, token })
  return data
}
export const assignbatch = async ({ semail, sbatch, token }) => {
  const url = "/api/assignbatch"
  const body = {
    STUDENT_EMAIL: semail,
    STUDENT_BATCH: sbatch,
  }
  const data = await Post({ url, body, token })
  return data
}

export const editdata = async ({
  semail,
  batch,
  email,
  membershiptype,
  trainer,
  selectedcourse,
  maincoursecategory,
  cfees,
  discount,
  token,
}) => {
  const url = "/api/editdata"
  const body = {
    OWNER_EMAIL: email,
    STUDENT_EMAIL: semail,
    BATCH: batch,
    MEMBERSHIPTYPE: membershiptype,
    TRAINER: trainer,
    SELECETEDCOURSE: selectedcourse,
    MAINCOURSE_CATEGORY: maincoursecategory,
    COURSEFEES: cfees,
    DISCOUNT: discount
  }

  const data = await Post({ url, body, token })
  return data
}

export const loadCombo = async({token})=>{
  const url = "api/loadcombo"
  const body = {}
  const data = await Post({url, body, token})
  return data
}

export const gettargets = async ({ email, token }) => {
  const url = "/api/gettargets"
  const body = {
    EMAIL: email, 
  }
  const data = await Post({ url, body, token })
  return data
}

export const createtarget = async ({ email,fdate,tdate,targetamount, token }) => {
  const url = "/api/createtarget"
  const body = {
    EMAIL: email,
    FROMDATE:fdate,
    TODATE:tdate,
    TARGETAMOUNT:targetamount,
    
  }
  const data = await Post({ url, body, token })
  return data
}


export const addremark = async ({ email,mobile,remark,coursestatus,tdate,token }) => {
  const url = "/api/addremark"
  const body = {
    OWNER_EMAIL: email,
    STUDENT_MOBILE: mobile,
    REMARK:remark,
    STATUS:coursestatus,
    NEXTREMAINDERDATE:tdate, 
  }
  const data = await Post({ url, body, token })
  return data
}
export const viewremark = async ({ mobile,token }) => {
  const url = "/api/viewremark"
  const body = {
   
    STUDENT_MOBILE: mobile,
   
  }
  const data = await Post({ url, body, token })
  return data
}



export const newdemorequest = async ({ email,course,searchtext, token }) => {
  const url = "/api/newdemorequest"
  const body = {
    USER_EMAIL: email,
    COURSE:course,
    SEARCH:searchtext
  }
  const data = await Post({ url, body, token })
  return data
}

export const addtomytrack = async ({ email, mobile, token }) => {
  const url = "/api/addtomytrack"
  const body = {
    OWNER_EMAIL: email,
    STUDENT_MOBILE: mobile,
  }
  const data = await Post({ url, body, token })
  return data

}


export const mydemowatchlistapi = async ({
  email,
  searchtext,
  coursestatus,

  token,
}) => {
  const url = "/api/mydemowatchlist"
  const body = {
    OWNER_EMAIL: email,
    SEARCH: searchtext,
    STATUS: coursestatus,
  
  }
  const data = await Post({ url, body, token })
  return data
}

export const remainderdemowatchlistapi = async ({
  email,
  searchtext,
  coursestatus,

  token,
}) => {
  const url = "/api/wathlistremainder"
  const body = {
    OWNER_EMAIL: email,
    SEARCH: searchtext,
    STATUS: coursestatus,
  
  }
  const data = await Post({ url, body, token })
  return data
}

export const addpayment = async ({ semail,payid,amount,paydate, token }) => {
  const url = "/api/addpayment"
  const body = {
    STUDENT_EMAIL: semail,
    PAYMENT_ID:payid,
    AMOUNT:amount,
    PAYMENT_DATE:paydate,
  }
  const data = await Post({ url, body, token })
  return data
}


export const createbatch = async ({ batch,batchlead_email,status,completed_date,startdate,course,courseprice,discount,token }) => {
  const url = "/api/createbatch"
  const body = {
  BATCH: batch,
  BATCH_LEAD_EMAIL: batchlead_email,
  STATUS: status,
  COMPLETED_DATE: completed_date,
  START_DATE: startdate,
  COURSE: course,
  COURSE_PRICE: courseprice,
  COURSE_DISCOUNT: discount
  }
  console.log('99999',body)
  const data = await Post({ url, body, token })
  return data
}

export const editbatch = async ({ id,batch,batchlead_email,status,completed_date,startdate,course,courseprice,discount,token }) => {
  const url = "/api/editbatch"
  const body = {
  BID:id,
  BATCH: batch,
  BATCH_LEAD_EMAIL: batchlead_email,
  STATUS: status,
  COMPLETED_DATE: completed_date,
  START_DATE: startdate,
  COURSE: course,
  COURSE_PRICE: courseprice,
  COURSE_DISCOUNT: discount
  }
  const data = await Post({ url, body, token })
  return data
}


export const addstudent = async (request,token) => {
  const url = "/api/addstudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const addDemostudent = async (request,token) => {
  const url = "/api/adddemostudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const editstudent = async (request,token) => {
  const url = "/api/editstudentdata"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const editDemostudent = async (request,token) => {
  const url = "/api/editdemostudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}



export const getbatchstudent = async (request,token) => {
  const url = "/api/getbatchstudent"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getAllbatchstudent = async (token) => {
  const url = "/api/allstudentdata"
  const data = await Get(url, token)
  return data
}

export const deletebatch = async (request,token) => {
  const url = "/api/deletebatch"
  const body = {BID : request}
  const data = await Post({ url, body, token })
  return data
}


export const deletestudentdata = async (request,token) => {
  const url = "/api/deletestudent"
  const body = {BID : request}
  const data = await Post({ url, body, token })
  return data
}

export const deleteDemostudentdata = async (request,token) => {
  const url = "/api/deletedemostudent"
  const body = {BID : request}
  const data = await Post({ url, body, token })
  return data
}

export const getdashboardheader = async (token) => {
  const url = "/api/dashboardheader"
  const body = {}
  const data = await Post({ url, body, token })
  return data
}

export const getdashboardbarchart = async (request,token) => {
  const url = "/api/dashboardbarchart"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getdashboardpiechart = async (request,token) => {
  const url = "/api/dashboardpaichart"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

export const getteamreport = async (request,token) => {
  const url = "/api/teamreport"
  const body = request
  const data = await Post({ url, body, token })
  return data
}

