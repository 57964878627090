import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-scroll";
import LogoIcon from "src/assets/icons/Logo.png";
import { ArrowBack } from "@mui/icons-material";
import courseImg from "../../../assets/images/courseImage.png";
import BookNow from "./BookNow";
import { CiYoutube } from "react-icons/ci";
import { IoDownloadOutline } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";
import { TfiMedall } from "react-icons/tfi";
import img1 from "../../../assets/images/img1.png";
import img2 from "../../../assets/images/img2.png";
import img3 from "../../../assets/images/img3.png";
import img4 from "../../../assets/images/img4.png";
import img5 from "../../../assets/images/img5.png";

export default function CourseDetails() {
  const location = useLocation();
  const [openJoinFor, setOpenJoinFor] = useState(false);
  const handleDialogClose = () => {
    setOpenJoinFor(false);
  };
  return (
    <div>
      <TopDiv className="purpleBg whiteColor flexSpaceCenter">
        <Link
          className="pointer flexNullCenter"
          style={{ gap: "0px" }}
          to="home"
          smooth={true}
        >
          <img
            //   className={styles.navLogo}
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              // marginRight: "16px",
            }}
            alt="logo.png"
            src={LogoIcon}
          />
          <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
            TagSkills
          </h1>
        </Link>
        <Button startIcon={<ArrowBack />} href="/" className="whiteColor">
          Back
        </Button>
      </TopDiv>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "block", md: "none", lg: "none" },
          // justifyContent: "end",
        }}
      >
        <Card
          style={{
            width: "100%",
            // maxWidth: "350px",
            boxShadow:
              " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
          }}
        >
          <img
            src={courseImg}
            alt="img"
            style={{ height: "250px", width: "100%" }}
          />
          <CardContent style={{ padding: "15px 30px" }}>
            <Typography variant="body2" className="lightPurpleColor font25">
              TagSkills
            </Typography>
            <Typography variant="body2">
              Subscribe to this course and 100+ top-rated TagSkills ERP courses
              for your organization.
            </Typography>
            <br />
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                className="lightPurpleBg whiteColor"
                onClick={() => setOpenJoinFor(true)}
              >
                Book your free demo
              </Button>
            </div>
            <br />
            <div>
              <Typography variant="body2" className="pb15">
                &#10003; &nbsp;&nbsp; Duration 75 hour
              </Typography>

              <Typography variant="body2" className="pb15">
                &#10003; &nbsp;&nbsp; ERP Application
              </Typography>
              <Typography variant="body2" className="pb15">
                &#10003; &nbsp;&nbsp; Business process knowledge
              </Typography>
              <Typography variant="body2" className="pb15">
                &#10003; &nbsp;&nbsp; Real time project
              </Typography>

              <Typography variant="body2" className="pb15">
                &#10003; &nbsp;&nbsp; Consultative approach
              </Typography>
              <Typography variant="body2" className="pb15">
                &#10003; &nbsp;&nbsp; Placement support
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Box>

      <div style={{ position: "relative" }} className="pb15">
        <div style={{ position: "absolute" }}>
          <Wrapper className="purpleBg">
            <InnerWrapper>
              {location.state.course === "S/4 HANA MM" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>S/4 HANA MM (Materials Management)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    ERP S/4HANA MM streamlines material management, optimizing
                    procurement and inventory processes with real-time
                    insights.Dive deep into the world of supply chain and
                    inventory management with our expert-led MM course.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4_HANA_MM.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "S/4 HANA TM" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>S/4 HANA TM (Transportation Management)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Comprehensive course covering logistics and supply chain
                    optimization, focusing on planning, execution, and
                    monitoring of transportation processes in the ERP S/4HANA
                    environment.Become proficient in managing transportation and
                    logistics for enhanced supply chain efficiency.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4%20HANA_TM.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "ARIBA" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>ARIBA</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Course focusing on procurement and supply chain management,
                    leveraging the Ariba platform to optimize sourcing, contract
                    management, and supplier collaboration within the ERP
                    S/4HANA environment.Leverage the power of Ariba for smarter
                    procurement and supply chain collaboration.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_ARIBA_Course.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "SALESFORCE" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>SALESFORCE</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Transform customer relationships with our Salesforce course,
                    covering CRM strategies, cloud solutions, and more.
                    Comprehensive training on the Salesforce platform, covering
                    customer relationship management (CRM), application
                    development, and cloud-based solutions for effective
                    business management and automation.
                  </Typography>
                </div>
              ) : location.state.course === "S/4 HANA ABAP" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>
                      S/4 HANA ABAP (Advanced Business Application Programming)
                    </b>
                  </Typography>
                  <br />
                  <Typography
                    variant="body1"
                    className=" whiteColor font12 regular"
                  >
                    {" "}
                    Develop and hone your programming skills to customize SAP
                    applications.
                  </Typography>
                </div>
              ) : location.state.course === "S/4 HANA SD" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>S/4 HANA SD (Sales and Distribution)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Master the intricacies of sales, billing, and distribution
                    processes to optimize business operations. Streamlined
                    module for managing end-to-end sales processes, from order
                    creation to delivery and billing, with advanced features for
                    improved efficiency and customer satisfaction.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4_HANA_SD.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "S/4 HANA FICO" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>S/4 HANA FICO (Financial Accounting and Controlling)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Gain critical insights into financial reporting and
                    management with our comprehensive FICO module. Comprehensive
                    module for financial accounting and controlling, offering
                    real-time analytics, simplified data models, and enhanced
                    functionalities to optimize financial processes in
                    enterprises.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/Tagskills_S%3A4_HANA_FICO.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "S/4 HANA EWM" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>S/4 HANA EWM (Extended Warehouse Management)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Learn to manage and optimize warehousing operations
                    seamlessly with EWM. Advanced logistics module for
                    comprehensive warehouse management, optimizing end-to-end
                    supply chain processes with real-time visibility and
                    advanced functionalities.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4_HANA_EWM.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "SAP PM" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>SAP PM (Plant Maintenance)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Unlock the potential of your plant maintenance operations
                    with SAP Plant Maintenance (PM). This comprehensive solution
                    helps manage asset maintenance, reduce downtime, and boost
                    operational efficiency. Designed for industries with complex
                    asset needs, SAP PM streamlines planning, scheduling, and
                    execution, ensuring smooth operations and enhanced asset
                    lifecycle management.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4_HANA_EWM.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "SAP BTP" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>SAP BTP (Business Technology Platform)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Unlock the potential of your business operations with SAP
                    Business Technology Platform (BTP). This integrated suite of
                    technologies enables seamless data management, advanced
                    analytics, and innovative application development. SAP BTP
                    empowers organizations to unify their data across systems,
                    drive real-time insights, and accelerate digital
                    transformation. Whether you're managing cloud applications
                    or building new solutions, SAP BTP streamlines processes and
                    enhances operational agility across industries.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4_HANA_EWM.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "SAP EAM" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>SAP EAM (Enterprise Asset Management)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Maximize the efficiency and reliability of your assets with
                    SAP Enterprise Asset Management (EAM). This comprehensive
                    solution helps organizations streamline asset maintenance,
                    improve operational performance, and extend asset
                    lifecycles. SAP EAM integrates seamlessly with other SAP
                    modules, enabling real-time insights, data-driven
                    decision-making, and a proactive approach to asset
                    management. Whether you're managing physical assets in
                    manufacturing, utilities, or any asset-intensive industry,
                    SAP EAM ensures optimal asset utilization and minimizes
                    downtime.
                  </Typography>

                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4_HANA_EWM.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "SAP PS" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>SAP PS (Project System)</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    SAP Project System (PS) helps organizations efficiently
                    plan, execute, and monitor projects, ensuring on-time,
                    on-budget delivery. It provides full visibility into project
                    progress, resource allocation, and costs. Integrated with
                    other SAP modules, SAP PS enables seamless coordination
                    across procurement, finance, and logistics, empowering teams
                    to optimize project outcomes and drive success.
                  </Typography>

                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_S%3A4_HANA_EWM.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "DATA ANALYTICS" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>DATA ANALYTICS</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Data Analytics is the practice of examining, cleaning, and
                    modeling data to uncover valuable insights and support
                    decision-making using statistical methods, tools, and
                    techniques. Gain the skills to transform raw data into
                    actionable intelligence for any industry.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/TagSkills_DATA_ANALYTICS.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : location.state.course === "DATA SCIENCE" ? (
                <div className="textLeft" style={{ width: "60%" }}>
                  <Typography variant="body1" className="font25 whiteColor">
                    <b>DATA SCIENCE</b>
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    className="whiteColor font12 regular"
                  >
                    Data Science provides comprehensive training in statistical
                    analysis, machine learning, and data visualization to equip
                    you with the skills needed for impactful data-driven
                    decision-making in various domains. Gain hands-on experience
                    through practical projects to build a strong foundation in
                    data science.
                  </Typography>
                  <br></br>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() =>
                      window.open(
                        "https://pub-9632b8e8c9194cb6882caeb316f4f734.r2.dev/TAGSKILLSBROCHURES/Tagskills_DATA_SCIENCE.pdf",
                        "_blank"
                      )
                    }
                  >
                    Download Brochure
                  </Button>
                </div>
              ) : (
                ""
              )}
            </InnerWrapper>
          </Wrapper>
          <br />
          <InnerWrapper>
            <BroderContainer>
              <Typography variant="body1" className="font25 lightPurpleColor">
                <b>What you'll learn</b>
              </Typography>
              <br />
              {location.state.course === "S/4 HANA MM" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; understand the architecture and features of ERP
                      S/4HANA for efficient material management in enterprises.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to create and maintain comprehensive
                      material master data for various types of goods.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Master the process of verifying and processing
                      vendor invoices to ensure accurate financial transactions.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore methods for vendor evaluation and
                      selection to optimize procurement and sourcing strategies.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into creating purchase
                      requisitions, managing purchase orders, and handling goods
                      receipts.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore MRP principles to manage material
                      requirements based on demand forecasts and stock levels.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how MM integrates seamlessly with
                      modules like SD, FI, and CO for end-to-end business
                      processes.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Efficiently manage inventory, including valuation
                      methods, stock transfers, and physical inventory counts.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "S/4 HANA TM" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the principles of planning
                      transportation routes and optimizing load assignments to
                      meet delivery requirements.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn about features enabling collaboration with
                      carriers, including tendering, communication, and tracking
                      functionalities.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore the creation, execution, and monitoring
                      of freight orders to ensure effective transportation
                      operations.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to manage transportation costs,
                      including rate determination, charge calculation, and
                      settlement processes.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn about real-time event monitoring and
                      notification features to track and manage transportation
                      events.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; xplore integrated visibility into transportation
                      processes for better decision-making and proactive issue
                      resolution.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into route optimization,
                      scheduling, and carrier selection to enhance
                      transportation efficiency.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Utilize analytical tools and reporting features
                      to analyze transportation data for performance evaluation
                      and continuous improvement.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "ARIBA" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the end-to-end procurement processes,
                      including requisitioning, sourcing, and purchase order
                      management.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how to collaborate with suppliers
                      effectively using Ariba, from onboarding to communication
                      and collaboration on orders.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore sourcing strategies and how to conduct
                      online auctions to optimize supplier selection and
                      negotiation.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into creating, managing, and
                      monitoring contracts within the Ariba platform for
                      enhanced compliance and efficiency.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to assess and manage supplier
                      performance using Ariba tools and analytics.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how Ariba integrates with ERP S/4HANA,
                      ensuring seamless data flow and collaboration between
                      procurement and other business processes.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore catalog creation and management to
                      streamline the procurement process and improve user
                      experience.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the Ariba Network and its role in
                      facilitating electronic transactions and collaboration
                      between buyers and suppliers.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "SALESFORCE" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the fundamentals of the Salesforce
                      platform, including its architecture, navigation, and key
                      components.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into Customer Relationship
                      Management (CRM) principles and how Salesforce facilitates
                      customer-centric business processes.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to design and manage data using Salesforce
                      objects, fields, and relationships to ensure accurate and
                      meaningful information.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003;Explore the automation capabilities of Salesforce,
                      including workflow rules, process builder, and approval
                      processes to streamline business processes.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the functionalities of Sales Cloud for
                      sales automation and Service Cloud for customer service
                      and support.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Familiarize yourself with the Lightning
                      Experience user interface for a more modern and efficient
                      user experience.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn the basics of Apex, Salesforce's
                      proprietary programming language, for customization and
                      building business logic.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore the creation of custom user interfaces
                      using Visualforce and Lightning Web Components.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "S/4 HANA ABAP" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the fundamentals of ABAP programming
                      language, including syntax, data types, and control
                      structures.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to work with the ABAP Data Dictionary for
                      defining and managing database objects like tables, views,
                      and data elements.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain proficiency in using ABAP Development Tools,
                      the modern development environment for ERP ABAP.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003;Explore object-oriented programming concepts in
                      ABAP, including classes, methods, and inheritance.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand techniques for enhancing and modifying
                      standard SAP applications to meet specific business
                      requirements.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to leverage user exits and BADI to
                      customize SAP applications without modifying standard
                      code.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain knowledge of Web Dynpro ABAP for developing
                      user interfaces and web-based applications.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore techniques for creating interactive
                      reports using the ABAP List Viewer.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "S/4 HANA SD" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the end-to-end sales process, from
                      customer order creation to cash receipt.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to create and maintain customer master data
                      for effective sales transactions and customer relationship
                      management.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore different types of sales documents
                      (quotations, sales orders, etc.) and their configuration.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003;Gain insights into configuring pricing procedures
                      to determine product prices based on various factors.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the availability check process and ATP
                      (Available-to-Promise) functionality to promise accurate
                      delivery dates to customers.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn about billing document creation, invoicing,
                      and integration with financial accounting.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore the logistics aspects, including shipping
                      processes and transportation management.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand credit management processes to assess
                      and control customer credit limits.for creating
                      interactive reports using the ABAP List Viewer.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "S/4 HANA FICO" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Master the principles of general ledger
                      accounting, including chart of accounts, journal entries,
                      and financial statement preparation.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand processes related to managing vendor
                      invoices and customer receivables for effective cash flow
                      management.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to manage fixed assets, including asset
                      acquisition, depreciation, and disposal.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore cost centers, profit centers, internal
                      orders, and other components of the controlling module for
                      effective cost management.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how FICO integrates with other ERP
                      modules to support integrated business planning and
                      decision-making
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain proficiency in using the ERP Fiori interface
                      for a more intuitive and user-friendly experience in
                      financial processes.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn about parallel accounting to manage
                      multiple valuation approaches or legal requirements within
                      the same system.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the process of migrating and
                      converting data from legacy systems to S/4HANA Finance.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "S/4 HANA EWM" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the configuration and management of
                      warehouse structures and master data necessary for
                      efficient warehouse operations.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003;Learn about the processes involved in receiving
                      goods (inbound) and shipping goods (outbound) within the
                      warehouse.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore techniques for optimal storage, including
                      the use of storage bins, and handling units to streamline
                      warehouse operations.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to manage resources such as labor,
                      equipment, and space efficiently within the warehouse.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn about quality inspection processes within
                      the warehouse to ensure product quality and compliance.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into real-time monitoring and
                      analytics for warehouse performance, enabling proactive
                      decision-making.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn about wave management techniques and order
                      fulfillment strategies for efficient order processing.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore advanced features like cross-docking for
                      faster goods movement and value-added services for
                      customized product handling.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "SAP PM" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the end-to-end plant maintenance
                      processes, including equipment management, preventive
                      maintenance, and work order creation.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how to optimize asset performance through
                      scheduled maintenance, inspections, and real-time issue
                      tracking.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore strategies for minimizing downtime by
                      integrating predictive and preventive maintenance
                      techniques with SAP PM.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into tracking maintenance costs,
                      managing work orders, and ensuring compliance with
                      regulatory standards using the SAP PM platform.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to assess and manage asset
                      performance using SAP PM tools, including real-time
                      monitoring and reporting.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how SAP PM integrates with other SAP
                      modules (like SAP MM and SAP FI) to ensure seamless
                      workflows across maintenance, procurement, and finance
                      processes.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore work order creation and management in SAP
                      PM to streamline maintenance activities and improve
                      operational efficiency.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the role of SAP PM in managing
                      equipment and asset lifecycles, from installation to
                      decommissioning, ensuring compliance and cost control.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "SAP BTP" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the core components of SAP BTP,
                      including data management, analytics, and integration
                      capabilities for driving business innovation.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how to leverage SAP BTP to enable real-time
                      analytics, data visualization, and reporting for better
                      decision-making across your organization.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore strategies for integrating SAP BTP with
                      existing SAP and third-party systems to streamline
                      business processes and create a connected enterprise.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into developing and deploying
                      custom applications using SAP BTP to meet unique business
                      requirements and enhance operational agility.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to leverage SAP BTP for managing
                      data, integrating systems, and enabling real-time business
                      insights through advanced analytics and reporting.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how SAP BTP integrates seamlessly with
                      other SAP solutions (like SAP S/4HANA, SAP SuccessFactors,
                      and SAP Ariba) to unify business processes and drive
                      innovation.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore how to use SAP BTP's low-code and no-code
                      tools to accelerate application development, automate
                      workflows, and enhance operational efficiency.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the role of SAP BTP in driving digital
                      transformation, from data management and AI-powered
                      insights to developing scalable cloud applications and
                      ensuring security and compliance.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "SAP EAM" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the end-to-end asset management
                      processes, including asset acquisition, preventive
                      maintenance, and work order creation.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how to optimize asset performance through
                      scheduled maintenance, condition monitoring, and real-time
                      issue tracking.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore strategies for reducing downtime and
                      extending asset life by integrating predictive and
                      preventive maintenance techniques with SAP EAM.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into tracking maintenance costs,
                      managing work orders, and ensuring compliance with
                      regulatory standards using the SAP EAM platform.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to assess and manage asset
                      performance using SAP EAM tools, including real-time
                      monitoring, reporting, and data-driven insights.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how SAP EAM integrates with other SAP
                      modules (like SAP PM, SAP MM, and SAP FI) to ensure
                      seamless workflows across asset maintenance, procurement,
                      and financial processes.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore asset lifecycle management in SAP EAM,
                      from installation to decommissioning, to ensure optimal
                      asset utilization and cost control.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the role of SAP EAM in enhancing
                      operational efficiency, ensuring compliance, and driving
                      better decision-making through advanced analytics and
                      insights.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "SAP PS" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the end-to-end project management
                      processes, including project planning, resource
                      management, and project execution.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how to optimize project performance through
                      detailed scheduling, cost tracking, and real-time progress
                      monitoring.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore strategies for controlling project costs,
                      timelines, and quality by integrating SAP PS with other
                      SAP modules (such as SAP MM and SAP FI).
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain insights into managing project budgets,
                      resources, and milestones while ensuring alignment with
                      organizational goals and regulatory standards using the
                      SAP PS platform.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to plan, execute, and monitor
                      projects using SAP PS tools, including real-time progress
                      tracking, resource management, and detailed reporting.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how SAP PS integrates with other SAP
                      modules (like SAP MM, SAP FI, and SAP CO) to ensure
                      seamless workflows across project management, procurement,
                      and financial processes.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore project lifecycle management in SAP PS,
                      from project initiation and planning to execution,
                      monitoring, and closure, ensuring efficient resource
                      utilization and cost control.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand the role of SAP PS in optimizing
                      project timelines, enhancing collaboration, ensuring
                      compliance, and driving better decision-making through
                      data-driven insights and advanced analytics.
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "DATA ANALYTICS" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn how to gather data from various sources,
                      including databases, APIs, and web scraping.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Master techniques to clean and preprocess data,
                      handling missing values, and correcting inconsistencies.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand how to create visual representations
                      of data using tools like Excel, Matplotlib's and Power BI.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Apply your knowledge through hands-on capstone
                      projects that involve real-world data analysis scenarios,
                      allowing you to demonstrate your skills and build a
                      portfolio to showcase to potential employers
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Develop skills in SQL for querying databases and
                      manipulating data efficiently
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to use programming language like Python for
                      data analysis and automation
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003;Understand how to translate data insights into
                      actionable business strategies
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Dive into advanced analytics techniques, such as
                      time series analysis, regression modeling, and clustering,
                      to uncover deeper insights and drive complex data-driven
                      solutions
                    </Typography>
                  </Grid>
                </Grid>
              ) : location.state.course === "DATA SCIENCE" ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Gain proficiency in Python, the primary language
                      used in data science, including libraries like NumPy,
                      Pandas, and Scikit-learn
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003;Learn to apply statistical methods and concepts to
                      analyze and interpret data sets
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Master techniques for cleaning, transforming, and
                      preparing data for analysis.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Understand and implement key machine learning
                      algorithms, including supervised and unsupervised learning
                      techniques.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className="pb10">
                      &#10003; Explore neural networks and deep learning
                      frameworks such as TensorFlow and Keras.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Learn to use SQL for querying databases and
                      managing data effectively.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003;Natural Language Processing (NLP): Explore
                      techniques for processing and analyzing textual data.
                    </Typography>
                    <Typography variant="body2" className="pb10">
                      &#10003; Apply your knowledge through real-world projects,
                      solving complex data problems and building a portfolio.
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </BroderContainer>
          </InnerWrapper>
          <InnerWrapper>
            <BroderLessContainer>
              <Typography variant="body2" className="font20">
                <b>This course includes:</b>
              </Typography>
              <br />
              <Grid container>
                <Grid item xs={6}>
                  <Typography className="flexNullCenter pb10" variant="body1">
                    <CiYoutube />
                    {location.state.course === "ARIBA" ||
                    location.state.course === "S/4 HANA EWM" ||
                    location.state.course === "S/4 HANA TM" ||
                    location.state.course === "S/4 HANA ABAP" ||
                    location.state.course === "S/4 HANA SD"
                      ? "40 hours on-demand video"
                      : "48 hours on-demand video"}
                  </Typography>
                  <Typography className="flexNullCenter pb10" variant="body1">
                    <IoDownloadOutline />
                    34 downloadable resources
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="flexNullCenter pb10" variant="body1">
                    <CgWebsite />
                    Access on Website
                  </Typography>
                  <Typography className="flexNullCenter pb10" variant="body1">
                    <TfiMedall />
                    certificate of completion
                  </Typography>
                </Grid>
              </Grid>
            </BroderLessContainer>
          </InnerWrapper>
          {/* <InnerWrapper> */}
          <FooterDiv>
            <Typography variant="body1" className="pb10">
              <b>Top Companies Hiring for Sap Consultant Jobs</b>
            </Typography>

            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={4} md={2}>
                <div style={{ width: "40px", height: "30px" }}>
                  <img src={img1} alt="img1" />
                </div>
              </Grid>
              <Grid item xs={4} md={2}>
                <div style={{ width: "30px", height: "30px" }}>
                  <img src={img3} alt="img3" />
                </div>
              </Grid>
              <Grid item xs={4} md={2}>
                <div style={{ width: "40px", height: "30px" }}>
                  <img src={img4} alt="img4" />
                </div>
              </Grid>
              <Grid item xs={4} md={2}>
                <div style={{ width: "40px", height: "30px" }}>
                  <img src={img5} alt="img5" />
                </div>
              </Grid>
              <Grid item xs={4} md={2}>
                <div style={{ width: "40px", height: "30px" }}>
                  <img src={img2} alt="img2" />
                </div>
              </Grid>
            </Grid>
          </FooterDiv>
          {/* </InnerWrapper> */}
          <br />
        </div>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "block", lg: "block" },
            // justifyContent: "end",
          }}
        >
          <SideDiv>
            {" "}
            <Card
              style={{
                width: "100%",
                maxWidth: "350px",
                boxShadow:
                  " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
              }}
            >
              <img
                src={courseImg}
                alt="img"
                style={{ height: "250px", width: "100%" }}
              />
              <CardContent style={{ padding: "15px 30px" }}>
                <Typography variant="body2" className="lightPurpleColor font25">
                  TagSkills
                </Typography>
                <Typography variant="body2">
                  Subscribe to this course and 100+ top-rated TagSkills ERP
                  courses for your organization.
                </Typography>
                <br />
                <div style={{ textAlign: "center" }}>
                  <Button
                    style={{ textTransform: "none" }}
                    variant="contained"
                    className="lightPurpleBg whiteColor"
                    onClick={() => setOpenJoinFor(true)}
                  >
                    Book your free demo
                  </Button>
                </div>
                <br />
                <div>
                  {console.log("9999", location.state.course)}
                  {(location.state.course === "DATA SCIENCE" ||
                    location.state.course === "S/4 HANA MM" ||
                    location.state.course === "S/4 HANA FICO" ||
                    location.state.course === "SALESFORCE" ||
                    location.state.course === "DATA ANALYTICS") && (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; Duration: 75-130 Hours
                    </Typography>
                  )}

                  {(location.state.course === "ARIBA" ||
                    location.state.course === "S/4 HANA EWM" ||
                    location.state.course === "S/4 HANA TM" ||
                    location.state.course === "S/4 HANA ABAP" ||
                    location.state.course === "S/4 HANA SD") && (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; Duration: 40-45 Hours
                    </Typography>
                  )}

                  {location.state.course === "DATA SCIENCE" ? (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; Real-World Applications
                    </Typography>
                  ) : location.state.course === "DATA ANALYTICS" ? (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; Real-World Applications
                    </Typography>
                  ) : (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; ERP Application
                    </Typography>
                  )}

                  {location.state.course === "DATA SCIENCE" ? (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; Programming Skills
                    </Typography>
                  ) : location.state.course === "DATA ANALYTICS" ? (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; Programming Skills
                    </Typography>
                  ) : (
                    <Typography variant="body2" className="pb15">
                      &#10003; &nbsp;&nbsp; Business process knowledge
                    </Typography>
                  )}

                  <Typography variant="body2" className="pb15">
                    &#10003; &nbsp;&nbsp; Real Time Projects
                  </Typography>

                  <Typography variant="body2" className="pb15">
                    &#10003; &nbsp;&nbsp; Consultative Approach
                  </Typography>
                  <Typography variant="body2" className="pb15">
                    &#10003; &nbsp;&nbsp; Placement Support
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </SideDiv>
        </Box>
      </div>
      {openJoinFor && (
        <BookNow openJoinFor={openJoinFor} onClose={handleDialogClose} />
      )}
      <Button
        href="https://wa.me/916360959192"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </Button>
    </div>
  );
}
const Wrapper = styled.section`
  padding-top: 30px;
  width: 100%;
  margin: 0;
  height: 100%;
  min-height: 30vh;
  max-height: 40vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    min-height: 20vh;
    padding-top: 15px;
  }
`;
const TopDiv = styled.section`
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 20px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
`;
const SideDiv = styled.section`
  position: absolute;
  right: 50px;
  top: 30px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    display: none;
  }
`;

const BroderContainer = styled.section`
  border: 1px solid lightgray;
  width: 100%;
  margin: 0;
  padding: 20px;
  height: 100%;
  max-width: 75%;
  min-height: 40vh;
  max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;
const FooterDiv = styled.section`
  width: 100%;
  //   margin: 10px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   max-width: 75%;
  //   min-height: 20vh;
  //   max-height: 20vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;

const BroderLessContainer = styled.section`
  //   border: 1px solid lightgray;
  width: 100%;
  margin: 0;
  padding: 20px;
  height: 100%;
  max-width: 75%;
  min-height: 15vh;
  max-height: 20vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    max-width: 100%;
  }
`;
const InnerWrapper = styled.section`
  //   padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;
  max-width: 75%;
  margin: 0;
  height: 100%;
  //   min-height: 40vh;
  //   max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 760px) {
    max-height: 100vh;
    padding: 20px;
    max-width: 100%;
  }
`;
