import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { PaymentStatusApi } from "src/api/ApiConnect"
import { Post } from "src/api/apiConfig";
import styles from "./Pay.module.css"
import { Link } from "react-router-dom";

const Paystatus = () => {
  const [loading, setLoading] = useState()
  const user = useSelector((state) => state.user)
  const transIddisp = useSelector((state) => state.trans)
  const [PAYSTAT, setPAYSTAT] = useState([])

  useEffect(() => {

    getpaymentstatus()

  },[])

  const getpaymentstatus = async () => {
    setLoading(true);
    const RequestBody = {  
      email:user.email,
      transid: transIddisp 
    }
    const data = await Post(PaymentStatusApi,RequestBody)
    setLoading(false);

    if (data?.IsSuccess) {
      setPAYSTAT(data)
    } else {
      setPAYSTAT(data)
    }
  }

  return (

<div className={styles.container}>
      <div className={styles.innerDiv}>
        <h1 className={styles.heading}>Payment Status</h1>
        <div className={styles.linebox}>
        
        </div>
        <form className={styles.form}>
        <h6>Status: {PAYSTAT.payStatus}</h6>
        <h6>Paid Amount: {PAYSTAT.paidamount}</h6>
        <h6>Transaction Id: {PAYSTAT.transactionid}</h6>
        <h6>Your selected Course: {PAYSTAT.product}</h6>
          <Link to="/Dashboard">
          <button className={styles.submitBtn} type="button">
          <p className={styles.linkItem}>Go Back To My Dashboard</p>
          </button>
          </Link>
        </form>
      </div>
    </div>
  )
}
export default Paystatus
