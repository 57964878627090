import {
  Close,
  Instagram,
  LinkedIn,
  Logout,
  YouTube,
} from "@mui/icons-material";
import { Avatar, Box, Button } from "@mui/material";
import React from "react";
import { TbCertificate } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "src/screens/Dashboard/Dashboard.module.css";
import { addUser } from "src/redux/action/action"

export default function ProfileSidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigation = (val) => {
    navigate(val);
  };
  
  const user = useSelector((state) => state.user.request[0]);
  const _logout = () => {
    dispatch(addUser({}));
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "#F0ECF9",
          // textAlign: "right",
          padding: "10px",
        }}
      >
        <Button
          size="small"
          startIcon={<Close />}
          onClick={props.toggleDrawer("right", false)}
          style={{ textTransform: "none" }}
        >
          Close
        </Button>
      </div>
      <Box
        sx={{
          width: 250,
          height: "100%",
          pt: 4,
          // gap: 5px,
          backgroundColor: "#F0ECF9",
        }}
        role="presentation"
        className="flexCenter flexColumn "
      >
        <div className={styles.divalign}>
          <Avatar
            alt={user.full_name}
            src={user.photourl}
            sx={{ width: 120, height: 120 }}
          />
        </div>
        <br />
        <div className={styles.divalign}>
          <h5>{user.full_name}</h5>
        </div>
        <br />
        <Button
          variant="contained"
          className="purpleBg"
          endIcon={<TbCertificate className="whiteColor" />}
          onClick={() => handleNavigation("/Certificate")}
        >
          My Certificate
        </Button>
        <br />
        <Button
          variant="outlined"
          className="btn"
          startIcon={<LinkedIn style={{ fill: "#5DADE2" }} />}
          onClick={() =>
            window.open("https://www.linkedin.com/company/tag-skills", "_blank")
          }
        >
          Linkdeln
        </Button>
        <br />
        <Button
          className="btn"
          variant="outlined"
          startIcon={<YouTube className="whiteColor" style={{ fill: "red" }} />}
          onClick={() =>
            window.open(
              "https://www.youtube.com/channel/UCnrhyp2JvcNQb3kFeRziDLA",
              "_blank"
            )
          }
        >
          Youtube
        </Button>
        <br />
        <Button
          className="btn"
          variant="outlined"
          startIcon={
            <Instagram className="whiteColor" style={{ fill: "#EC7063" }} />
          }
          onClick={() =>
            window.open(
              "https://www.instagram.com/tagskills2020?igsh=YzZmNXpjNXZneHlu",
              "_blank"
            )
          }
        >
          Instagram
        </Button>
        <br />
        <Button
          startIcon={<Logout style={{ fill: "black" }} />}
          // onClick={() => handleNavigation("/Certificate")}
          onClick={() => _logout()}
          className="darkColor"
        >
          Logout
        </Button>
      </Box>
    </div>
  );
}
