import React from "react";
import loginBg from "src/assets/images/loginpageimg.jpg";
import "src/screens/LandingPage/components/styles.css";
import { useState } from "react";
import { Input } from "reactstrap";
import { Close } from "@mui/icons-material";
import GoogleButton from "react-google-button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Post } from "src/api/apiConfig";
import { useUserAuth } from "src/api/UserAuthContext";
import { UserDataApi } from "src/api/ApiConnect";
import { login } from "src/api/api";
import { addUser } from "src/redux/action/action"
import { Link } from "react-scroll";
import { ArrowBack } from "@mui/icons-material";
import styled from "styled-components";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import LogoIcon from "src/assets/icons/Logo.png";



const Login = () => {

  const [selectedTab, setSelectedTab] = useState("email");

  const { googleSignIn } = useUserAuth();
  const [isRemember, setIsRemember] = useState(localStorage.getItem("rememberme") === "true" ? true :false );
  const [username, setUsername] = useState(localStorage.getItem("username")!=""?localStorage.getItem("username"):"");
  const [password, setPassword] = useState(localStorage.getItem("password")!=""?localStorage.getItem("password"):"");
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);


  //Validation
  const [msgemaiidbool , setEmailidmsgbool] = useState('');
  const [msgpasswordbool , setPasswordmsgbool] = useState('');

  const [msgemaiid , setEmailidmsg] = useState('');
  const [msgpassword , setPasswordmsg] = useState('');



  const handleRemeberme = async (e) => {

    if (!e) {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
    } else {
      
      localStorage.setItem("username", "");
      localStorage.setItem("password", "");
      localStorage.setItem("rememberme", !e);
    }
    localStorage.setItem("rememberme", !e);
    setIsRemember(!e)
  
  }

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  


  const handleGoogleSignIn = async (e) => {
    e.preventDefault();   
    try {

      setLoading(true);
      const logindata = await googleSignIn();
      setLoading(false);
      localStorage.setItem("token", logindata?.user?.accessToken);

      if (logindata?.user?.accessToken) {
        const RequestBody = {
          USER_EMAIL: logindata.user.email,
          USER_FULLNAME: logindata.user.displayName,
          USER_MOBILE:
            logindata.user.phoneNumber == null
              ? ""
              : logindata.user.phoneNumber,
        };
        setLoading(true);
        const res = await Post(UserDataApi, RequestBody);
        setLoading(false);
        const data = res?.userdata;
        if (data) {
          const user = {
            token: logindata.user.accessToken,
            photourl: logindata.user.photoURL,
            batch: data[0].USER_BATCH,
            course_status: data[0].USER_COURSE_STATUS,
            email: logindata.user.email,
            full_name: data[0].USER_FULLNAME,
            membership_type: data[0].USER_MEMBERSHIP_TYPE,
            course_type: data[0].USER_COURSE_TYPE,
            selected_course: data[0].USER_SELECTED_COURSE,
            mobile: data[0].USER_MOBILE,
            status: data[0].USER_STATUS,
            Message: data[0].Message,
            user_gender: data[0].USER_GENDER,
            user_country: data[0].USER_COUNTRY,
            user_location: data[0].USER_LOCATION,
            user_course_fees: data[0].USER_COURSE_FEES,
            user_course_offer_percentage: data[0].OFFER_PERCENTAGE,
            user_pendingamount: "0",
          };
          localStorage.setItem('token', logindata.user.accessToken);
          dispatch(addUser(user));
          navigate("/Dashboard");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async () => {

    if(username ==='' || username ===null)
    {
      setEmailidmsgbool(true);
      setEmailidmsg('Enter EmailId!')
      return;
    }


    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!re.test(String(username).toLowerCase()))
      {
        setEmailidmsgbool(true);
        setEmailidmsg('Enter Valid EmailId!')
        return;
      }


      if(password ==='' || password ===null)
      {
        setPasswordmsgbool(true);
        setPasswordmsg('Enter Password!')
        return;
      }


    setLoading(true);
    const data = await login(username, password);
    setLoading(false);
    if (data?.IsSuccess) {
      const user = {
        token: data.TOKEN,
        batch: data.USER_BATCH,
        course_status: data.USER_COURSE_STATUS,
        email: data.USER_EMAIL,
        full_name: data.USER_FULLNAME,
        membership_type: data.USER_MEMBERSHIP_TYPE,
        course_type: data.USER_COURSE_TYPE,
        selected_course: data.USER_SELECTED_COURSE,
        mobile: data.USER_MOBILE,
        status: data.USER_STATUS,
        Message: data.Message,
        user_gender: data.USER_GENDER,
        user_country: data.USER_COUNTRY,
        user_location: data.USER_LOCATION,
        user_course_fees: data.USER_COURSE_FEES,
        user_course_offer_percentage: data.OFFER_PERCENTAGE,
        user_pendingamount: "0",
      };
      localStorage.setItem('token', data.TOKEN);
      dispatch(addUser(user));
      navigate("/Dashboard");
    } else {
        
      setPasswordmsgbool(true);
      setPasswordmsg(data.Message+'!')
      return;
    }
  };


  
  return (
    <div >
       <TopDiv className="purpleBg whiteColor flexSpaceCenter">
        <Link
          className="pointer flexNullCenter"
          style={{ gap: "0px" }}
          to="home"
          smooth={true}
        >
          <img
            //   className={styles.navLogo}
            style={{
              width: "36px",
              height: "36px",
              alignSelf: "flex-start",
              // marginRight: "16px",
            }}
            alt="logo.png"
            src={LogoIcon}
          />
          <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
            TagSkills
          </h1>
        </Link>
        
      </TopDiv>
       <Dialog fullWidth maxWidth={"md"} open={true} >
         <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={6} >
          <img src={loginBg} alt="img" className="imgDiv" />
        </Grid>
        <Grid item xs={6} >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                cursor: "pointer",
              }}
             
            >
              <Button startIcon={<ArrowBack />} href="/" className="blackColor">
          Home
        </Button>
            </div>
            <Typography className="font14">
              <b>Welcome to TagSkills</b>{" "}
            </Typography>

            {/* <div className="tabDiv">
              <div
                className={
                  selectedTab === "email"
                    ? "activeTab tab whiteColor"
                    : "tab whiteColor"
                }
                onClick={() => setSelectedTab("email")}
              >
                Email ID
              </div>
              <div
                onClick={() => setSelectedTab("phone")}
                className={
                  selectedTab === "phone"
                    ? "activeTab tab whiteColor"
                    : "tab whiteColor "
                }
              >
                Phone Number
              </div>
            </div> */}
            <br />
            {/* <Typography variant="body2" className="font12">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.s
            </Typography> */}
            {selectedTab === "email" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                 <TextField
                  variant="outlined"
                  size="small"
                  label="Email id"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={username}
                    onChange={(e) => {setUsername(e.target.value);
                      if(e.target.value!=''){setEmailidmsgbool(false);setEmailidmsg('')}}}
                    helperText={msgemaiid}
                    error={msgemaiidbool}
                />

              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography variant="body2" className="font12">
                  Phone number
                </Typography>
                <Input
                  className="textField"
                  placeholder="Enter your Phone number"
                />
              </div>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
            <TextField
                  variant="outlined"
                  type="password"
                  size="small"
                  label="Password"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={password}
                  onChange={(e) => {setPassword(e.target.value);;
                    if(e.target.value!=''){setPasswordmsgbool(false);setPasswordmsg('')}}}
                    helperText={msgpassword}
                    error={msgpasswordbool}
                />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" className="font12">
                <Checkbox
                defaultChecked={isRemember}
                checked={isRemember}
                  onClick={() => 
                    handleRemeberme(isRemember)}
                />{" "}
                Remember me
              </Typography>
              <Typography
                variant="body2"
                className="font12"
                onClick={() => alert("Please contact your Trainer...!")}
              >
                Forgot password?
              </Typography>
            </div>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
           <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => handleSubmit()}
          className="purpleBg radius8"
        >
          Login
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
            <Typography variant="body1">Or</Typography>
            <GoogleButton
              style={{ width: "340px" }}
              className="g-btn"
              type="dark"
              onClick={handleGoogleSignIn}
            />
          </div>
        </Grid>
      </Grid>
      </DialogContent>
      </Dialog>
    </div>
  );
};

export default Login;
const TopDiv = styled.section`
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 20px;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
`;
