import React, { useState, useEffect } from "react";
import axios from "axios"
import { Link } from "react-scroll";
import styled from "styled-components";
import LogoIcon from "src/assets/icons/Logo.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Check } from "@mui/icons-material";
import ActivityIndicator from "src/components/ActivityIndicator/ActivityIndicator";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import gIcon from "src/assets/images/gIcon.png";
import PeopleIcon from "@mui/icons-material/People";
import { BookDemoApi } from "src/api/ApiConnect";
import { PostWitoutToken } from "src/api/apiConfig";
import Badge from "@mui/material/Badge";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Avatar from "@mui/material/Avatar";
import { useRef } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import { IoMdReturnLeft } from "react-icons/io";


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


export default function AddYourReview() {

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 40,
    height: 40,
    //border: `2px solid ${theme.palette.background.paper}`,
  }));

  const [loading, setLoading] = useState();
  //variable declaration
  const [localfullname, setlocalfullname] = useState("");
  const [localmobile, setlocalmobile] = useState("");
  const [localemail, setlocalemail] = useState("");
  const [couponcode, setCouponcode] = useState("");

  const [imgurl_var, setImgurl_var] = useState("");
  const [img_var, setImg_var] = useState(imgurl_var || "");

  const [fullname_var, setFullname_var] = useState('');
  const [email_var, setEmail_var] = useState('');
  const [company_var, setCompany_var] = useState('');
  const [exp_var, setExp_var] = useState('');
  const [linkedin_var, setLinkedin_var] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);

  //Validation declaration
  const [localfullnameError, setlocalfullnameError] = useState(null);
  const [localemailError, setlocalemailError] = useState(null);
  const [workexprError, setWorkexprError] = useState(null);
  const [companyrError, setCompanyrError] = useState(null);
  const [storyError, setStoryError] = useState(null);
  const [picError, setPicError] = useState(null);
  

  const [comment_var, setComment_var] = useState('');
  const maxWords = 101;

  const hiddenFileInput = useRef(null);

  const CommenthandleChange = async (event) => {
    const inputText = event.target.value;
    const wordCount = inputText.trim().split(/\s+/).length;

    if (wordCount <= maxWords) {
      setComment_var(inputText);
    }
  };

  const remainingWords = maxWords - comment_var.trim().split(/\s+/).length;

  const handleClick = async (event) => {
    hiddenFileInput.current.click();
  };

  const submitHandler = async () => {

    setUploadPercentage(0);

    if(!fullname_var)
    {
      setlocalfullnameError('Enter Full Name!')
      return;
    }

    if(!email_var)
    {
      localemailError('Enter Email!')
      return;
    }

    if(!company_var)
    {
      setCompanyrError('Enter Company!')
      return;
    }


    if(!exp_var)
    {
      setWorkexprError('Enter Work Experience!')
      return;
    }

    if(!comment_var)
    {
      setStoryError('Enter Review!')
      return;
    }
    

    if(!imgurl_var)
    {
      setPicError('Upload Your Image!')
      return;
    }

      const body = {
        USER_COMMENT: comment_var,
        USER_COMPANY: company_var,
        USER_EXPERIENCE: exp_var,
        USER_NAME: fullname_var,
        LOCATLPATH: imgurl_var,
        USER_EMAIL: email_var,
        LINKEDIN: linkedin_var
      };

      const headerstemp = {
        "Content-Type": "multipart/form-data",
      };
      let token;
      if (token) {
        headerstemp.Authorization = `bearer ${token}`;
      }
      try {
        const response = await axios({
          method: "post",
          url: "https://apilive.tagskills.com/api/addyourstory",
          data: body,
          headers: headerstemp,
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        });
        alert(response.data);
        window.location.reload(); 
        console.log("Response:", response.data);

      } catch (error) {
        console.error("Error uploading file:", error);
      }
    

  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const localurl = URL.createObjectURL(event.target.files[0]);
    setImg_var(localurl);
    setImgurl_var(fileUploaded);
  };

  return (
    <div>
      <Wrapper className="purpleBg">
        <div>
          <Link
            className="pointer flexNullCenter"
            style={{ gap: "0px" }}
            to="home"
            smooth={true}
          >
            <img
              //   className={styles.navLogo}
              style={{
                width: "36px",
                height: "36px",
                alignSelf: "flex-start",
                // marginRight: "16px",
              }}
              alt="logo.png"
              src={LogoIcon}
            />
            <h1
              style={{ marginLeft: "15px" }}
              className="font20 extraBold whiteColor"
            >
              TagSkills
            </h1>
          </Link>
        </div>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="grid1">
            <ContentWrapper>
              <Typography variant="h3" className="font30 whiteColor">
                Empower Your Learning Journey with Comprehensive Reviews and
                Expert Advice on TagSkills.
              </Typography>
              <br />
              {/* <Typography variant="body2" className="font14 whiteColor">
              Empowering minds, transforming futures. Your journey to greatness begins here.
              </Typography> */}
              <br />
            </ContentWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="grid2"
            style={{ display: "flex", justifyContent: "center" }}
          ></Grid>
        </Grid>
      </Wrapper>
      <Wrapper style={{ paddingBottom: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="flexColumn flexCenter">
            <div>
              <div className="flexNullCenter">
                <LinkedIn
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/tag-skills",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer", fill: "#68a3da" }}
                />
                <Typography variant="body2">18.77K+ Followers</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <YouTube
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCnrhyp2JvcNQb3kFeRziDLA",
                      "_blank"
                    )
                  }
                  style={{ cursor: "pointer", fill: "#ff2525" }}
                />
                <Typography variant="body2">8.33 8.33k+ Subscribers</Typography>
              </div>
              <br />{" "}
              <div className="flexNullCenter">
                <img
                  src={gIcon}
                  style={{ width: "20px", display: "flex", cursor: "pointer" }}
                  alt="icon"
                  onClick={() =>
                    window.open(
                      "https://www.google.com/search?q=tagskills&rlz=1C1CHBF_enIN994IN994&oq=tagskills&aqs=chrome..69i57j46i175i199i512j0i10l2j46i10i175i199j69i60l2j69i61.5342j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3bae3f50cd3efe11:0x45ac4731b6278edb,1,,,",
                      "_blank"
                    )
                  }
                />
                <Typography variant="body2">4.9 Rating on Google</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <PeopleIcon style={{ cursor: "pointer", fill: "#ff2525" }} />
                <Typography variant="body2">1K+ Placements</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Expert Trainers </Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">
                  Business process knowledge
                </Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Real time project</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Consultative approach</Typography>
              </div>
              <br />
              <div className="flexNullCenter">
                <Check />
                <Typography variant="body2">Placement support</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="flexCenter">
            <Box
              sx={{
                width: "400px",
                position: "absolute",
                backgroundColor: "white",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                flexGrow: 1,
                top: { md: "100px", lg: "150px" },
                position: { xs: "relative", md: "absolute", lg: "absolute" },
                // justifyContent: "end",
              }}
              style={{}}
            >
              <CardContent className="flexNullCenter flexColumn p30">
                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="Full Name"
                  style={{ width: "100%" }}
                  value={fullname_var}
                  required
                  error={fullname_var.length == 0}
                  onChange={(e) => {
                    setFullname_var(e.target.value);
                  }}
                  helperText={localfullnameError}
                  autoComplete="off"
                  maxLength={25}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  label="Email id"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={email_var}
                  required
                  error={email_var.length == 0}
                  onChange={(e) => {
                    setEmail_var(e.target.value);
                  }}
                  //helperText={localemailError}
                  autoComplete="off"
                  maxLength={25}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  className="pb10"
                  label="Company Name"
                  style={{ width: "100%" }}
                  value={company_var}
                  required
                  error={company_var.length == 0}
                  onChange={(e) => {
                    setCompany_var(e.target.value);
                  }}
                  autoComplete="off"
                  helperText={companyrError}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  value={exp_var}
                  required
                  error={exp_var.length == 0}
                  label="Work Experience"
                  className="pb10"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setExp_var(e.target.value);
                  }}
                  helperText={workexprError}
                />

                <TextField
                  variant="outlined"
                  size="small"
                  required
                  error={linkedin_var.length == 0}
                  label="LinkedIn Profile Url"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={linkedin_var}
                  onChange={(e) => {
                    setLinkedin_var(e.target.value);
                  }}
                />
                 <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  Remaining words: {remainingWords >= 0 ? remainingWords : 0}
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  size="small"
                  className="pb10"
                  style={{ width: "100%" }}
                  value={comment_var}
                  required
                  error={comment_var.length == 0}
                  id="outlined-basic"
                  label="Your Review (100 Words Max)"
                  variant="outlined"
                  inputProps={{ maxLength: 1500 }}
                  onChange={(e) => {
                    CommenthandleChange(e);
                    //setComment_var(e.target.value);
                  }}
                  helperText={storyError}
                />
                <Typography
                  variant="body2"
                  color={picError == null ? "textSecondary" : "#DC4C64"}
                >
                  Upload Your Picture
                </Typography>
                <FormControl >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <>
                      <SmallAvatar alt="Remy Sharp" onClick={handleClick}>
                        {" "}
                        <AddAPhotoIcon
                          style={{ cursor: "pointer" }}
                          sx={{ color: "black", width: 20, height: 20 }}
                        />
                      </SmallAvatar>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                      />
                    </>
                  }
                >
                  <Avatar
                    alt="Travis Howard"
                    sx={{ width: 100, height: 100 }}
                    src={img_var}
                  />
                </Badge>
                </FormControl>
              </CardContent>
              <CardActions className="flexNullCenter flexColumn p30">
                <div className="flexNullCenter pb10">
                  <Typography variant="body2" className="font11">
                    By clicking Submit. you agree to achive communications from
                    Tagskills in accordance with our{" "}
                    <a href="#" className="lightPurpleColor">
                      Privacy Policy
                    </a>
                  </Typography>
                </div>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  className="lightPurpleBg"
                  disabled={loading}
                  onClick={(e) => {
                    submitHandler();
                  }}
                  type="button"
                >
                  {!loading ? <p>Submit</p> : <ActivityIndicator />}
                </Button>
              </CardActions>
              <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={uploadPercentage} />
            </Box>
              <br />
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.section`
  padding: 30px;
  width: 100%;
  margin: 0;
  height: 100%;
  //   min-height: 140px;
  //   max-height: 60vh;
  //   @media (max-width: 960px) {
  //     flex-direction: column;
  //   }
  @media (max-width: 560px) {
    max-height: 100vh;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
  height: 100%;
`;
