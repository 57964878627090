import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Sponsorships from "../../assets/images/sponsorship/sponsorship3.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

export default function OurSponsorship() {
  return (
    <Wrapper >
      <ContentWrapper>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "block" },
            justifyContent: "end",
          }}
        >
          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid item xs={12} md={8}>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h4" component="div" sx={{ mb: "20px" }}>
                  <b>Our Sponsorship</b>
                </Typography>
                <Typography
                  variant="body2"
                  className="font12"
                  sx={{ textAlign: "left", marginBottom: 2 }}
                >
                  We're dedicated to empowering the next generation of sports
                  stars through our innovative sport sponsorship programs. At
                  TagSkills, we believe in supporting young athletes on their
                  journey to success both on and off the field.
                </Typography>
                <Typography
                  sx={{ textAlign: "left", marginBottom: 2 }}
                  variant="body2"
                  className="font12"
                >
                  Our comprehensive sponsorship initiatives provide aspiring
                  sports stars with the resources and opportunities they need to
                  thrive, while also offering brands a unique platform to
                  connect with talented individuals and invest in the future of
                  sports.
                </Typography>
                <Typography
                  variant="body2"
                  className="font12"
                  sx={{ textAlign: "left" }}
                >
                  Join us in making a difference by fostering the growth and
                  development of young athletes while building meaningful
                  partnerships that transcend the game. Discover the power of
                  sport sponsorship with TagSkills today!
                </Typography>

                <Button
                  variant="contained"
                  className="purpleBg radius8"
                  sx={{
                    backgroundColor: "#5249bd",
                    color: "white",
                    textTransform: "none",
                    mt: "10px",
                    "&:hover": {
                      backgroundColor: "#3f3da5",
                      color: "white",
                    },
                  }}
                  component={Link}
                  to="/sponsorship"
                >
                  Explore More
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  zIndex: 9,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "200px",
                    height: "300px",
                    background: "transparent",
                    borderRadius: "18px",
                  }}
                >
                  <LazyLoadImage
                    src={Sponsorships}
                    alt="img"
                    style={{
                      borderRadius: "18px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "block", md: "none" },
            justifyContent: "end",
            paddingTop: 2,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h4">
                  <b>Our Sponsorship</b>
                </Typography>

                <Typography
                  variant="body2"
                  className="font12"
                  sx={{ textAlign: "left", marginBottom: 2, marginY: 2 }}
                >
                  We're dedicated to empowering the next generation of sports
                  stars through our innovative sport sponsorship programs. At
                  TagSkills, we believe in supporting young athletes on their
                  journey to success both on and off the field.
                </Typography>
                <Typography
                  sx={{ textAlign: "left", marginBottom: 2 }}
                  variant="body2"
                  className="font12"
                >
                  Our comprehensive sponsorship initiatives provide aspiring
                  sports stars with the resources and opportunities they need to
                  thrive, while also offering brands a unique platform to
                  connect with talented individuals and invest in the future of
                  sports.
                </Typography>
                <Typography
                  variant="body2"
                  className="font12"
                  sx={{ textAlign: "left" }}
                >
                  Join us in making a difference by fostering the growth and
                  development of young athletes while building meaningful
                  partnerships that transcend the game. Discover the power of
                  sport sponsorship with TagSkills today!
                </Typography>
                <Button
                  variant="contained"
                  className="purpleBg radius8"
                  sx={{
                    backgroundColor: "#5249bd",
                    color: "white",
                    textTransform: "none",
                    mt: "10px",
                    "&:hover": {
                      backgroundColor: "#3f3da5", // Slightly darker shade for hover effect
                      color: "white",
                    },
                  }}
                  component={Link}
                  to="/sponsorship"
                >
                  Explore More
                </Button>
                <CEOWrapper>
                  <ImgDiv>
                    <img
                      src={Sponsorships}
                      alt="img"
                      style={{
                        width: "140px",
                        height: "200px",
                        borderRadius: "14px",
                        objectFit: "cover",
                      }}
                    />
                  </ImgDiv>
                </CEOWrapper>
              </div>
            </Grid>
          </Grid>
        </Box>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: 20px 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const CEOWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 410px;
  height: 300px;
  z-index: 9;
  margin: 20px auto 0;
`;

const ImgDiv = styled.div`
  position: relative;
  width: 140px;
  height: 200px;
  margin: 0 auto;
  background: transparent;
`;
