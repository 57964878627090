import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBvLzRFVMU1hI_cOiE_BmYouQnhcjU2EJU",
  authDomain: "tagskills-36214.firebaseapp.com",
  projectId: "tagskills-36214",
  storageBucket:"tagskills-36214.appspot.com",
  messagingSenderId:  "438711040304",
  appId: "1:438711040304:web:4f43b8b452a94d5904b0f5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;